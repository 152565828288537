<template>
    <div v-if="pessoa" data-backdrop="static" class="modal show" id="ExclusaoPessoas" tabindex="-1" role="dialog" :aria-labelledby="'ExclusaoPessoasLabel'" aria-hidden="true">
        <div class="modal-dialog  modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body"  style="padding:0!important">
                    <table class="table table-sm table-hover" style="margin:0px;">
                        <thead style="background:#1c4d5a!important;color:white;white-space: nowrap;">
                            <tr>
                                <th colspan="2" style="text-align:center;border:0px;">
                                    <slot name="titulo">
                                    </slot>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="pessoaExcluida.status">
                                <td colspan="2" style="padding:0px;">
                                        <b-alert style="margin-bottom:0px;text-align:center;" v-if="pessoaExcluida.status=='OK'" variant="success" show><b>{{ pessoaExcluida.mensagem }}</b></b-alert>
                                        <b-alert style="margin-bottom:0px;text-align:center;" v-if="pessoaExcluida.status=='NOK'" variant="danger" show><b>{{ pessoaExcluida.mensagem }}</b></b-alert>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="text-align:left;">
                                    <b>ID:</b> {{ pessoa.id }}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="text-align:left;">
                                    <b>NOME:</b> {{ pessoa.nome }}                                
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="text-align:left;">
                                    <b>CPF:</b> {{ pessoa.cpf }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="modal-footer">
                    <button v-if="!pessoaExcluida.status" type="button" class="btn btn-danger" :id="'btnOkExclusaoPessoas'" @click="excluirPessoa(pessoa)">Excluir</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" :id="'Fechar'" @click="fechar()">Fechar</button>
                </div>
            </div>
        </div>
        <loading id="DeleteUser" titulo="Excluindo, Aguarde..." :sem-padding="true" gif-carregando="/imagens/carregando.gif"></loading>
    </div>
</template>

<script>
export default {
    props:{
        urlRedirect:{type:String, requied:true},
        pessoa:{type:Object, requied:false},
        urlSistema:{type:String, requied:true},
        
    },
    
    data(){
        return {
            pessoaSelecionada:{},
            pessoaExcluida:{},
            exibirModal:true,
            excluido:false,
        }
    },
    created(){
         this.pessoaSelecionada = this.pessoa
    },
    methods:{
        redirect(){
            window.location.href = this.urlRedirect;
        },
        fechar(){
            this.pessoaExcluida = {}

            if(this.excluido==true){
                this.$emit('exclusaoconcluida' /*, this.nclick*/);
            }

        },
        excluirPessoa(pessoa){
            $('#DeleteUser').modal('show');
            var config = {
            method: 'delete',
            url: '/api/v1'+this.urlSistema+'/'+pessoa.id,
            // headers: {
            //         'Accept': 'application/json',
            //         'Authorization': this.$root.token(),
            //     },
            };
            //executa requisição
            axios(config)
            .then(response => {

                this.pessoaExcluida = response.data;
                this.excluido = true
                
                $('#DeleteUser').modal('hide');
                this.exibirModal = true;
                
            })
            .catch(function (error) {
                console.log(error);
                this.exibirModal = false;
                $('#DeleteCompany').modal('hide');
                this.excluido = false
            });

            $("#ExclusaoPessoas").modal('');
        }
        
    }
}
</script>

<style>
    
</style>
