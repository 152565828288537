<template>
  <div style="width:100%;" class="table-chamado">
        <nav class="navbar navbar-light bg-light navbar-padding">
            <form class="form-inline">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">Situação do Chamado</span>
                    </div>
                    <select name="situacao_os" id="situacao_os" class="form-control">
                        <option value="0">Todos</option>
                        <option v-for="status in status_orders" :key="status.id" value="0">{{ status.descricao }}</option>
                    </select>
                </div>
            </form>
            <form class="form-inline">
                <a href="/ServiceOrder/Service/create" class="btn btn-secondary btn-sm">Nova O.S.</a>
            </form>
        </nav>
        <table class="table table-sm table-hover">
            <thead style="background:#1c4d5a!important;color:white;">
                <tr>
                    <th style="text-align:center;">
                        Nº O.S.
                    </th>
                    <th style="text-align:center;">
                        Assunto
                    </th>
                    <th style="text-align:center;" v-if="userLoggedId.perfil_usuario==1 || userLoggedId.perfil_usuario==2">
                        Solicitante
                    </th>
                    <th style="text-align:center;" v-if="userLoggedId.perfil_usuario==1 || userLoggedId.perfil_usuario==3">
                        Assistência
                    </th>
                    <th style="text-align:center;">
                        Situação O.S.
                    </th>
                    <th style="text-align:center;">
                        Ações
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="os in service_orders.data" :key="os.id">
                    <td style="text-align:center;">
                        <b>{{ os.numerochamado }}</b>
                    </td>
                    <td>
                        {{ os.assunto }}
                    </td>
                    <td v-if="userLoggedId.perfil_usuario==1 || userLoggedId.perfil_usuario==2">
                        {{ os.user_client[0].nome }}
                    </td>
                    <td v-if="userLoggedId.perfil_usuario==1 || userLoggedId.perfil_usuario==3">
                        {{ os.user_support[0].nome}}
                    </td>
                    <td :style="'text-align:center;color:'+getCorSituacao(os)">
                            <b>{{ getSituacaoOs(os) }}</b>
                    </td>
                    <td style="text-align:center;">
                        <a :href="'/ServiceOrder/Service/'+os.id+'/edit'" class="btn" :style="'color:white;font-weight:bold;width:100px;height:auto;padding:2px; background-color:' + getCorSituacao(os) + ';border-radius: 100px;font-size:9pt;'">Responder&nbsp;&nbsp;<b-icon icon="chat-text-fill" font-scale="1"></b-icon></a>
                    </td>
                </tr>
            </tbody>
        </table>
        <paginate class="table-padding">
            <li v-for=" link, key in service_orders.links" :key="key" :class="(link.active ? 'page-item active':(link.url ? 'page-item':'page-item disabled'))" style="cursor:pointer;">
                <a class="page-link" tabindex="-1" v-html="link.label" @click="paginacao(link)">{{ link }}

                </a>
            </li>
        </paginate>
    </div>
            </template>
        </ficha-chamados>
  </div>
</template>

<script>
export default {
    data(){
        return{
            service_orders:{},
            status_orders:{},
            os:{},
            o_status:{
                id:{type:Number,required:true},
                cor:{type:String,required:true}
            },
            urlApi:window.location.origin+'/api/v1'+this.urlSistema+'/'+this.userLoggedId.id,
        }
    },
    props:{
        classe:{
            type:String,
            required:true
        },
        urlSistema:{
            type:String,
            required:true
        },
        userLoggedId:{},
    },
    created(){
         this.getServiceOrder();
         
    },
    methods:{
        getSituacaoOs(os){
            var situacao=''
            for(var i=0;i<this.status_orders.length;i++){
                if(os.situation_service_order_id == this.status_orders[i].id){
                    // console.log(this.status_orders[i].descricao + ' - ' + os.situation_service_order_id)
                    situacao = this.status_orders[i].descricao;
                }

                
            }
            return situacao
        }
        ,getCorSituacao(os){
            var cor = "#fff"
            for(var i=0;i<this.status_orders.length;i++){
                if(os.situation_service_order_id == this.status_orders[i].id){
                    // console.log(this.status_orders[i].descricao + ' - ' + os.situation_service_order_id)
                    cor = this.status_orders[i].cor;
                }

                
            }
            return cor
        },
        StatusExiste(){
            for(let status=0;status<this.status_orders.length;status++){
                this.status_orders[status].existe = false;
            }

            if(this.status_orders.length>0 && this.service_orders.length>0){
                for(let status=0;status<this.status_orders.length;status++){
                    for(let service=0;service<this.service_orders.length;service++){
                        if(this.service_orders[service].situation_service_order_id==this.status_orders[status].id){
                            this.status_orders[status].existe = true
                        }
                    }
                }
            }

            // console.log( this.status_orders)
        },
        expandiRecolhe(id_elemento,id_botao){
            if(document.getElementById(id_elemento).style.display == 'block'){
                $("#"+id_elemento).css("display", "none");
                $('#'+id_botao).text("+").attr({
                    title:"+"
                });
            }else{
                $("#"+id_elemento).css("display", "block");
                $('#'+id_botao).text("-").attr({
                    title:"-"
                });
            }
            // console.log(document.getElementById(id_elemento).style.display)
        },
        paginacao(link){
            if(link.url){
                this.urlApi = link.url; // ajustando a url com parâmetros de pagina
                // console.log(this.urlApi)
                this.getServiceOrder();
            }

        },
        getServiceOrder(){
            // Client/ClientUser
            //gera configurações de cabeçalho para requisição
            // console.log(this.urlApi)
            var config = {
                method: 'get',
                url: this.urlApi,
                // headers: {
                //         // 'Accept': 'application/json',
                //         // 'Authorization': this.$root.token(),
                //     },
                };
                //executa requisição
                axios(config)
                .then(response => {
                    this.service_orders = response.data.serviceOrders
                    this.status_orders = response.data.situacao_chamado
                    this.StatusExiste();
                    // console.log(this.status_orders.length)
                    // this.colunas = response.data.CamposTabelaExibicao
                })
                .catch(function (error) {
                    console.log(error);
                });

                
            },
            // getCookie(name) {
            //     return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + name.replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
            // }
    }
}
</script>

<style scoped>
    .table-chamado{
        border-radius: 10px;
        background: white;
        
    }

    .table-padding{
        padding-bottom: 15px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .navbar-padding{
        border-radius: 10px 10px 0px 0px;
        padding-left: 15px;
        padding-top:10px;
        padding-bottom: 10px;
    }

    button{
        text-align: center;
        float: left;
        
        position: relative;

        /* flex para alinhar conteúdo*/
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .page-item.active .page-link{
        background: #1c4d5a;
        border-color: #1c4d5a;
        color: white!important;
    }

    .page-item .page-link{
        color: #1c4d5a;
        border-color: #1c4d5a;
    }

    .btn-primary,.btn-primary:visited{
        background: #1c4d5a;
        color: white;
        border-color: #1c4d5a;
    }

    .btn-primary:hover{
        color: #1c4d5a;
        background: white;
        border-color: #1c4d5a;
    }

    .btn-primary:active{
        color: #1c4d5a!important;
        background: white!important;
        border-color: #1c4d5a!important;
    }

    .page-item.disabled{
        border-color: #1c4d5a;
    }

    @media(max-width: 600px){
        table{
            display: block;    
            overflow: auto;
            white-space: nowrap;
        }
    }
</style>