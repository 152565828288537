<template>
    <div style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
        <!-- <div class="card">
            <div class="card-header" style="padding:0px;"> -->
                <nav class="navbar navbar-expand-lg navbar-light bg-light">
                  <!--<a class="navbar-brand" :href="linkUnidade" v-if="unitie">
                      {{ unitie.nomecompleto }}
                  </a>-->
                  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarMenuUnidade" aria-controls="navbarMenuUnidade" aria-expanded="false" aria-label="Toggle navigation" style="border-color: white!important;border: 2px solid white!important;padding: 0.25rem 0.75rem; font-size: 1.125rem; line-height: 1; background-color: transparent; border: 1px solid transparent; border-radius: 0.25rem;">
                  <!-- <span class="navbar-toggler-icon"></span> -->
                    <b-icon icon="menu-button-wide-fill" font-scale="1.3" style="color:white;"></b-icon>
                  </button>
                      <div class="collapse navbar-collapse" id="navbarMenuUnidade">
                          <ul class="navbar-nav mr-auto">
                          <!--<li class="nav-item active">
                              <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" href="#">Link</a>
                          </li>-->
                          <li class="nav-item dropdown">
                              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="border: 2px solid white!important;padding: 0.25rem 0.75rem; font-size: 1.125rem; line-height: 1; background-color: transparent; border: 1px solid transparent; border-radius: 0.25rem;">
                                <b-icon icon="menu-button-wide-fill" font-scale="1.3"></b-icon>
                              </a>
                              <div class="dropdown-menu" aria-labelledby="navbarDropdown" v-if="usuarioLogado.perfil_usuario==1 || usuarioLogado.perfil_usuario==2">
                                <a class="dropdown-item" :href="'/Access/Unity/Unitie/'+unitie.id">Dashboard da Unidade</a>
                                <a class="dropdown-item" :href="'/Access/Unity/Unitie/'+unitie.id+'/oferta_processo_seletivo'" v-if="unitie.oferta_processo_seletivo==1">Oferta de Processo Seletivo</a>
                                <a class="dropdown-item" :href="'/Access/Unity/Unitie/'+unitie.id+'/oferta_inscricao_web'" v-if="unitie.oferta_Inscricao_web==1">Oferta de Inscrição Web</a>
                                <a class="dropdown-item" :href="'/Access/Unity/Unitie/'+unitie.id+'/captacao'">WebHooks</a>
                            </div>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdown" v-if="usuarioLogado.perfil_usuario==3"> 
                                <a class="dropdown-item" :href="'/Access/Unity/Unitie/'+unitie.id">Dashboard da Unidade</a>
                                <a class="dropdown-item" :href="'/Access/Unity/Unitie/'+unitie.id+'/oferta_processo_seletivo'" v-if="unities_users.oferta_processo_seletivo==1 && unitie.oferta_processo_seletivo==1">Oferta de Processo Seletivo</a>
                                <a class="dropdown-item" :href="'/Access/Unity/Unitie/'+unitie.id+'/oferta_inscricao_web'" v-if="unities_users.oferta_Inscricao_web==1 && unitie.oferta_Inscricao_web==1">Oferta de Inscrição Web</a>
                                <a class="dropdown-item" :href="'/Access/Unity/Unitie/'+unitie.id+'/captacao'">WebHooks</a>
                            </div>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="border: 2px solid white!important;padding: 0.25rem 0.75rem; font-size: 1.125rem; line-height: 1; background-color: transparent; border: 1px solid transparent; border-radius: 0.25rem;">
                                <b-icon icon="gear-fill" font-scale="1.3"></b-icon>
                            </a>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdown" v-if="usuarioLogado.perfil_usuario==1 || usuarioLogado.perfil_usuario==2">
                                <a class="dropdown-item" :href="'/Access/Unity/Unitie/'+unitie.id+'/oferta_inscricao_web/conf/financeira'" v-if="unitie.oferta_Inscricao_web==1">Configuração Financeira Inscrições</a>
                                <a class="dropdown-item" :href="'/Access/Unity/Unitie/'+unitie.id+'/oferta_processo_seletivo/conf/financeira'" v-if="unitie.oferta_processo_seletivo==1">Configuração Financeira Processo Seletivo</a>
                                <a class="dropdown-item" :href="'/Access/Unity/Unitie/'+unitie.id+'/config'">Configurações de Unidade</a>
                                
                                <!--<div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Something else here</a>-->
                                </div>
                                <div class="dropdown-menu" aria-labelledby="navbarDropdown" v-if="usuarioLogado.perfil_usuario==3"> 
                                    <a class="dropdown-item" :href="'/Access/Unity/Unitie/'+unitie.id+'/oferta_inscricao_web/conf/financeira'" v-if="unities_users.oferta_Inscricao_web==1 && unitie.oferta_Inscricao_web==1">Configuração Financeira Inscrições</a>
                                    <a class="dropdown-item" :href="'/Access/Unity/Unitie/'+unitie.id+'/oferta_processo_seletivo/conf/financeira'" v-if="unities_users.oferta_processo_seletivo==1 && unitie.oferta_processo_seletivo==1">Configuração Financeira Processo Seletivo</a>
                                    <a class="dropdown-item" :href="'/Access/Unity/Unitie/'+unitie.id+'/config'">Configurações de Unidade</a>
                              </div>
                          </li>
                          <!--<li class="nav-item">
                              <a class="nav-link disabled" href="#">Disabled</a>
                          </li>-->
                        </ul>
                          <!--<form class="form-inline my-2 my-lg-0">
                          <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
                          <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
                          </form>-->
                      </div>
                  </nav>
            <!-- </div>
            <div class="card-body" style="padding:0px;"> -->
                <slot name="conteudo">
                </slot>
            <!-- </div>
        </div> -->
        <slot name="rodape">
        </slot>
    </div>
</template>

<script>
export default {
    props:{
        unitie:{
            type:Object,
            required:true
        },
        linkUnidade:{
            type:String,
            required:true
        },
        usuarioLogado:{
            type:Object,
            required:true
        }
    },
    data(){
        return{
            unities_users:{
                type:Object,
                required:true
            }
        }
    },
    created(){
        for(let i=0;i<this.unitie.unities_users.length;i++){
            if(this.usuarioLogado.id==this.unitie.unities_users[i].user_id){
                this.unities_users = this.unitie.unities_users[i]
            }
        }
    }
}
</script>

<style>

</style>