<template>
    <div >
        <div :id="'Div' + nomeFormulario" :name="'Div' + nomeFormulario" class="div-crud-sistema">
            <form :method="metodoFormulario" :action="linkAction" :id="nomeFormulario" :name="nomeFormulario">
                <div v-if="!edit">
                    <slot name="csrf">

                    </slot>
                </div>
                <input type="hidden" name="id_empresa" :value="processo_seletivo.id">
                <div>
                    <div class="form-row">
                        <div class="form-group col-md-3">
                            <label for="idProcesso">ID Processo (ERP)</label>
                            <input :readonly="visualizar" v-if="!errors.idProcesso" type="text" class="form-control" id="idProcesso" name="idProcesso" placeholder="ID Processo (ERP)" v-model="processo_seletivo.idProcesso">
                            <input :readonly="visualizar" v-else type="text" class="form-control is-invalid" id="idProcesso" name="idProcesso" placeholder="ID Processo (ERP)" v-model="processo_seletivo.idProcesso">
                            <div class="invalid-feedback" v-if="errors.idProcesso">
                                {{ errors.idProcesso[0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <label for="ano">Ano (ERP)</label>
                            <input :readonly="visualizar" v-if="!errors.ano" type="text" class="form-control" id="ano" name="ano" placeholder="Ano (ERP)" v-model="processo_seletivo.ano">
                            <input :readonly="visualizar" v-else type="text" class="form-control is-invalid" id="ano" name="ano" placeholder="Ano (ERP)" v-model="processo_seletivo.ano">
                            <div class="invalid-feedback" v-if="errors.ano">
                                {{ errors.ano[0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <label for="semestre">Semestre (ERP)</label>
                            <input :readonly="visualizar" v-if="!errors.semestre" type="text" class="form-control" id="semestre" name="semestre" placeholder="Semestre (ERP)" v-model="processo_seletivo.semestre">
                            <input :readonly="visualizar" v-else type="text" class="form-control is-invalid" id="semestre" name="semestre" placeholder="Semestre (ERP)" v-model="processo_seletivo.semestre">
                            <div class="invalid-feedback" v-if="errors.semestre">
                                {{ errors.semestre[0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <label for="chamada">Chamada (ERP)</label>
                            <input :readonly="visualizar" v-if="!errors.chamada" type="text" class="form-control" id="chamada" name="chamada" placeholder="Semestre (ERP)" v-model="processo_seletivo.chamada">
                            <input :readonly="visualizar" v-else type="text" class="form-control is-invalid" id="chamada" name="chamada" placeholder="Semestre (ERP)" v-model="processo_seletivo.chamada">
                            <div class="invalid-feedback" v-if="errors.chamada">
                                {{ errors.chamada[0] }}
                            </div>
                        </div>
                        
                        
                        
                        
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="descricaoprocesso">Descrição Processo (ERP)</label>
                            <input :readonly="visualizar" v-if="!errors.descricaoprocesso" type="text" class="form-control" id="descricaoprocesso" name="descricaoprocesso" placeholder="Descrição Processo (ERP)" v-model="processo_seletivo.descricaoprocesso">
                            <input :readonly="visualizar" v-else type="text" class="form-control is-invalid" id="descricaoprocesso" name="descricaoprocesso" placeholder="Descrição Processo (ERP)" v-model="processo_seletivo.descricaoprocesso">
                            <div class="invalid-feedback" v-if="errors.descricaoprocesso">
                                {{ errors.descricaoprocesso[0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="descricaotipoprocesso">Tipo Processo</label>
                            <input :readonly="visualizar" v-if="!errors.descricaotipoprocesso" type="text" class="form-control" id="descricaotipoprocesso" name="descricaotipoprocesso" placeholder="Descrição Processo (ERP)" v-model="processo_seletivo.descricaotipoprocesso">
                            <input :readonly="visualizar" v-else type="text" class="form-control is-invalid" id="descricaotipoprocesso" name="descricaotipoprocesso" placeholder="Descrição Processo (ERP)" v-model="processo_seletivo.descricaotipoprocesso">
                            
                            <div class="invalid-feedback" v-if="errors.descricaotipoprocesso">
                                {{ errors.descricaotipoprocesso[0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="descricaotipoprocesso">Modalidade</label>
                            <input :readonly="visualizar" v-if="!errors.modalidade" type="text" class="form-control" id="descricaoModalidade" name="descricaoModalidade" placeholder="Modalidade" v-model="processo_seletivo.modality.modalidade">
                            <input :readonly="visualizar" v-else type="text" class="form-control is-invalid" id="descricaoModalidade" name="descricaoModalidade" placeholder="Modalidade" v-model="processo_seletivo.modality.modalidade">
                            
                            <div class="invalid-feedback" v-if="errors.modalidade">
                                {{ errors.modalidade[0] }}
                            </div>
                        </div>
                        

                        <!-- <div class="form-group col-md-2" >
                            <label for="inputCodigoEmpresa">Ativo</label><br/>
                                <b-form-checkbox
                                    id="cadastroAtivo"
                                    v-model="processo_seletivo.ativo"
                                    name="cadastroAtivo"
                                    :value="'Ativo'"
                                    :unchecked-value="'Inativo'"
                                    :disabled="visualizar"
                                    :switch="true"
                                    style="vertical-align: bottom !important;"
                                ></b-form-checkbox>
                        </div> -->
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-2">
                            <label for="inputCelular">Data Inicio</label>
                            <date-picker v-model="processo_seletivo.datainicio" v-if="!errors.datainicio" format="DD/MM/YYYY" type="date" id="datainicio" name="datainicio" style="width: 100%;" placeholder="__/__/____" :disabled="visualizar"></date-picker>
                            <date-picker v-model="processo_seletivo.datainicio" v-else format="DD/MM/YYYY" type="date" id="datainicio" name="datainicio" style="width: 100%;" placeholder="__/__/____" :disabled="visualizar"></date-picker>
                            <div class="invalid-feedback" v-if="errors.datainicio">
                                {{ errors.datainicio[0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-2">
                            <label for="datatermino">Data Término</label>
                            <date-picker v-model="processo_seletivo.datatermino" v-if="!errors.datatermino" format="DD/MM/YYYY" type="date" id="datatermino" name="datatermino" style="width: 100%;" placeholder="__/__/____" :disabled="visualizar"></date-picker>
                            <date-picker v-model="processo_seletivo.datatermino" v-else  format="DD/MM/YYYY" type="date" id="datatermino" name="datatermino" style="width: 100%;" placeholder="__/__/____" :disabled="visualizar"></date-picker>
                            
                            <div class="invalid-feedback" v-if="errors.datatermino">
                                {{ errors.datatermino[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="form-row" v-if="users.length>0">
                    </div>
                </div>
                <br/>
                <a :href="'/'+urlSistema" class="btn btn-primary">Cancelar</a>
                <button :disabled="visualizar" type="button" class="btn btn-primary" @click="insereOuAtualiza()">Salvar</button>
                <button type="button" class="btn btn-primary" @click="exportarPSE()">Exportar para Rubeus</button>
            </form>
        </div>
        <modal-component titulo="Mensagem do Sistema" label-btn-fechar="OK" id="MensagemSistema" :url-redirect="'/'+urlSistema+'/'" :sem-padding="true">
            <template v-slot:titulo>
            </template>
            <template v-slot:conteudo>
                <table class="table table-sm table-hover" style="margin: 0px;">
                    <thead style="background:#1c4d5a!important;color:white;white-space: nowrap;">
                        <tr>
                            <th colspan="2" style="text-align:center;border: 0px;">
                                EMPRESA
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="2" style="padding:0px;">
                                <div class="alert alert-success" role="alert" style="margin:0px;text-align: center;">
                                    <b v-if="edit">Atualizado com sucesso!</b>
                                    <b v-else>Cadastrado com sucesso!</b>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="text-align:left;">
                                <b>ID:</b> {{ processo_seletivo.id }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="text-align:left;">
                                <b>NOME:</b> {{ processo_seletivo.nomecompleto }}                                    
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="text-align:left;">
                                <b>CNPJ:</b> {{ processo_seletivo.cnpj }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </modal-component>
        <modal-component titulo="Exportação CRM" label-btn-fechar="OK" id="ExportacaoCrmMsg" :url-redirect="'/'+urlSistema+'/'" :sem-padding="true">
            <template v-slot:titulo>
                Exportação CRM
            </template>
            <template v-slot:conteudo>
                <table class="table table-sm table-hover ">
                    <thead style="background:#1c4d5a!important;color:white;white-space: nowrap;">
                        <tr>
                            <th colspan="2" style="text-align:center;">
                                EMPRESA
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="2" style="padding:0px;">
                                <div class="alert alert-success" role="alert" style="margin:0px;text-align: center;">
                                    <b>Empresa Exportada para o CRM</b>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="text-align:left;">
                                <b>Código:</b> {{ empresa_exportada_crm.codigoempresa }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="text-align:left;">
                                <b>Id Exportação:</b> {{ empresa_exportada_crm.idexportacaocrm }}                                   
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="text-align:left;">
                                <b>Descrição Unidade:</b> {{ empresa_exportada_crm.nomecompleto }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </modal-component>
        <div :id="'DivTable' + nomeFormulario" :name="'DivTable' + nomeFormulario" class="div-table-sistema">
            <div class="table-crud-sistema">
                <table class="table table-sm table-hover ">
                    <thead style="background:#1c4d5a!important;color:white;">
                        <tr>
                            <th colspan="9" style="text-align:center;">
                                Cursos vinculadas ao PSE
                            </th>
                        </tr>
                        <tr>
                            <th style="text-align:center;">
                                Codigo Curso (ERP)
                            </th>
                            <th style="text-align:center;">
                                Código Identificação (ERP)
                            </th>
                            <th style="text-align:center;">
                                Descrição Curso
                            </th>
                            <th style="text-align:center;">
                                Descrição Documento
                            </th>
                            <th style="text-align:center;">
                                Periodo
                            </th>
                            <th style="text-align:center;">
                                Tipo Curso
                            </th>
                            <th style="text-align:center;">
                                Nível de Ensino
                            </th>
                            <th style="text-align:center;">
                                Modalidade
                            </th>
                            <th style="text-align:center;">
                                Ativo
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="curso,i in processo_seletivo.cursos" :key="i">
                            <td style="text-align:center;">
                                {{ curso.codigocurso}}
                            </td>
                            <td style="text-align:center;">
                                {{ curso.codigoidentificacao }}
                            </td>
                            <td style="text-align:left;">
                                {{ curso.descricaocurso }}
                            </td>
                            <td style="text-align:left;">
                                {{ curso.descricaodocumento }}
                            </td>
                            <td style="text-align:center;">
                                {{ curso.periodo }}
                            </td>
                            <td style="text-align:center;">
                                {{ curso.tipocurso }}
                            </td>
                            <td style="text-align:center;">
                                {{ curso.descricaonivelensino }}
                            </td>
                            <td style="text-align:center;">
                                {{ curso.descricaomodalidade }}
                            </td>
                            <td style="text-align:center;">
                                {{ curso.ativo }}
                            </td>
                        </tr>
                    </tbody>
    
                </table>
            </div>
        </div>
        <div v-if="msgErro && !success" style="margin-top: 15px;">
            <b-alert
                :show="dismissCountDownDanger"
                dismissible
                variant="danger"
                @dismissed="dismissCountDownDanger=0"
                @dismiss-count-down="countDownChangedDanger"
                >
                <strong>Mensagem:</strong> Não foi possível Exportar o Processo Seletivo.<br/>
                <strong>Resposta ERP:</strong> {{ msgErro }}
            </b-alert>
        </div>
        <div v-if="success && resposta_exportacao.resposta_requisicao.success" style="margin-top: 15px;">
            <b-alert
                :show="dismissCountDown"
                dismissible
                variant="success"
                @dismissed="dismissCountDown=0"
                @dismiss-count-down="countDownChanged"
                >
                <strong>Mensagem:</strong> Processo Seletivo exportado com sucesso para o CRM.<br/><br/>
                <div v-if="resposta_exportacao.retornos.length > 0">
                    <div v-for="resOferta, iResOferta in resposta_exportacao.retornos" :key="iResOferta">
                        <div v-if="!JSON.parse(resOferta.resposta).success" class="alert alert-danger" role="alert">
                            <strong>Oferta:</strong> {{ resOferta.dadosOferta.nome }}  <strong>Resposta:</strong>  {{ JSON.parse(resOferta.resposta).errors }}
                        </div>
                        <div v-else>
                            <strong>Oferta:</strong> {{ resOferta.dadosOferta.nome }}  <strong>Resposta:</strong>  Oferta exportada com sucesso para o CRM.
                        </div>
                    </div>
                </div>
            </b-alert>
        </div>
        <div :id="'DivTable' + nomeFormulario" :name="'DivTable' + nomeFormulario" class="div-table-sistema">
            <div class="table-crud-sistema">
                <table class="table table-sm table-hover ">
                    <thead style="background:#1c4d5a!important;color:white;">
                        <tr>
                            <th colspan="12" style="text-align:center;">
                                Turmas vinculadas ao PSE
                            </th>
                        </tr>
                        <tr>
                            <th style="text-align:center;">
                                Oferta
                            </th>
                            <th style="text-align:center;">
                                Ano
                            </th>
                            <th style="text-align:center;">
                                Descrição Turma
                            </th>
                            <th style="text-align:center;">
                                Descrição Ciclo
                            </th>
                            <th style="text-align:center;">
                                Inicio
                            </th>
                            <th style="text-align:center;">
                                Término
                            </th>
                            <th style="text-align:center;">
                                Inicio Inscrição
                            </th>
                            <th style="text-align:center;">
                                Término Inscrição
                            </th>
                            <th style="text-align:center;">
                                Tamanho
                            </th>
                            <th style="text-align:center;">
                                Situação
                            </th>
                            <th style="text-align:center;">
                                Valor
                            </th>
                            <th style="text-align:center;">
                                Ações
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="turma,i in processo_seletivo.turmas">
                            <tr :key="i">
                                <td style="text-align:center;">
                                    {{ turma.codigoofertapse }}
                                </td>
                                <td style="text-align:center;">
                                    {{ turma.anoinicio }}
                                </td>
                                <td style="text-align:left;">
                                    {{ turma.descricaoofertapse }}
                                </td>
                                <td style="text-align:center;">
                                    {{ turma.descricaociclo }}
                                </td>
                                <td style="text-align:center;">
                                    {{ formatDate(turma.datainicio,2) }}
                                </td>
                                <td style="text-align:center;">
                                    {{ formatDate(turma.datatermino,2) }}
                                </td>
                                <td style="text-align:center;" @dblclick.prevent="alterarDataInscricao(turma)">
                                    <div>
                                        {{ formatDate(turma.datainicioinscricao,2) }}
                                    </div>
                                </td>
                                <td style="text-align:center;">
                                    <div>{{ formatDate(turma.dataterminoinscricao,2) }}</div>
                                </td>
                                <td style="text-align:center;">
                                    {{ (turma.tamanhoturmaminimo>=0 ? 'MIN: ' + turma.tamanhoturmaminimo :'') }} {{ (turma.tamanhoturmaximo>=0 ? 'MÁX: ' + turma.tamanhoturmaximo :'') }}
                                </td>
                                <td style="text-align:center;">
                                    {{ turma.situacaoturma }}
                                </td>
                                <td style="text-align:center;">
                                    <b style="color:red;">{{ (turma.valoroferta==0 ? 'Isento':$root.numberToReal(turma.valoroferta)) }}</b>
                                </td>
                                <td style="text-align:center;">
                                    <a v-if="1 > 0" class="btn btn-primary btn-sm" :href="'/Access/Unity/Unitie/'+unidade.id+'/captacao/'+turma.id">WebHooks</a>
                                </td>
                            </tr>
                            <tr v-if="turma.polos_turma.length>0">
                                <td colspan="12">
                                    <table class="table table-sm table-hover table-bordered">
                                        <thead style="background:#1c4d5a!important;color:white;white-space: nowrap;">
                                            <tr>
                                                <th colspan="5" class="align-middle" style="width: 30px;text-align: center;">Polos da Turma</th>
                                            </tr>
                                            <tr>
                                                <th class="align-middle" style="width: 30px;">Empresa Polo</th>
                                                <th class="align-middle" style="width: 30px;">Unidade Polo</th>
                                                <th class="align-middle">Descrição Polo</th>
                                                <th class="align-middle" style="width: 30px;">Exportado</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="polo in turma.polos_turma" :key="polo.id">
                                            <td class="align-middle" style="text-align: center;">
                                                {{ polo.idempresapolo }}
                                            </td>
                                            <td class="align-middle" style="text-align: center;">
                                                {{ polo.idunidadepolo }}
                                            </td>
                                            <td class="align-middle" style="text-align: center;">
                                                {{ polo.descricaopolo }}
                                            </td>
                                            <td class="align-middle" style="text-align: center;">
                                                <b-icon v-if="polo.idexportacao > 0" icon="check-lg" font-scale="1.3" style="color:green"></b-icon>
                                                <b-icon v-else icon="x-lg" font-scale="1.3" style="color:red"></b-icon>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </template>
                    </tbody>
    
                </table>
            </div>
            <loading id="exportarProcessoSeletivo" titulo="Exportando Ofertas para o CRM ..." :sem-padding="true" gif-carregando="/imagens/carregando.gif" @cancelaRequisicao="cancelarRequisicao()"></loading>
        </div>
    </div>
</template>

<script>


export default{

    methods:{
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },
        countDownChangedDanger(dismissCountDownDanger) {
            this.dismissCountDownDanger = dismissCountDownDanger
        },
        showAlertDanger() {
            this.dismissCountDownDanger = this.dismissSecsDanger
        },
        cancelarRequisicao(){
            
            this.cancelTokenSource.cancel("Pesquisa de PSES do ERP cancelada");
            this.msgCancelamento = "Pesquisa de PSES do ERP cancelada"
            
        },
        dissmiss(id){
            this.$bvModal.hide(id);
        },
        novo(id){
            this.user = {}
            this.modoeditar = false
            this.modoexcluir = false
            this.modonovo = true
            this.texto_modal = "Novo"
            this.user.id=id
            // console.log(this.user)
        },
        editar(usuarioSelecionado){
            this.user = usuarioSelecionado;
            this.modoeditar = true
            this.modoexcluir = false
            this.modonovo = false
            this.texto_modal = "Editar"
            // console.log(this.user)
            // console.log(this.user)
        },
        excluir(usuarioSelecionado){
            this.user = usuarioSelecionado;
            this.modoeditar = false
            this.modoexcluir = true
            this.modonovo = false
            this.texto_modal = "Excluir"
            // console.log(this.user)
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.nameState = valid
            return valid
        },
        resetModal() {
            this.name = ''
            this.nameState = null
        },
        removerPorId: function (id) {
            var _vm = this; //Adicionando a instância à uma variável, para que possa ser acessada dentro do forEach`
            this.users.forEach(function(el, index) {
                if(el.id === id)
                    _vm.users.splice(index, 1);
            })
        },
        alterarDataInscricao(turma){
            window.alert(turma.descricaocompleta + ' - Inicio Matrícula:' + turma.datainicioinscricao + ' - Término Matrícula:' + turma.dataterminoinscricao)
        },
        insereOuAtualiza(){
            if(!this.edit){
                this.inserirCompany();
                console.log('insere')
            }else{
                this.atualizaCompany();
                console.log('atualiza')
            }
        },
        inserirCompany(){
            // if(this.modonovo){
                let url='/api/v1/'+this.urlSistema;
                console.log(url)
                let formData = new FormData();
                formData.append('tipocadastro', this.tipoCadastro)
                formData.append('codigoempresa', (this.processo_seletivo.codigoempresa?this.processo_seletivo.codigoempresa:''))
                formData.append('nomecompleto', (this.processo_seletivo.nomecompleto?this.processo_seletivo.nomecompleto:''))
                formData.append('cnpj', (this.processo_seletivo.cnpj?this.processo_seletivo.cnpj:''))
                formData.append('periodo', (this.processo_seletivo.periodo?this.processo_seletivo.periodo:''))
                formData.append('tipocurso', (this.processo_seletivo.tipocurso?this.processo_seletivo.tipocurso:''))
                formData.append('celular', (this.processo_seletivo.celular?this.processo_seletivo.celular:''))
                formData.append('logradouro', (this.processo_seletivo.logradouro?this.processo_seletivo.logradouro:''))
                formData.append('numero', (this.processo_seletivo.numero?this.processo_seletivo.numero:''))
                formData.append('complemento', (this.processo_seletivo.complemento?this.processo_seletivo.complemento:''))
                formData.append('bairro', (this.processo_seletivo.bairro?this.processo_seletivo.bairro:''))
                formData.append('cidade', (this.processo_seletivo.cidade?this.processo_seletivo.cidade:''))
                formData.append('estado', (this.processo_seletivo.estado && this.processo_seletivo.estado != '0' ?this.processo_seletivo.estado:''))
                formData.append('cep', (this.processo_seletivo.cep?this.processo_seletivo.cep:''))
                formData.append('urlapirubeus', (this.processo_seletivo.urlapirubeus?this.processo_seletivo.urlapirubeus:''))
                formData.append('tokenapirubeus', (this.processo_seletivo.tokenapirubeus?this.processo_seletivo.tokenapirubeus:''))
                formData.append('urlapigvdasa', (this.processo_seletivo.urlapigvdasa?this.processo_seletivo.urlapigvdasa:''))
                formData.append('tokenapigvdasa', (this.processo_seletivo.tokenapigvdasa?this.processo_seletivo.tokenapigvdasa:''))
                formData.append('ativo',(this.processo_seletivo.ativo=='Ativo'?'1':'0'))
                if(this.tipoCadastro==2){
                    formData.append('nomereduzido', (this.processo_seletivo.nomereduzido?this.processo_seletivo.nomereduzido:''))
                }else{
                    formData.append('nomereduzido', '')
                }
                formData.append('email',(this.processo_seletivo.email?this.processo_seletivo.email:''))

                let configuracao = {
                    // method:'post',
                    // headers:{
                    //     // // 'Accept': 'application/json',
                    //     // // 'Authorization': this.$root.token(),
                    // }
                }
                
                axios.post(url,formData,configuracao)
                    .then(response => {
                        this.transacaoStatus = response.status
                        this.processo_seletivo = response.data.usuarioNovo;
                        this.errors={};
                        this.processo_seletivo.CadastroTipo=2
                        $('#MensagemSistema').modal('show');
                    })
                    .catch(errors => {
                        this.errors = errors.response.data.errors
                        console.log(errors.response)
                    })
            // }
        },
        atualizaCompany(){
            // if(this.modonovo){
                let url='/api/v1/'+this.urlSistema+'/'+this.processo_seletivo.id;
                console.log(url)
                //console.log(this.processo_seletivo)                                  
                let formData = new FormData();
                formData.append('_method','patch');
                formData.append('tipocadastro', this.tipoCadastro)
                formData.append('codigoempresa', (this.processo_seletivo.codigoempresa?this.processo_seletivo.codigoempresa:''))
                formData.append('nomecompleto', (this.processo_seletivo.nomecompleto?this.processo_seletivo.nomecompleto:''))
                formData.append('cnpj', (this.processo_seletivo.cnpj?this.processo_seletivo.cnpj:''))
                formData.append('email', (this.processo_seletivo.email?this.processo_seletivo.email:''))
                formData.append('tipocurso', (this.processo_seletivo.tipocurso?this.processo_seletivo.tipocurso:''))
                formData.append('celular', (this.processo_seletivo.celular?this.processo_seletivo.celular:''))
                formData.append('logradouro', (this.processo_seletivo.logradouro?this.processo_seletivo.logradouro:''))
                formData.append('numero', (this.processo_seletivo.numero?this.processo_seletivo.numero:''))
                formData.append('complemento', (this.processo_seletivo.complemento?this.processo_seletivo.complemento:''))
                formData.append('bairro', (this.processo_seletivo.bairro?this.processo_seletivo.bairro:''))
                formData.append('cidade', (this.processo_seletivo.cidade?this.processo_seletivo.cidade:''))
                formData.append('estado', (this.processo_seletivo.estado && this.processo_seletivo.estado != '0' ?this.processo_seletivo.estado:''))
                formData.append('cep', (this.processo_seletivo.cep?this.processo_seletivo.cep:''))
                formData.append('urlapirubeus', (this.processo_seletivo.urlapirubeus?this.processo_seletivo.urlapirubeus:''))
                formData.append('tokenapirubeus', (this.processo_seletivo.tokenapirubeus?this.processo_seletivo.tokenapirubeus:''))
                formData.append('urlapigvdasa', (this.processo_seletivo.urlapigvdasa?this.processo_seletivo.urlapigvdasa:''))
                formData.append('tokenapigvdasa', (this.processo_seletivo.tokenapigvdasa?this.processo_seletivo.tokenapigvdasa:''))
                formData.append('ativo',(this.processo_seletivo.ativo=='Ativo'?'1':'0'))
                if(this.responsavel_id>0){
                    formData.append('responsavel_id', (this.responsavel_id))
                }
                if(this.tipoCadastro==2){
                    formData.append('nomereduzido', (this.processo_seletivo.nomereduzido?this.processo_seletivo.nomereduzido:''))
                }else{
                    formData.append('nomereduzido', '')
                }
                formData.append('email',(this.processo_seletivo.email?this.processo_seletivo.email:''))
                
                let configuracao = {
                    // method:'put',
                    // headers:{
                    // //     'Accept': 'application/json',
                    // //     'Authorization': this.$root.token()
                    // },
                }
                
                axios.post(url, formData,configuracao)
                    .then(response => {
                        // console.log(response)
                        this.transacaoStatus = response.status
                        this.processo_seletivo = response.data.usuarioNovo;
                        this.errors={};
                        this.processo_seletivo.CadastroTipo=2
                        $('#MensagemSistema').modal('show');
                    })
                    .catch(errors => {
                        this.errors = errors.response.data.errors
                        console.log(errors.response.data)
                    })
            // }
        },
        exportarPSE(){
            this.CancelToken = axios.CancelToken;
            $('#exportarProcessoSeletivo').modal('show');
            this.cancelTokenSource = this.CancelToken.source();
            console.log(this.processo_seletivo)
            var url= '/api/v1/Crm/exportarPSERubeus/' + this.unidade.id;
            // console.log(url)
            let formData = new FormData();
            let datas={
                datainicio:this.formatDate(this.processo_seletivo.datainicio),
                datatermino:this.formatDate(this.processo_seletivo.datatermino)
            }

            console.log('processo_seletivo',this.processo_seletivo)
            formData.append('dadosPse', JSON.stringify(this.processo_seletivo))
            formData.append('datasPse',JSON.stringify(datas));
            let configuracao = {
                // method:'post',
                // headers:{
                //     // // 'Accept': 'application/json',
                //     // // 'Authorization': this.$root.token(),
                // }
            }
            
            axios.post(url,formData,configuracao)
                .then(response => {

                    if(response.data.erro){
                        $('#exportarProcessoSeletivo').modal('hide');
                        
                        //window.alert(response.data.erro)
                        let erro_msg = response.data.erro;
                        this.msgErro = erro_msg
                        console.log(this.msgErro)
                        this.success = false;
                        this.showAlertDanger();

                    }else{
                        $('#exportarProcessoSeletivo').modal('hide');

                        this.transacaoStatus = response.status
                        this.resposta_exportacao = response.data.dadosPse.original
                        console.log('resposta_exportacao',this.resposta_exportacao)
                        // this.processo_seletivo = response.data.dadosOferta;
                        // this.errors={};
                        this.success = true;
                        this.showAlert();
                        //window.alert('Exportação realizada com sucesso.')
                        // $('#MensagemSistema').modal('show');
                    }
                })
                .catch(errors => {
                    console.log(errors)
                    this.errors = errors.response.data.errors
                })

        },
        excluirUsuarioCliente(id){
            
            if(this.modoexcluir){
                var config = {
                method: 'delete',
                url: '/api/v1/'+this.urlSistema+'/'+id,
                // headers: {
                //         // 'Accept': 'application/json',
                //         // 'Authorization': this.$root.token(),
                //     },
                };
                //executa requisição
                axios(config)
                .then(response => {
                    debugger;
                    // console.log(response.data.usuario)
                    this.$bvModal.hide('modal-prevent-closing')
                    this.$nextTick(() => {
                        window.alert("Usuário de Cliente excluído com sucesso");
                    })
                    this.removerPorId(id)
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
        },
        formatDate(date,idioma) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            if(idioma==1){
                let dt = [year, month, day].join('-');
                if(dt=='1969-12-31'){
                    return null
                }else{
                    return dt;
                }
            }else if(idioma==2){
                let dt = [day, month, year].join('/');
                if(dt=='31/12/1969'){
                    return null
                }else{
                    return dt;
                }
            }else{
                let dt = [year, month, day].join('-');
                if(dt=='1969-12-31'){
                    return null
                }else{
                    return dt;
                }
            }
        },
    },
    mounted(){
        // this.$root.atualizaCookie('token');
    },
    created(){

        if(this.processo){
            this.processo_seletivo = this.processo;
            var dataIni = new Date(this.processo_seletivo.datainicio);
            var dataTer = new Date(this.processo_seletivo.datatermino);
            this.processo_seletivo.datainicio = dataIni;
            this.processo_seletivo.datatermino = dataTer;
            // console.log(this.processo_seletivo)
            this.users = this.usuarios;
            this.doc=this.processo.cnpj;
            
            if(this.processo.responsavel_id>0){
                this.responsavel_id=this.processo.responsavel_id
            }

            
        }else{
            this.processo_seletivo.CadastroTipo=2;
            this.tipoCadastro=2;
            this.processo_seletivo.estado="0";
        }
    },
    props:{
        unidade:{
            type:Object,
            required:true
        },
        urlSistema:{
            type:String,
            required:true
        },
        visualizar:{
            type:Boolean,
            required:true
        },
        nomeFormulario:{
            type:String,
            required:true
        },
        metodoFormulario:{
            type:String,
            required:true
        },
        linkAction:{
            type:String,
            required:true
        },
        processo:{
            type:Object,
            required:false
        },
        usuarioAutenticado:{
            type:Object,
            required:false
        },
        usuarios:[],
        edit:{
            type:Boolean,
            required:true
        }
    },
    watch: {
    // whenever question changes, this function will run
        texto_modal(newTexto, oldTtexto) {
            if (newTexto!=oldTtexto) {
                this.texto_modal=newTexto;
            }
        }
        ,tipoCadastro(newTipoCadastro, oldTipoCadastro) {
            if (newTipoCadastro!=oldTipoCadastro) {
                this.processo_seletivo.CadastroTipo=newTipoCadastro;
                this.tipoCadastro=newTipoCadastro;
                this.errors={};
            }
        },
        doc(newdoc, olddoc) {
            if (newdoc!=olddoc) {
                this.processo_seletivo.cnpj=newdoc;
                this.doc=newdoc;
            }
        },
    },
    data(){
        return{
            dismissSecs: 15,
            dismissCountDown: 0,
            dismissSecsDanger: 7,
            dismissCountDownDanger: 0,
            transacaoStatus:null,
            modonovo:false,
            modoeditar:false,
            modoexcluir:false,
            Novo:false,
            texto_modal:'',
            nameState: null,
            senhaState:null,
            emailState:null,
            submittedNames: [],
            tipoCadastro:null,
            doc:null,
            processo_seletivo:{
                id:null,
                nomecompleto:null,
                nomereduzido:null,
                cnpj:null,
                logradouro:null,
                numero:null,
                complemento:null,
                cep:null,
                pais:null,
                estado:null,
                cidade:null,
                bairro:null,
                site:null,
                email:null,
                tipocurso:null,
                celular:null,
                codigoempresa:null,
                /**
                 * cpf = 1
                 * cnpj = 2
                 */
                CadastroTipo:0,
                // link_integracao_gvdasa:null,
                // token_api_gvdasa:null,
                // link_integracao_rubeus:null,
                // token_api_rubeus:null,
                // origem:null,

            },
            user:{
                id:null,
                name:null,
                email:null,
                senha:null,
            },
            responsavel_id:0,
            users:[],
            errors:{},
            empresa_exportada_crm:[{
                id:null,
                codigo:null,
                descricao:null,
                nome:null,
                oridem:null
            }],
            CancelToken:null,
            cancelTokenSource:null,
            msgCancelamento:null,
            msgErro:null,
            success:null,
            resposta_exportacao:{}
        }
    }
}
</script>

<style scoped>
    form{
        text-align: left!important;
    }

    .div-crud-sistema{
        background: white;
        padding: 15px;
        border-radius: 0px 0px 10px 10px;

    }

    .div-table-sistema{
        background: white;
        padding: 15px;
        border-radius: 10px;
        margin-top:15px;

    }

    .table-crud-sistema{
        background: white;
        border-radius: 0px 0px 10px 10px;
        overflow-x: auto;
    }

</style>
