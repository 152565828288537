<template>
    <div class="div-crud-sistema">
        <div :id="'Div' + nomeFormulario" :name="'Div' + nomeFormulario">
            <form :method="metodoFormulario" :action="linkAction" :id="nomeFormulario" :name="nomeFormulario">
                <div v-if="!edit">
                    <slot name="csrf">

                    </slot>
                </div>
                <input type="hidden" name="id_empresa" :value="cliente.id">
                <div class="form-row">
                    <div class="form-group col-md-4">
                        <label for="tipoCadastroPessoa">Tipo de Cadastro</label>
                        <select  v-if="edit" disabled id="tipoCadastroPessoa" name="tipoCadastroPessoa" class="form-control" v-model="tipoCadastro">
                            <option value="2">CNPJ</option>
                        </select>
                        <select  :disabled="visualizar"  v-else id="tipoCadastroPessoa" name="tipoCadastroPessoa" class="form-control" v-model="tipoCadastro">
                            <option value="2">CNPJ</option>
                        </select>
                    </div>
                </div>
                <div v-if="cliente.CadastroTipo!=0 && cliente.CadastroTipo==2">
                    <div class="form-row">
                        <div class="form-group col-md-10">
                            <label for="inputCodigoEmpresa">Empresa</label><br/>
                            <select  :disabled="visualizar"  id="inputCodigoEmpresa" name="inputCodigoEmpresa" class="form-control" v-model="codigoempresa">
                                <option value="0">Selecione uma Empresa</option>
                                <option v-for="empresa in empresas" :value="empresa.id" :key="empresa.id">{{ empresa.nomecompleto }} - {{ empresa.nomereduzido }} - {{empresa.cnpj}}</option>
                                
                            </select>
                        </div>
                        <div class="form-group col-md-2" >
                            <label for="inputCadastroAtivo">Ativo</label><br/>
                                <b-form-checkbox
                                    id="inputCadastroAtivo"
                                    v-model="cliente.ativo"
                                    name="inputCadastroAtivo"
                                    :value="'Ativo'"
                                    :unchecked-value="'Inativo'"
                                    :disabled="visualizar"
                                    :switch="true"
                                    style="vertical-align: bottom !important;"
                                ></b-form-checkbox>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-3">
                            <label for="inputCodigoUnidadeErp">Codigo Unidade ERP</label>
                            <input :readonly="visualizar" v-if="!errors.codigounidade" type="text" class="form-control" id="inputCodigoUnidadeErp" name="inputCodigoUnidadeErp" placeholder="Código Unidade" v-model="cliente.codigounidade">
                            <input :readonly="visualizar" v-else type="text" class="form-control is-invalid" id="inputCodigoUnidadeErp" name="inputCodigoUnidadeErp" placeholder="Código Unidade" v-model="cliente.codigounidade">
                            <div class="invalid-feedback" v-if="errors.codigounidade">
                                {{ errors.codigounidade[0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <label for="inputCodigoUnidadeCrm">Codigo Unidade CRM</label>
                            <input :readonly="visualizar" v-if="!errors.codigounidadeexterno" type="text" class="form-control" id="inputCodigoUnidadeCrm" name="inputCodigoUnidadeCrm" placeholder="Código Unidade" v-model="cliente.codigounidadeexterno">
                            <input :readonly="visualizar" v-else type="text" class="form-control is-invalid" id="inputCodigoUnidadeCrm" name="inputCodigoUnidadeCrm" placeholder="Código Unidade" v-model="cliente.codigounidadeexterno">
                            <div class="invalid-feedback" v-if="errors.codigounidadeexterno">
                                {{ errors.codigounidadeexterno[0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <label for="inputCodigoUnidadePk">Codigo Unidade PK (ERP)</label>
                            <input :readonly="visualizar" v-if="!errors.codigounidadepk" type="text" class="form-control" id="inputCodigoUnidadePk" name="inputCodigoUnidadePk" placeholder="Código Unidade" v-model="cliente.codigounidadepk">
                            <input :readonly="visualizar" v-else type="text" class="form-control is-invalid" id="inputCodigoUnidadePk" name="inputCodigoUnidadePk" placeholder="Código Unidade" v-model="cliente.codigounidadepk">
                            <div class="invalid-feedback" v-if="errors.codigounidadepk">
                                {{ errors.codigounidadepk[0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <label for="inputOrigem">Origem(CRM)</label>
                            <input :readonly="visualizar" v-if="!errors.origem" type="text" class="form-control" id="inputOrigem" name="inputOrigem" placeholder="Código Empresa" v-model="cliente.origem">
                            <input :readonly="visualizar" v-else type="text" class="form-control is-invalid" id="inputOrigem" name="inputOrigem" placeholder="Código Empresa" v-model="cliente.origem">
                            <div class="invalid-feedback" v-if="errors.origem">
                                {{ errors.origem[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="inputRazaoSocial">Nome Completo</label>
                            <input :readonly="visualizar" v-if="!errors.nomecompleto" type="text" class="form-control" id="inputRazaoSocial" name="inputRazaoSocial" placeholder="Nome Completo" v-model="cliente.nomecompleto">
                            <input :readonly="visualizar" v-else type="text" class="form-control is-invalid" id="inputRazaoSocial" name="inputRazaoSocial" placeholder="Nome Completo" v-model="cliente.nomecompleto">
                            <div class="invalid-feedback" v-if="errors.nomecompleto">
                                {{ errors.nomecompleto[0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="inputNomeFantasia">Nome Reduzido</label>
                            <input :readonly="visualizar" v-if="!errors.nomereduzido" type="text" class="form-control" id="inputNomeFantasia" name="inputNomeFantasia" placeholder="Nome Reduzido" v-model="cliente.nomereduzido">
                            <input :readonly="visualizar" v-else type="text" class="form-control is-invalid" id="inputNomeFantasia" name="inputNomeFantasia" placeholder="Nome Reduzido" v-model="cliente.nomereduzido">
                            <div class="invalid-feedback" v-if="errors.nomereduzido">
                                {{ errors.nomereduzido[0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-2">
                            <label for="inputCNPJ">CNPJ</label>
                            <the-mask :readonly="visualizar" :masked="true" v-if="!errors.cnpj" :mask="['##.###.###/####-##']" class="form-control" id="inputCNPJ" name="inputCNPJ" placeholder="CNPJ" v-model="doc"/>
                            <the-mask :readonly="visualizar" :masked="true" v-else :mask="['##.###.###/####-##']" class="form-control is-invalid" id="inputCNPJ" name="inputCNPJ" placeholder="CNPJ" v-model="doc"/>
                            <div class="invalid-feedback" v-if="errors.cnpj">
                                {{ errors.cnpj[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="inputCelular">E-mail</label>
                            <input :readonly="visualizar" type="text" v-if="!errors.email"  class="form-control" id="inputEmail" name="inputEmail" placeholder="E-mail " v-model="cliente.email">
                            <input :readonly="visualizar" type="text" v-else class="form-control is-invalid" id="inputEmail" name="inputEmail" placeholder="E-mail " v-model="cliente.email">
                            <div class="invalid-feedback" v-if="errors.email">
                                {{ errors.email[0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="inputFone">Telefone</label>
                            <the-mask :readonly="visualizar" :masked="true" v-if="!errors.telefone" :mask="['(##)####-####','(##)#####-####']" type="text" class="form-control" id="inputFone" name="inputFone" placeholder="Telefone" v-model="cliente.telefone"/>
                            <the-mask :readonly="visualizar" :masked="true" v-else :mask="['(##)####-####','(##)#####-####']" type="text" class="form-control is-invalid" id="inputFone" name="inputFone" placeholder="Telefone" v-model="cliente.telefone"/>
                            <div class="invalid-feedback" v-if="errors.telefone">
                                {{ errors.telefone[0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="inputCelular">Celular</label>
                            <the-mask :readonly="visualizar" :masked="true" :mask="['(##)####-####','(##)#####-####']" type="text" class="form-control" id="inputCelular" name="inputCelular" placeholder="Celular" v-model="cliente.celular"/>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="inputEndereco">Endereço</label>
                            <input :readonly="visualizar" type="text" class="form-control" id="inputEndereco" name="inputEndereco" placeholder="Endereço, Nº" v-model="cliente.logradouro">
                        </div>
                        <div class="form-group col-md-2">
                            <label for="inputEndereco">Nº</label>
                            <input :readonly="visualizar" type="text" class="form-control" id="inputNumero" name="inputNumero" placeholder="Nº" v-model="cliente.numero">
                        </div>
                        <div class="form-group col-md-4">
                            <label for="inputEndereco">Complemento</label>
                            <input :readonly="visualizar" type="text" class="form-control" id="inputComplemento" name="inputComplemento" placeholder="Complemento" v-model="cliente.complemento">
                        </div>
                        
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-3">
                            <label for="inputBairro">Bairro</label>
                            <input :readonly="visualizar" type="text" class="form-control" id="inputBairro" name="inputBairro" placeholder="Bairro, Distrito" v-model="cliente.bairro">
                        </div>
                        <div class="form-group col-md-3">
                            <label for="inputCidade">Cidade</label>
                            <input :readonly="visualizar" type="text" class="form-control" id="inputCidade" name="inputCidade" placeholder="Cidade" v-model="cliente.cidade">
                        </div>
                        <div class="form-group col-md-2">
                            <label for="inputUF">UF</label>
                            <select :disabled="visualizar" id="inputUF" name="inputUF" class="form-control" v-model="cliente.estado">
                                <option value="">Selecione o Estado</option>
                                <option value="RS">RS</option>
                                <option value="AC">AC</option>
                                <option value="AL">AL</option>
                                <option value="AM">AM</option>
                                <option value="AP">AP</option>
                                <option value="BA">BA</option>
                                <option value="CE">CE</option>
                                <option value="DF">DF</option>
                                <option value="ES">ES</option>
                                <option value="GO">GO</option>
                                <option value="MA">MA</option>
                                <option value="MG">MG</option>
                                <option value="MS">MS</option>
                                <option value="MT">MT</option>
                                <option value="PA">PA</option>
                                <option value="PB">PB</option>
                                <option value="PE">PE</option>
                                <option value="PI">PI</option>
                                <option value="PR">PR</option>
                                <option value="RJ">RJ</option>
                                <option value="RN">RN</option>
                                <option value="RO">RO</option>
                                <option value="RR">RR</option>
                                <option value="SC">SC</option>
                                <option value="SE">SE</option>
                                <option value="SP">SP</option>
                                <option value="TO">TO</option>
                            </select>
                        </div>
                        <div class="form-group col-md-2">
                            <label for="inputCidade">País</label>
                            <input :readonly="visualizar" type="text" class="form-control" id="inputPais" name="inputPais" placeholder="País" v-model="cliente.pais">
                        </div>
                        <div class="form-group col-md-2">
                            <label for="inputCep">CEP</label>
                            <the-mask :readonly="visualizar" :masked="true" :mask="['#####-###']" type="text" class="form-control" id="inputCep" name="inputCep" placeholder="CEP"  v-model="cliente.cep"/>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <hr>
                            <label><b>Módulos Licenciados</b></label>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-2">
                            <b-form-checkbox
                                id="oferta_processo_seletivo"
                                v-model="cliente.oferta_processo_seletivo"
                                name="oferta_processo_seletivo"
                                :value="1"
                                :unchecked-value="0"
                                :disabled="visualizar"
                                :switch="true"
                            >
                            Oferta de Processo Seletivo
                            </b-form-checkbox>
                        </div>
                        <div class="form-group col-md-2">
                            <b-form-checkbox
                                id="oferta_Inscricao_web"
                                v-model="cliente.oferta_Inscricao_web"
                                name="oferta_Inscricao_web"
                                :value="1"
                                :unchecked-value="0"
                                :disabled="visualizar"
                                :switch="true"
                            >
                            Oferta de Inscrição WEB
                            </b-form-checkbox>
                        </div>
                        <div class="form-group col-md-2">
                            <b-form-checkbox
                                id="inscricao_web_aluno"
                                v-model="cliente.inscricao_web_aluno"
                                name="inscricao_web_aluno"
                                :value="1"
                                :unchecked-value="0"
                                :disabled="visualizar"
                                :switch="true"
                            >
                            Inscrição do Aluno
                            </b-form-checkbox>
                        </div>
                        <div class="form-group col-md-2">
                            <b-form-checkbox
                                id="importar_parcela"
                                v-model="cliente.importar_parcela"
                                name="importar_parcela"
                                :value="1"
                                :unchecked-value="0"
                                :disabled="visualizar"
                                :switch="true"
                            >
                            Importação de Parcela
                            </b-form-checkbox>
                        </div>
                        <div class="form-group col-md-2">
                            <b-form-checkbox
                                id="download_contrato"
                                v-model="cliente.download_contrato"
                                name="download_contrato"
                                :value="1"
                                :unchecked-value="0"
                                :disabled="visualizar"
                                :switch="true"
                            >
                            Download de Contrato
                            </b-form-checkbox>
                        </div>
                    </div>
                    <div class="form-row" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                        <div class="form-group col-md-12 text-center">
                            <br/><h5>CONFIGURAÇÃO DE TIPOS DE CONTATOS E DOCUMENTOS OBRIGATÓRIOS DO ALUNO</h5>
                        </div>
                    </div>
                    <div class="form-row" v-if="(errors.codigotipocontatotelefone || errors.mascarapse || errors.mascarainscricao || errors.codigotipocontatoemail || errors.custom_field_id || errors.document_id) && message_add==''" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                        <div class="form-group col-md-12">
                            <div class="alert alert-danger" role="alert" v-if="(errors.codigotipocontatotelefone || errors.mascarapse || errors.mascarainscricao || errors.codigotipocontatoemail || errors.custom_field_id || errors.document_id) && message_add==''">
                                <ul class="list-unstyled">
                                    <li v-if="errors.codigotipocontatotelefone"><strong>Tipos de Contato Telefone</strong>
                                        <ul>
                                            <li v-for="tipocontatotel, itel in errors.codigotipocontatotelefone" :key="itel">{{ tipocontatotel }}</li>
                                        </ul>
                                    </li>
                                    <li v-if="errors.codigotipocontatoemail"><strong>Tipos de Contato E-mail</strong>
                                        <ul>
                                            <li v-for="tipocontatoemail, iemail in errors.codigotipocontatoemail" :key="iemail">{{ tipocontatoemail }}</li>
                                        </ul>
                                    </li>
                                    <li v-if="errors.mascarainscricao"><strong>Máscara Oferta Inscrição</strong>
                                        <ul>
                                            <li v-for="maskinscricao, iinsc in errors.mascarainscricao" :key="iinsc">{{ maskinscricao }}</li>
                                        </ul>
                                    </li>
                                    <li v-if="errors.mascarapse"><strong>Máscara Oferta PSE</strong>
                                        <ul>
                                            <li v-for="maskpse, ipse in errors.mascarapse" :key="ipse">{{ maskpse }}</li>
                                        </ul>
                                    </li>
                                    <li v-if="errors.document_id"><strong>Documento do Aluno</strong>
                                        <ul>
                                            <li v-for="document, item in errors.document_id" :key="item">{{ document }}</li>
                                        </ul>
                                    </li>
                                    <li v-if="errors.custom_field_id"><strong>Campo Personalizado</strong>
                                        <ul>
                                            <li v-for="campo, item in errors.custom_field_id" :key="item">{{ campo }}</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="mascaras-tab" data-toggle="tab" href="#mascaras" role="tab" aria-controls="mascaras" aria-selected="false">MÁSCARAS DE OFERTAS</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="tipocontato-tab" data-toggle="tab" href="#tipocontato" role="tab" aria-controls="tipocontato" aria-selected="true">TIPOS DE CONTATO</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="tipodocumento-tab" data-toggle="tab" href="#tipodocumento" role="tab" aria-controls="tipodocumento" aria-selected="false">DOCUMENTOS OBRIGATÓRIOS</a>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade" id="tipocontato" role="tabpanel" aria-labelledby="tipocontato-tab" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                                    <div class="form-row">
                                        <div class="form-group col-md-12 text-center">
                                            <br/><h5>CONFIGURAÇÕES DE TIPOS DE CONTATO</h5>
                                        </div>
                                    </div>
                                    <div class="form-row" style="width: 100%;">
                                        <div class="form-group col-md-6">
                                            <label for="inputCodigoTipoContatoTelefone">Código Tipo Contato Telefone</label>
                                            <select v-if="!errors.codigotipocontatotelefone" class="form-control" id="inputCodigoTipoContatoTelefone" name="inputCodigoTipoContatoTelefone" v-model="cliente.codigotipocontatotelefone">
                                                <option :value="0">SELECIONE TIPO CONATATO TELEOFNE</option>
                                                <optgroup label="TIPO DE CONTATO PARA TELEFONES" >
                                                    <option v-for="tipocontato,key in tipoContatoTelefone" :key="key" :value="tipocontato.CODIGOTIPOCONTATOITEM">{{ tipocontato.DESCRICAO }}</option>
                                                </optgroup>
                                            </select>
                                            <select v-else class="form-control is-invalid" id="inputCodigoTipoContatoTelefone" name="inputCodigoTipoContatoTelefone" v-model="cliente.codigotipocontatotelefone">
                                                <option :value="0">SELECIONE TIPO CONATATO TELEOFNE</option>
                                                <optgroup label="TIPO DE CONTATO PARA TELEFONES" >
                                                    <option v-for="tipocontato,key in tipoContatoTelefone" :key="key" :value="tipocontato.CODIGOTIPOCONTATOITEM">{{ tipocontato.DESCRICAO }}</option>
                                                </optgroup>
                                            </select>
                                            <div class="invalid-feedback" v-if="errors.codigotipocontatotelefone">
                                                {{ errors.codigotipocontatotelefone[0] }}
                                            </div>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label for="inputCodigoTipoContatoEmail">Código Tipo Contato E-mail</label>
                                            <select v-if="!errors.codigotipocontatoemail" class="form-control" id="inputCodigoTipoContatoEmail" name="inputCodigoTipoContatoEmail" v-model="cliente.codigotipocontatoemail">
                                                <option :value="0">SELECIONE TIPO CONATATO E-MAIL</option>
                                                <optgroup label="TIPO DE CONTATO PARA E-MAIL" >
                                                    <option v-for="tipocontato,key in tipoContatoEmail" :key="key" :value="tipocontato.CODIGOTIPOCONTATOITEM">{{ tipocontato.DESCRICAO }}</option>
                                                </optgroup>
                                            </select>
                                            <select v-else class="form-control is-invalid" id="inputCodigoTipoContatoEmail" name="inputCodigoTipoContatoEmail" v-model="cliente.codigotipocontatoemail">
                                                <option :value="0">SELECIONE TIPO CONATATO E-MAIL</option>
                                                <optgroup label="TIPO DE CONTATO PARA E-MAIL" >
                                                    <option v-for="tipocontato,key in tipoContatoEmail" :key="key" :value="tipocontato.CODIGOTIPOCONTATOITEM">{{ tipocontato.DESCRICAO }}</option>
                                                </optgroup>
                                            </select>
                                            <div class="invalid-feedback" v-if="errors.codigotipocontatoemail">
                                                {{ errors.codigotipocontatoemail[0] }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="tipodocumento" role="tabpanel" aria-labelledby="tipodocumento-tab" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                                    <div class="form-row">
                                        <div class="form-group col-md-12 text-center">
                                            <br/><h5>CONFIGURAÇÕES DE TIPOS DE DOCUMENTOS OBRIGATÓRIOS DO ALUNO</h5>
                                            <button class="btn btn-primary btn-sm" @click.prevent="sincronizarTodos();"><b-icon icon="arrow-repeat" font-scale="1.3">Sincronizar Listas</b-icon></button><br>
                                            <small>Para atualizar a listagem de Documentos(ERP) e de Campos Personalizados(CRM) clique no botão acima.</small>
                                            
                                        </div>
                                    </div>
                                    <table class="table table-hover table-sm" style="border:0px!important">
                                        <thead style="border:0px!important">
                                            <!--tr style="background-color: #1c4d5a;color:white;">
                                                <th colspan="3" style="text-align: center;" class="align-middle">EVENTOS</th>
                                            </tr-->
                                            <tr style="background-color: #1c4d5a;color:white;">
                                                <th style="text-align: center;width: 40%;" class="align-middle">Lista de Campos Personalizados CRM</th>
                                                <th style="text-align: center;width: 40%;" class="align-middle">Lista de Documentos ERP</th>
                                                <!--th style="text-align: center;width: 40%;" class="align-middle">Obrigatório</th-->
                                                <!--th style="text-align: center;" class="align-middle">Valor Evento</th-->
                                                <th style="text-align: center;" class="align-middle">Ações</th>
                                            </tr>
                                            
                                        </thead>
                                        <tbody style="border:0px!important">
                                            <tr>                                                
                                                <td style="text-align: center;" class="align-middle">
                                                    <select class="form-control" name="camposPersonalizado" id="camposPersonalizado" v-if="campos_lista.length>0 && !errors.custom_field_id" v-model="docs_custom_fields.custom_field_id">
                                                        <option :value="0" selected>SELECIONE UM CAMPO PERSONALIZADOS OBRIGATÒRIO</option>
                                                        <optgroup label="CAMPOS PERSONALIZADOS CRM">
                                                            <option :value="campo.id" v-for="campo in campos_lista">{{ campo.nome }}</option>
                                                        </optgroup>
                                                    </select>
                                                    <select class="form-control is-invalid" v-else-if="campos_lista.length>0" name="camposPersonalizado" id="camposPersonalizado" v-model="docs_custom_fields.custom_field_id">
                                                        <option :value="0" selected>SELECIONE UM CAMPO PERSONALIZADOS OBRIGATÒRIO</option>
                                                        <optgroup label="CAMPOS PERSONALIZADOS CRM">
                                                            <option :value="campo.id" v-for="campo in campos_lista">{{ campo.nome }}</option>
                                                        </optgroup>
                                                    </select>
                                                    <div class="invalid-feedback" v-if="errors.custom_field_id">
                                                        {{ errors.custom_field_id[0] }}
                                                    </div>
                                                </td>
                                                <td style="text-align: center;" class="align-middle">
                                                    <select class="form-control" name="docsAluno" id="docsAluno" v-if="docs_lista.length>0  && !errors.document_id" v-model="docs_custom_fields.document_id">
                                                        <option :value="0" selected>SELECIONE UM DOCUMENTO DO ALUNO OBRIGATÒRIO</option>
                                                        <optgroup label="DOCUMENTOS ALUNO">
                                                            <option :value="doc.id" v-for="doc in docs_lista">{{ doc.descricao }}</option>
                                                        </optgroup>
                                                    </select>
                                                    <select class="form-control is-invalid" name="docsAluno" id="docsAluno"  v-else-if="campos_lista.length>0" v-model="docs_custom_fields.document_id">
                                                        <option :value="0" selected>SELECIONE UM DOCUMENTO DO ALUNO OBRIGATÒRIO</option>
                                                        <optgroup label="DOCUMENTOS ALUNO">
                                                            <option :value="doc.id" v-for="doc in docs_lista">{{ doc.descricao }}</option>
                                                        </optgroup>
                                                    </select>
                                                    <div class="invalid-feedback" v-if="errors.document_id">
                                                        {{ errors.document_id[0] }}
                                                    </div>
                                                </td>
                                                <!--td style="text-align: center;" class="align-middle">
                                                    
                                                            <b-form-checkbox
                                                                id="campo_obrigatorio"
                                                                v-model="docs_custom_fields.obrigatorio"
                                                                name="campo_obrigatorio"
                                                                :value="1"
                                                                :unchecked-value="0"
                                                                :disabled="false"
                                                                :switch="true"
                                                            >
                                                            {{ (docs_custom_fields.obrigatorio==0?'Não':'Sim') }}
                                                            </b-form-checkbox>
                                                        
                                                </td-->
                                                <td style="text-align: center;" class="align-middle">
                                                    <button class="btn btn-primary btn-sm" @click.prevent="inserirAlterarDocumentCustomField(docs_custom_fields)">{{ (docs_custom_fields.created_at ? 'Salvar Alterações':'Adicionar') }}</button>
                                                </td>
                                            </tr>
                                            <tr v-if="success_add==false">
                                                <td colspan="4" class="align-middle" style="text-align: center;">
                                                    <div class="alert alert-danger" role="alert" v-if="!success_add">
                                                        <small>{{ message_add }}</small>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr v-if="success_add==true">
                                                <td colspan="4" class="align-middle" style="text-align: center;">
                                                    <div class="alert alert-success" role="alert" v-if="success_add">
                                                        <small>{{ message_add }}</small>
                                                    </div>
                                                </td>
                                            </tr>
                                            <template v-if="docs_configurados_unidade.length>0">
                                                <tr v-for="docs_unidade, key in docs_configurados_unidade" :key="key">
                                                    <td colspan="2" style="text-align: left;" class="align-middle">
                                                        O Campo Personalizado <strong>{{ docs_unidade.custom_field.nome }} ("{{ docs_unidade.custom_field.coluna }}")</strong> do CRM
                                                        <br/>é equivalente ao
                                                        Documento <strong>{{ docs_unidade.document.descricao }}</strong> do ERP
                                                        
                                                        <!--select class="form-control" name="docsAluno" id="docsAluno" v-if="docs_lista.length>0" v-model="docs_unidade.document_id">
                                                            <optgroup label="DOCUMENTOS ALUNO">
                                                                <option :value="doc.id" v-for="doc in docs_lista">{{ doc.descricao }}</option>
                                                            </optgroup>
                                                        </select-->
                                                    </td>
                                                    <!--td style="text-align: center;" class="align-middle">
                                                        
                                                        <select class="form-control" name="camposPersonalizado" id="camposPersonalizado" v-if="campos_lista.length>0" v-model="docs_unidade.custom_field_id">
                                                            <optgroup label="CAMPOS PERSONALIZADOS CRM">
                                                                <option :value="campo.id" v-for="campo in campos_lista">{{ campo.nome }}</option>
                                                            </optgroup>
                                                        </select>
                                                    </td-->
                                                    <!--td>
                                                        {{ evento.valor }}
                                                    </td>
                                                    <td style="text-align: center;" class="align-middle" v-html="(docs_unidade.obrigatorio==0?'Não':'<b>Sim</b>')">
                                                        
                                                    </td-->
                                                    <td style="text-align: center;white-space:nowrap;" class="align-middle">
                                                        <button :disabled="docs_custom_fields.created_at" class="btn btn-primary btn-sm" @click.prevent="alterarConfigDoc(docs_unidade)">Alterar</button>
                                                        <button :disabled="docs_custom_fields.created_at" class="btn btn-primary btn-sm" @click.prevent="excluirDocumentCustomField(docs_unidade.id)">Remover</button>
                                                        <button v-if="docs_custom_fields.id==docs_unidade.id" class="btn btn-primary btn-sm" @click.prevent="cancelarDocumentCustomField()">Cancelar</button>
                                                    </td>
                                                    
                                                </tr>
                                            </template>
                                        </tbody>
                                        
                                    </table>
                                </div>
                                <div class="tab-pane fade show active" id="mascaras" role="tabpanel" aria-labelledby="mascaras-tab" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                                    <aviso-mascaras titulo="CONFIGURAÇÃO DE MÁSCARAS DE OFERTAS" cor="danger"></aviso-mascaras>
                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label for="inputmascarainscricao">Máscara Oferta Inscrição</label>
                                            <input v-if="!errors.mascarainscricao" type="text" class="form-control" id="inputmascarainscricao" name="inputmascarainscricao" placeholder="Máscara Oferta Inscrição" v-model="cliente.mascaraofertainscricao">
                                            <input v-else type="text" class="form-control is-invalid" id="inputmascarainscricao" name="inputmascarainscricao" placeholder="Máscara Oferta Inscrição" v-model="cliente.mascaraofertainscricao">
                                            <div class="invalid-feedback" v-if="errors.mascarainscricao">
                                                {{ errors.mascarainscricao[0] }}
                                            </div>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label for="inputmascarapse">Máscara Oferta PSE</label>
                                            <input v-if="!errors.mascarapse" type="text" class="form-control" id="inputmascarapse" name="inputmascarapse" placeholder="Máscara Oferta PSE" v-model="cliente.mascaraofertapse">
                                            <input v-else type="text" class="form-control is-invalid" id="inputmascarapse" name="inputmascarapse" placeholder="Máscara Oferta PSE" v-model="cliente.mascaraofertapse">
                                            <div class="invalid-feedback" v-if="errors.mascarapse">
                                                {{ errors.mascarapse[0] }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                    
                    
                    
                    
                </div>
                <br/>
                <a :href="'/Access/Unity/Unitie/'+cliente.id" class="btn btn-primary">Cancelar</a>
                <button type="button" class="btn btn-primary" @click="insereOuAtualiza()">Salvar</button>
                <button type="button" class="btn btn-primary" @click="exportarUnidadeCrm(cliente)">Exportar para Rubeus</button>
                <button type="button" class="btn btn-primary" @click="exportarLocaisOfertaCrm(cliente)">Exportar Local de Oferta</button>

            </form>
        </div>
        <modal-component titulo="Mensagem do Sistema" label-btn-fechar="OK" id="MensagemSistema" :url-redirect="'./config'"  :sem-padding="true">
            <template v-slot:conteudo>
                <table class="table table-sm table-hover" style="margin:0px;">
                    <thead style="background:#1c4d5a!important;color:white;white-space: nowrap;">
                        <tr>
                            <th colspan="2" style="text-align:center;border:0px;">
                                UNIDADE
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="2" style="padding:0px;">
                                <div class="alert alert-success" role="alert" style="margin:0px;text-align: center;">
                                    <b v-if="edit">Mascaras de Ofertas atualizadas com sucesso!</b>
                                    <b v-else>Cadastrado com sucesso!</b>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="text-align:left;">
                                <b>ID:</b> {{ cliente.id }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="text-align:left;">
                                <b>Nome:</b> {{ cliente.nomecompleto }}                                    
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="text-align:left;">
                                <b>CNPJ:</b> {{ cliente.cnpj }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </modal-component>
        <modal-component titulo="Exportação CRM" label-btn-fechar="OK" id="ExportacaoCrmMsg" :url-redirect="'./config'" :sem-padding="true">
            <template v-slot:titulo>
                Exportação CRM
            </template>
            <template v-slot:conteudo>
                <table class="table table-sm table-hover ">
                    <thead style="background:#1c4d5a!important;color:white;white-space: nowrap;">
                        <tr>
                            <th colspan="2" style="text-align:center;">
                                UNIDADE
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="2" style="padding:0px;">
                                <div class="alert alert-success" role="alert" style="margin:0px;text-align: center;">
                                    <b>Unidade Exportada para o CRM</b>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="text-align:left;">
                                <b>Código:</b> {{ unidade_exportada_crm.codigo }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="text-align:left;">
                                <b>Id Exportação:</b> {{ unidade_exportada_crm.id }}                                   
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="text-align:left;">
                                <b>Descrição Unidade:</b> {{ unidade_exportada_crm.descricao }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </modal-component>
        <modal-component titulo="Exportação CRM" label-btn-fechar="OK" id="ExportacaoCrmMsgLocalOferta" :url-redirect="'./config'" :sem-padding="true">
            <template v-slot:titulo>
                Exportação CRM
            </template>
            <template v-slot:conteudo>
                <table class="table table-sm table-hover ">
                    <thead style="background:#1c4d5a!important;color:white;white-space: nowrap;">
                        <tr>
                            <th colspan="2" style="text-align:center;">
                                UNIDADE
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="2" style="padding:0px;">
                                <div class="alert alert-success" role="alert" style="margin:0px;text-align: center;">
                                    <b>Unidade Exportada para o Local de Oferta CRM</b>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="text-align:left;">
                                <b>Código:</b> {{ local_oferta_crm.codigounidade }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="text-align:left;">
                                <b>Id Exportação:</b> {{ local_oferta_crm.codigolocaloferta }}                                   
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="text-align:left;">
                                <b>Unidade:</b> {{ local_oferta_crm.nomecompleto }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </modal-component>
        <loading id="sincronizarListaDocs" titulo="Sincronizando listas do ERP e CRM, Aguarde." :sem-padding="true" gif-carregando="/imagens/carregando.gif" @cancelaRequisicao="cancelarRequisicao()"></loading>
        <loading id="SalvandoConfiguracoes" titulo="Salvando Configurações da Unidade, Aguarde..." :sem-padding="true" gif-carregando="/imagens/carregando.gif"></loading>
        <loading id="ExcluindoConfiguracoes" titulo="Excluindo Configurações da Unidade, Aguarde..." :sem-padding="true" gif-carregando="/imagens/carregando.gif"></loading>
    </div>
</template>

<script>


export default{
    props:{
        documentosConfigurados:{
            type:Array,
            required:true
        },
        listagemCamposPersonalizados:{
            type:Array,
            required:true
        },
        listagemDocumentos:{
            type:Array,
            required:true
        },
        tipoContatoEmail:{
            type:Array,
            required:true
        },
        tipoContatoTelefone:{
            type:Array,
            required:true
        },
        urlSistema:{
            type:String,
            required:true
        },
        visualizar:{
            type:Boolean,
            required:true
        },
        nomeFormulario:{
            type:String,
            required:true
        },
        metodoFormulario:{
            type:String,
            required:true
        },
        linkAction:{
            type:String,
            required:true
        },
        pessoa:{
            type:Object,
            required:false
        },
        empresas:{
            type:Array,
            required:true
        },
        usuarioAutenticado:{
            type:Object,
            required:false
        },
        usuarios:[],
        edit:{
            type:Boolean,
            required:true
        }
    },
    data(){
        return{
            transacaoStatus:null,
            modonovo:false,
            modoeditar:false,
            modoexcluir:false,
            Novo:false,
            texto_modal:'',
            nameState: null,
            senhaState:null,
            emailState:null,
            submittedNames: [],
            tipoCadastro:null,
            doc:null,
            codigoempresa:0,
            docs_lista:[],
            campos_lista:[],
            docs_configurados_unidade:[],
            docs_custom_fields:{
                unity_id:null,
                document_id:0,
                custom_field_id:0,
                obrigatorio:0,
                id:null
            },
            cliente:{
                id:null,
                nomecompleto:null,
                nomereduzido:null,
                cnpj:null,
                logradouro:null,
                numero:null,
                complemento:null,
                cep:null,
                pais:null,
                estado:null,
                cidade:null,
                bairro:null,
                site:null,
                email:null,
                telefone:null,
                celular:null,
                codigounidade:null,
                company_id:null,
                ativo:null,
                origem:null,
                /**
                 * cpf = 1
                 * cnpj = 2
                 */
                CadastroTipo:0,
                // link_integracao_gvdasa:null,
                // token_api_gvdasa:null,
                // link_integracao_rubeus:null,
                // token_api_rubeus:null,
                // origem:null,

            },
            user:{
                id:null,
                name:null,
                email:null,
                senha:null,
            },
            unidade_exportada_crm:[{
                id:null,
                codigo:null,
                descricao:null,
                nome:null,
                oridem:null
            }],
            local_oferta_crm:[{
                id:null,
                codigo:null,
                descricao:null,
                nome:null,
                oridem:null
            }],
            users:[],
            errors:{},
            success_add:null,
            message_add:null
        }
    },
    methods:{
        adicionarDocumentCustomField(){
            $('#SalvandoConfiguracoes').modal('show');
            let url='/api/v1/Access/Unity/Unitie/'+this.cliente.id+'/config/documentscustomfields';
                let formData = new FormData();

                formData.append('custom_field_id', (this.docs_custom_fields.custom_field_id>0 ? this.docs_custom_fields.custom_field_id:null))
                formData.append('unity_id', (this.docs_custom_fields.unity_id>0 ? this.docs_custom_fields.unity_id:null))
                formData.append('document_id', (this.docs_custom_fields.document_id>0 ? this.docs_custom_fields.document_id:null))
                formData.append('obrigatorio', (this.docs_custom_fields.obrigatorio == 1 ? 1:0))
                
                let configuracao = {
                    // method:'post',
                    // headers:{
                    //     // // 'Accept': 'application/json',
                    //     // // 'Authorization': this.$root.token(),
                    // }
                }
                
                axios.post(url,formData,configuracao)
                    .then(response => {
                        

                        if(response.data.success==false){
                            this.success_add = response.data.success
                            this.message_add = response.data.message
                            this.errors = {}
                            console.log('aqui',response.data.message)
                        }else{
                            this.success_add = response.data.success
                            this.message_add = response.data.message
                            this.docs_configurados_unidade=response.data.list_document_custom_field
                            this.docs_custom_fields.document_id = 0
                            this.docs_custom_fields.custom_field_id = 0
                            this.docs_custom_fields.obrigatorio = 0
                            this.errors = {}
                        }
                        $('#SalvandoConfiguracoes').modal('hide');

                    })
                    .catch(errors => {
                        console.log(errors.response)
                        this.errors = errors.response.data.errors
                        this.message_add=''
                        this.success_add = null;
                        $('#SalvandoConfiguracoes').modal('hide');
                    })
        },
        excluirDocumentCustomField(id){
            $('#ExcluindoConfiguracoes').modal('show');
            console.log('id',id)
            var config = {
            method: 'delete',
            url: '/api/v1/Access/Unity/Unitie/'+this.cliente.id+'/config/documentscustomfields/'+id,
            // headers: {
            //         'Accept': 'application/json',
            //         'Authorization': this.$root.token(),
            //     },
            };
            //executa requisição
            axios(config)
            .then(response => {
                console.log(response);
                if(response.data.success==false){
                    this.success_add = response.data.success
                    this.message_add = response.data.message
                    this.errors = {}
                    console.log('aqui',response.data.message)
                }else{
                    this.success_add = response.data.success
                    this.message_add = response.data.message
                    this.docs_configurados_unidade=response.data.list_document_custom_field
                    this.docs_custom_fields.document_id = 0
                    this.docs_custom_fields.custom_field_id = 0
                    this.docs_custom_fields.obrigatorio = 0
                }
                $('#ExcluindoConfiguracoes').modal('hide');
            })
            .catch(function (error) {
                console.log(error);
                $('#ExcluindoConfiguracoes').modal('hide');
            });

            $("#ExclusaoPessoas").modal('');
        },
        inserirAlterarDocumentCustomField(){
            if(this.docs_custom_fields.created_at){
                this.alterarDocumentCustomField()
            }else{
                this.adicionarDocumentCustomField()
            }
        },
        alterarConfigDoc(doc){
            this.docs_custom_fields = doc;
            console.log(this.docs_custom_fields,doc)
            this.errors = {}
        },
        alterarDocumentCustomField(){
            $('#SalvandoConfiguracoes').modal('show');
            let url='/api/v1/Access/Unity/Unitie/'+this.cliente.id+'/config/documentscustomfields/'+this.docs_custom_fields.id
                
                //console.log(this.cliente)                                  
                let formData = new FormData();
                formData.append('_method','patch');
                formData.append('custom_field_id', (this.docs_custom_fields.custom_field_id>0 ? this.docs_custom_fields.custom_field_id:null))
                formData.append('unity_id', (this.docs_custom_fields.unity_id>0 ? this.docs_custom_fields.unity_id:null))
                formData.append('document_id', (this.docs_custom_fields.document_id>0 ? this.docs_custom_fields.document_id:null))
                formData.append('obrigatorio', (this.docs_custom_fields.obrigatorio == 1 ? 1:0))
                formData.append('id', (this.docs_custom_fields.id>0 ? this.docs_custom_fields.id:null))
                
                
                let configuracao = {
                    // method:'put',
                    // headers:{
                    // //     'Accept': 'application/json',
                    // //     'Authorization': this.$root.token()
                    // },
                }
                
                axios.post(url, formData,configuracao)
                .then(response => {
                        

                        if(response.data.success==false){
                            this.success_add = response.data.success
                            this.message_add = response.data.message
                            this.errors = {}
                            console.log('aqui',response.data.message)
                        }else{
                            this.success_add = response.data.success
                            this.message_add = response.data.message
                            this.docs_configurados_unidade=response.data.list_document_custom_field
                            this.docs_custom_fields =  {
                                                            unity_id:this.cliente.id,
                                                            document_id:0,
                                                            custom_field_id:0,
                                                            id:null,
                                                            obrigatorio:0
                                                        }
                            this.errors = {}

                        }
                        $('#SalvandoConfiguracoes').modal('hide');

                    })
                    .catch(errors => {
                        console.log(errors.response)
                        this.errors = errors.response.data.errors
                        this.message_add=''
                        this.success_add = null;
                        $('#SalvandoConfiguracoes').modal('hide');
                    })
        },
        cancelarDocumentCustomField(){
            this.docs_custom_fields = {
                obrigatorio:0,
                unity_id:this.cliente.id,
                document_id:0,
                custom_field_id:0,
                id:null
            }
            this.errors = {}
            this.success_add=null
            this.message_add=''
        },
        cancelarRequisicao(){
            window.alert('teste');
            
        },
        sincronizarTodos(){
            this.sincronizarDocsErp();
            this.sincronizarCamposPersonalizadosCrm();
            
        },
        sincronizarCamposPersonalizadosCrm(){
           // $('#sincronizarListaDocs').modal('show');
            // $('#sincronizarListaDocs').modal('show');
            let url = window.location.origin + '/api/v1/Crm/ListaCamposPersonalizados/' + this.cliente.id
            console.log(url);

            let configuracao = {
                    // method:'put',
                    // headers:{
                    // //     'Accept': 'application/json',
                    // //     'Authorization': this.$root.token()
                    // },
                }
                
            axios.get(url,configuracao)
                .then(response => {
                    $('#sincronizarListaDocs').modal('hide');
                    console.log(response.data)
                    this.campos_lista = response.data.lista_campos_personalizados
                    // this.resetarDadosEventosInscricao()
                
                })
                .catch(errors => {
                    // $('#sincronizarListaDocs').modal('hide');
                    window.alert("Houve um erro");
                    console.log(errors)
                })
               
            
        },
        sincronizarDocsErp(){
            $('#sincronizarListaDocs').modal('show');
            let url = window.location.origin + '/api/v1/Erp/ListaTiposDocs/' + this.cliente.id
            console.log(url);

            let configuracao = {
                    // method:'put',
                    // headers:{
                    // //     'Accept': 'application/json',
                    // //     'Authorization': this.$root.token()
                    // },
                }
                
            axios.get(url,configuracao)
                .then(response => {
                    // $('#sincronizarListaDocs').modal('hide');
                    console.log(response.data)
                    this.docs_lista = response.data.lista_lista_docs
                    // this.resetarDadosEventosInscricao()
                
                })
                .catch(errors => {
                    // $('#sincronizarListaDocs').modal('hide');
                    window.alert("Houve um erro");
                    console.log(errors)
                })
            
        },
        dissmiss(id){
            this.$bvModal.hide(id);
        },
        novo(id){
            this.user = {}
            this.modoeditar = false
            this.modoexcluir = false
            this.modonovo = true
            this.texto_modal = "Novo"
            this.user.id=id
            // console.log(this.user)
        },
        editar(usuarioSelecionado){
            this.user = usuarioSelecionado;
            this.modoeditar = true
            this.modoexcluir = false
            this.modonovo = false
            this.texto_modal = "Editar"
            // console.log(this.user)
            // console.log(this.user)
        },
        excluir(usuarioSelecionado){
            this.user = usuarioSelecionado;
            this.modoeditar = false
            this.modoexcluir = true
            this.modonovo = false
            this.texto_modal = "Excluir"
            // console.log(this.user)
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.nameState = valid
            return valid
        },
        resetModal() {
            this.name = ''
            this.nameState = null
        },
        removerPorId: function (id) {
            var _vm = this; //Adicionando a instância à uma variável, para que possa ser acessada dentro do forEach`
            this.users.forEach(function(el, index) {
                if(el.id === id)
                    _vm.users.splice(index, 1);
            })
        },
        insereOuAtualiza(){
            if(!this.edit){
                this.inserirUnity();
                console.log('insere')
            }else{
                this.atualizaUnity();
                console.log('atualiza')
            }
        },
        inserirUnity(){
                
            // if(this.modonovo){
                $('#SalvandoConfiguracoes').modal('show');
                let url='/api/v1/'+this.urlSistema;
                let formData = new FormData();
                formData.append('tipocadastro', this.tipoCadastro)
                formData.append('company_id', (this.cliente.company_id?this.cliente.company_id:''))
                formData.append('codigounidadepk', (this.cliente.codigounidadepk?this.cliente.codigounidadepk:''))
                formData.append('codigounidade', (this.cliente.codigounidade?this.cliente.codigounidade:''))
                formData.append('codigounidadeexterno', (this.cliente.codigounidadeexterno?this.cliente.codigounidadeexterno:''))
                formData.append('nomecompleto', (this.cliente.nomecompleto?this.cliente.nomecompleto:''))
                formData.append('cnpj', (this.cliente.cnpj?this.cliente.cnpj:''))
                formData.append('email', (this.cliente.email?this.cliente.email:''))
                formData.append('telefone', (this.cliente.telefone?this.cliente.telefone:''))
                formData.append('celular', (this.cliente.celular?this.cliente.celular:''))
                formData.append('logradouro', (this.cliente.logradouro?this.cliente.logradouro:''))
                formData.append('numero', (this.cliente.numero?this.cliente.numero:''))
                formData.append('complemento', (this.cliente.complemento?this.cliente.complemento:''))
                formData.append('bairro', (this.cliente.bairro?this.cliente.bairro:''))
                formData.append('cidade', (this.cliente.cidade?this.cliente.cidade:''))
                formData.append('estado', (this.cliente.estado && this.cliente.estado != '0' ?this.cliente.estado:''))
                formData.append('pais', (this.cliente.pais && this.cliente.pais != '0' ?this.cliente.pais:''))
                formData.append('cep', (this.cliente.cep?this.cliente.cep:''))
                formData.append('urlapirubeus', (this.cliente.urlapirubeus?this.cliente.urlapirubeus:''))
                formData.append('tokenapirubeus', (this.cliente.tokenapirubeus?this.cliente.tokenapirubeus:''))
                formData.append('urlapigvdasa', (this.cliente.urlapigvdasa?this.cliente.urlapigvdasa:''))
                formData.append('tokenapigvdasa', (this.cliente.tokenapigvdasa?this.cliente.tokenapigvdasa:''))

                formData.append('oferta_processo_seletivo', (this.cliente.oferta_processo_seletivo?this.cliente.oferta_processo_seletivo:0))
                formData.append('oferta_Inscricao_web', (this.cliente.oferta_Inscricao_web?this.cliente.oferta_Inscricao_web:0))
                formData.append('inscricao_web_aluno', (this.cliente.inscricao_web_aluno?this.cliente.inscricao_web_aluno:0))
                formData.append('importar_parcela', (this.cliente.importar_parcela?this.cliente.importar_parcela:0))
                formData.append('download_contrato', (this.cliente.download_contrato?this.cliente.download_contrato:0))
                formData.append('ativo',(this.cliente.ativo=='Ativo'?'1':'0'))
                formData.append('origem', (this.cliente.origem?this.cliente.origem:''))
                formData.append('mascarainscricao', (this.cliente.mascaraofertainscricao?this.cliente.mascaraofertainscricao:''))
                formData.append('mascarapse', (this.cliente.mascaraofertapse?this.cliente.mascaraofertapse:''))
                formData.append('codigotipocontatotelefone', (this.cliente.codigotipocontatotelefone?this.cliente.codigotipocontatotelefone:null))
                formData.append('codigotipocontatoemail', (this.cliente.codigotipocontatoemail?this.cliente.codigotipocontatoemail:null))
                if(this.tipoCadastro==2){
                    formData.append('nomereduzido', (this.cliente.nomereduzido?this.cliente.nomereduzido:''))
                }else{
                    formData.append('nomereduzido', '')
                }
                formData.append('email',(this.cliente.email?this.cliente.email:''))

                let configuracao = {
                    // method:'post',
                    // headers:{
                    //     // // 'Accept': 'application/json',
                    //     // // 'Authorization': this.$root.token(),
                    // }
                }
                
                axios.post(url,formData,configuracao)
                    .then(response => {
                        this.transacaoStatus = response.status
                        this.cliente = response.data.usuarioNovo;
                        this.cliente.ativo = (this.cliente.ativo=='Ativo'?'Ativo':'Inativo')
                        this.errors={};
                        this.cliente.CadastroTipo=2
                        
                        $('#SalvandoConfiguracoes').modal('hide');
                        $('#MensagemSistema').modal('show');
                    })
                    .catch(errors => {
                        this.errors = errors.response.data.errors
                        console.log(errors.response)
                        $('#SalvandoConfiguracoes').modal('hide');
                    })
            // }
        },
        atualizaUnity(){
            // if(this.modonovo){
                let url='/api/v1/'+this.urlSistema+'/'+this.cliente.id;
                $('#SalvandoConfiguracoes').modal('show');
                //console.log(this.cliente)                                  
                let formData = new FormData();
                formData.append('_method','patch');
                formData.append('tipocadastro', this.tipoCadastro)
                formData.append('company_id', (this.cliente.company_id?this.cliente.company_id:''))
                formData.append('codigounidade', (this.cliente.codigounidade?this.cliente.codigounidade:''))
                formData.append('codigounidadepk', (this.cliente.codigounidadepk?this.cliente.codigounidadepk:''))
                formData.append('codigounidadeexterno', (this.cliente.codigounidadeexterno?this.cliente.codigounidadeexterno:''))
                formData.append('nomecompleto', (this.cliente.nomecompleto?this.cliente.nomecompleto:''))
                formData.append('cnpj', (this.cliente.cnpj?this.cliente.cnpj:''))
                formData.append('email', (this.cliente.email?this.cliente.email:''))
                formData.append('telefone', (this.cliente.telefone?this.cliente.telefone:''))
                formData.append('celular', (this.cliente.celular?this.cliente.celular:''))
                formData.append('logradouro', (this.cliente.logradouro?this.cliente.logradouro:''))
                formData.append('numero', (this.cliente.numero?this.cliente.numero:''))
                formData.append('complemento', (this.cliente.complemento?this.cliente.complemento:''))
                formData.append('bairro', (this.cliente.bairro?this.cliente.bairro:''))
                formData.append('cidade', (this.cliente.cidade?this.cliente.cidade:''))
                formData.append('estado', (this.cliente.estado && this.cliente.estado != '0' ?this.cliente.estado:''))
                formData.append('pais', (this.cliente.pais && this.cliente.pais != '0' ?this.cliente.pais:''))
                formData.append('cep', (this.cliente.cep?this.cliente.cep:''))
                formData.append('urlapirubeus', (this.cliente.urlapirubeus?this.cliente.urlapirubeus:''))
                formData.append('tokenapirubeus', (this.cliente.tokenapirubeus?this.cliente.tokenapirubeus:''))
                formData.append('urlapigvdasa', (this.cliente.urlapigvdasa?this.cliente.urlapigvdasa:''))
                formData.append('tokenapigvdasa', (this.cliente.tokenapigvdasa?this.cliente.tokenapigvdasa:''))

                formData.append('oferta_processo_seletivo', (this.cliente.oferta_processo_seletivo?this.cliente.oferta_processo_seletivo:0))
                formData.append('oferta_Inscricao_web', (this.cliente.oferta_Inscricao_web?this.cliente.oferta_Inscricao_web:0))
                formData.append('inscricao_web_aluno', (this.cliente.inscricao_web_aluno?this.cliente.inscricao_web_aluno:0))
                formData.append('importar_parcela', (this.cliente.importar_parcela?this.cliente.importar_parcela:0))
                formData.append('download_contrato', (this.cliente.download_contrato?this.cliente.download_contrato:0))
                formData.append('ativo',(this.cliente.ativo=='Ativo'?'1':'0'))
                formData.append('origem', (this.cliente.origem?this.cliente.origem:''))
                formData.append('mascarainscricao', (this.cliente.mascaraofertainscricao?this.cliente.mascaraofertainscricao:''))
                formData.append('mascarapse', (this.cliente.mascaraofertapse?this.cliente.mascaraofertapse:''))
                formData.append('codigotipocontatotelefone', (this.cliente.codigotipocontatotelefone?this.cliente.codigotipocontatotelefone:null))
                formData.append('codigotipocontatoemail', (this.cliente.codigotipocontatoemail?this.cliente.codigotipocontatoemail:null))
                if(this.tipoCadastro==2){
                    formData.append('nomereduzido', (this.cliente.nomereduzido?this.cliente.nomereduzido:''))
                }else{
                    formData.append('nomereduzido', '')
                }
                formData.append('email',(this.cliente.email?this.cliente.email:''))
                
                let configuracao = {
                    // method:'put',
                    // headers:{
                    // //     'Accept': 'application/json',
                    // //     'Authorization': this.$root.token()
                    // },
                }
                
                axios.post(url, formData,configuracao)
                    .then(response => {
                        // console.log(response)
                        this.transacaoStatus = response.status
                        this.cliente = response.data.usuarioNovo;
                        this.cliente.ativo = response.data.usuarioNovo.ativo
                        this.errors={};
                        this.cliente.CadastroTipo=2
                        this.tipoCadastro=2;
                        $('#SalvandoConfiguracoes').modal('hide');
                        $('#MensagemSistema').modal('show');
                    })
                    .catch(errors => {
                        this.errors = errors.response.data.errors
                        console.log(errors.response.data)
                        $('#SalvandoConfiguracoes').modal('hide');
                    })
            // }
        },
        excluirUsuarioCliente(id){
            
            if(this.modoexcluir){
                var config = {
                method: 'delete',
                url: '/api/v1/'+this.urlSistema+'/'+id,
                // headers: {
                //         // 'Accept': 'application/json',
                //         // 'Authorization': this.$root.token(),
                //     },
                };
                //executa requisição
                axios(config)
                .then(response => {
                    debugger;
                    // console.log(response.data.usuario)
                    this.$bvModal.hide('modal-prevent-closing')
                    this.$nextTick(() => {
                        window.alert("Usuário de Cliente excluído com sucesso");
                    })
                    this.removerPorId(id)
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
        },
        exportarLocaisOfertaCrm(unidade){
            let url='/api/v1/Companies/Unity/CadastroLocaisOferta/'+this.cliente.id;
                
                //console.log(this.cliente)                                  
                
                
            let configuracao = {
                // method:'put',
                // headers:{
                // //     'Accept': 'application/json',
                // //     'Authorization': this.$root.token()
                // },
            }
            
            axios.post(url,configuracao)
                .then(response => {
                    this.local_oferta_crm = response.data
                    console.log(this.local_oferta_crm)
                    // this.empresa_exportada_crm.id = response.data.resposta_crm.id
                    $('#ExportacaoCrmMsgLocalOferta').modal('show');
                })
                .catch(errors => {
                    this.errors = errors.response.data.errors
                    console.log(errors.response.data)
                })
        },
        exportarUnidadeCrm(unidade){
            let url='/api/v1/Crm/cadastroUnidade/'+this.cliente.id;
                
                //console.log(this.cliente)                                  
                
                
            let configuracao = {
                // method:'put',
                // headers:{
                // //     'Accept': 'application/json',
                // //     'Authorization': this.$root.token()
                // },
            }
            
            axios.post(url,configuracao)
                .then(response => {
                    console.log(response)
                    this.unidade_exportada_crm = response.data.dadosUnidade
                    this.unidade_exportada_crm.id = response.data.resposta_crm.id
                    $('#ExportacaoCrmMsg').modal('show');
                })
                .catch(errors => {
                    this.errors = errors.response.data.errors
                    console.log(errors.response.data)
                })
        }
    },
    mounted(){
        // this.$root.atualizaCookie('token');
    },
    created(){
        if(this.pessoa){
            this.cliente = this.pessoa;
            console.log('/api/v1/'+this.urlSistema+'/'+this.cliente.id)
            this.codigoempresa = this.pessoa.company_id;
            console.log(this.cliente)
            this.users = this.usuarios;
            this.doc=this.pessoa.cnpj;
            this.docs_custom_fields.unity_id = this.cliente.id
            this.docs_lista = this.listagemDocumentos;
            this.docs_configurados_unidade = this.documentosConfigurados;
            this.campos_lista = this.listagemCamposPersonalizados
            if(this.pessoa.cnpj.length==14){
                this.cliente.CadastroTipo=1
                this.cliente.cnpj = this.pessoa.cnpj;
                this.tipoCadastro=1
            }else if(this.pessoa.cnpj.length==18){
                this.cliente.CadastroTipo=2
                this.cliente.cnpj = this.pessoa.cnpj;
                this.tipoCadastro=2
            }else{
                this.cliente.CadastroTipo=2
                this.tipoCadastro=2
            }
        }else{
            this.cliente.CadastroTipo=0;
            this.tipoCadastro=0;
            this.cliente.estado="0";
            this.codigoempresa=0;
        }
    },
    watch: {
        message_add(new_message_add,old_message_add){
            this.message_add = new_message_add
        },
    // whenever question changes, this function will run
        codigoempresa(newempresa,oldempresa){
            if(newempresa!=oldempresa){
                this.cliente.company_id = newempresa
            }
        },
        texto_modal(newTexto, oldTtexto) {
            if (newTexto!=oldTtexto) {
                this.texto_modal=newTexto;
            }
        }
        ,tipoCadastro(newTipoCadastro, oldTipoCadastro) {
            if (newTipoCadastro!=oldTipoCadastro) {
                this.cliente.CadastroTipo=newTipoCadastro;
                this.tipoCadastro=newTipoCadastro;
                this.errors={};
            }
        },
        'cliente.ativo'(newcliente,oldcliente){
            console.log(newcliente);
        },
        doc(newdoc, olddoc) {
            if (newdoc!=olddoc) {
                this.cliente.cnpj=newdoc;
                this.doc=newdoc;
            }
        },
    }
}
</script>

<style scoped>
    form{
        text-align: left!important;
    }

    .div-crud-sistema{
            background: white;
            padding: 0px;
            padding-top: 10px;
            border-radius: 0px 0px 10px 10px;
            
    }

    .nav-item{
        width: 33.333%!important;
        text-align: center;
        border-radius: 10px 10px 0px 0px!important;
    }

    .tab-pane{
        overflow: auto;
        width: 100%;
    }
    nav-tabs .nav-item .nav-link, 
    .nav-tabs .nav-item .nav-link{
        color:black!important;
        background-color:#fff!important;
        border-radius: 10px 10px 0px 0px!important;
        border: 1px solid #1c4d5a;
        
        height: 100%;
        padding-left: 10px;
    }

    .nav-tabs .nav-item .nav-link.active, 
    .nav-tabs .nav-item.show .nav-link{
        color:white!important;
        background-color:#1c4d5a!important;
        border-radius: 10px 10px 0px 0px!important;
        border: 1px solid #1c4d5a;
        
        height: 100%;
        padding-left: 10px;
    }

    .tab-content > .active {
        background-color:#fff!important;
        border: 1px solid #1c4d5a;
        padding: 20px;
    }

    #td-html > p {
        margin:0px!important;
    }

    .nav-tabs {
        border-bottom: 1px solid #1c4d5a;
    }

    .table thead th {
        vertical-align: bottom;
        border: 0px solid #1c4d5a;
    }

</style>
