<template>
    <div >
        <div class="div-crud-sistema">
            <div>
                <table class="table table-hover table-sm" style="white-space: normal;border-top: 0px;">
                    <thead>
                        <tr style="background-color: #1c4d5a;color:white;">
                            <th colspan="6" style="text-align: center;max-width: 120px;width: 120px;">DETALHES DA OFERTA</th>
                        </tr>
                        <tr style="background-color: #1c4d5a;color:white;">
                            <th style="text-align: center;max-width: 120px;width: 120px;">ANO OFERTA</th>
                            <th style="text-align: center;max-width: 120px;width: 120px;">CODIGO OFERTA CRM</th>
                            <th style="text-align: center;max-width: 120px;width: 120px;">CODIGO OFERTA ERP</th>
                            <th style="text-align: center;max-width: 120px;width: 120px;">OFERTA</th>
                            <th style="text-align: center;max-width: 120px;width: 120px;">DATA INICIO INSCRIÇÃO</th>
                            <th style="text-align: center;max-width: 120px;width: 120px;">DATA TÉRMINO INSCRIÇÃO</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th style="text-align: center;max-width: 120px;width: 120px;">{{ oferta.anoinicio }}</th>
                            <th style="text-align: center;max-width: 120px;width: 120px;">{{ (oferta.codigooferta ? oferta.codigooferta : oferta.codigoofertapse) }}</th>
                            <th style="text-align: center;max-width: 120px;width: 120px;">{{ oferta.codigoturma }}</th>
                            <th style="text-align: center;max-width: 120px;width: 120px;">{{ oferta.descricaoturma }}</th>
                            <th style="text-align: center;max-width: 120px;width: 120px;">{{ formatDate(oferta.datainicioinscricao,2) }}</th>
                            <th style="text-align: center;max-width: 120px;width: 120px;">{{ formatDate(oferta.dataterminoinscricao,2) }}</th>
                        </tr>
                    </tbody>
                </table>
            </div>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Inscrição Confirmada</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Contrato Aceito</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Matriculado</a>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <table class="table table-hover table-sm" style="white-space: normal;border-top: 0px;">
                        <thead>
                            <tr style="background-color: #1c4d5a;color:white;">
                                <th style="text-align: center;width: 100px;">
                                    Entrar
                                </th>
                                <th style="text-align: center;width: auto;">
                                    Item
                                </th>
                                <th style="text-align: center;max-width: 120px;width: 120px;">
                                    ID
                                </th>
                                <th style="text-align: center;max-width: 250px;width: 250px;">
                                    Nome
                                </th>
                                <th style="text-align: center;">
                                    Requisição ERP
                                </th>
                                <th style="text-align: center;">
                                    Contato ID CRM
                                </th>
                                <!-- <th style="text-align: center;">
                                    Etapas
                                </th> -->
                                <th style="text-align: center;width: 45px;">
                                    Sinc.
                                </th>
                                <th style="text-align: center;width: 100px;">
                                    Data
                                </th>
                                <th style="text-align: center;width: 100px;">
                                    Status
                                </th>
                                <th style="text-align: center;width: 100px;">
                                    Status
                                </th>
                                <th style="text-align: center;width: 100px;">
                                    Origem
                                </th>
                            </tr>
                        </thead>
                        <tbody v-if="existeDados('c')">
                            <template v-for="aluno_captado,item in captados_webhook" >
                                <tr v-if="aluno_captado.tipoeventoaviso=='c'" :key="item">
                                    <td style="text-align: center;" class="align-middle">
                                        <a :href="'/Access/Unity/Unitie/'+oferta.unity_id+'/captacao/wh/'+aluno_captado.id" class="btn btn-primary btn-sm"><b-icon icon="eye-fill" font-scale="1.3"></b-icon></a>
                                    </td>
                                    <td class="align-middle" style="text-align: center;font-weight: bold;">
                                        {{ item+1 }}
                                    </td>
                                    <td class="align-middle" style="text-align: center;font-weight: bold;">
                                        {{ aluno_captado.id }}
                                    </td>
                                    <td class="align-middle" style="text-align: left;font-weight: bold;text-overflow: ellipsis;max-width:120px;overflow: hidden;white-space: nowrap;">
                                        {{ aluno_captado.nomeinscrito }}
                                    </td>
                                    <td class="align-middle" style="text-align: center;font-weight: bold;">
                                        {{aluno_captado.requisicao_id}}
                                    </td>
                                    <td class="align-middle" style="text-align: center;font-weight: bold;">
                                        {{ (aluno_captado.request_body ? JSON.parse(aluno_captado.request_body).contato.id : '') }}
                                    </td>
                                    <!-- <td id="td-html" class="align-middle" v-html="converteJsonObjeto(aluno_captado.request_body).descricao">
                                        
                                    </td> -->
                                    <td style="white-space: normal;text-align: center;" class="align-middle">
                                        <b-icon v-if="aluno_captado.processado == 1" icon="check-lg" font-scale="1.3" style="color:green"></b-icon>
                                        <b-icon v-else icon="x-lg" font-scale="1.3" style="color:red"></b-icon>
                                    </td>
                                    <td style="text-align: center;" class="align-middle">
                                        {{ formatDate(aluno_captado.created_at,2) }}
                                    </td>
                                    <td class="align-middle" style="text-align: center;font-weight: bold;">
                                        <div v-if="aluno_captado.status=='success' && aluno_captado.requisicao_id>0" class="alert alert-success text-center" role="alert" style="margin-bottom: 0px;padding: 5px 10px 5px 10px;">
                                            <b>{{ aluno_captado.status }}</b>
                                        </div>
                                        <div v-else-if="aluno_captado.requisicao_id>0" class="alert alert-danger text-center" role="alert" style="margin-bottom: 0px;padding: 5px 10px 5px 10px;">
                                            <b>{{ aluno_captado.status }}</b>
                                        </div>
                                        <div v-else-if="aluno_captado.processado==1 && aluno_captado.status!='success'" class="alert alert-danger text-center" role="alert" style="margin-bottom: 0px;padding: 5px 10px 5px 10px;">
                                            <b>{{ aluno_captado.status }}</b>
                                        </div>
                                    </td>
                                    <td class="align-middle" style="text-align: center;font-weight: bold; width: 200px;">
                                        <div v-if="aluno_captado.processado == 1 && aluno_captado.status_inscricao=='success' && aluno_captado.requisicao_id>0" class="alert alert-success text-center" role="alert" style="margin-bottom: 0px;padding: 5px 10px 5px 10px;">
                                            <b>{{ aluno_captado.status_inscricao }}</b>
                                        </div>
                                        <div v-else-if="aluno_captado.processado == 1 && aluno_captado.status_inscricao=='error' && aluno_captado.requisicao_id>0" class="alert alert-danger text-center" role="alert" style="margin-bottom: 0px;padding: 5px 10px 5px 10px;">
                                            <b>{{ aluno_captado.status_inscricao }}</b>
                                        </div>
                                        <div v-else-if="aluno_captado.processado==1 && aluno_captado.status_inscricao=='pending'" class="alert alert-info text-center" role="alert" style="margin-bottom: 0px;padding: 5px 10px 5px 10px;">
                                            <b>{{ aluno_captado.status_inscricao }}</b>
                                        </div>
                                    </td>
                                    <td style="white-space: normal;text-align: center;" class="align-middle">
                                        <b>{{ aluno_captado.origem_inclusao }}</b>
                                    </td>
                                </tr>

                            </template>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="11" style="text-align: center;font-weight: bold;">
                                    Não existem dados para exibir
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                    <table class="table table-hover table-sm" style="white-space: normal;border-top: 0px;">
                        <thead>
                            <tr style="background-color: #1c4d5a;color:white;">
                                <th style="text-align: center;width: 100px;">
                                    Entrar
                                </th>
                                <th style="text-align: center;width: auto;">
                                    Item
                                </th>
                                <th style="text-align: center;max-width: 120px;width: 120px;">
                                    ID
                                </th>
                                <th style="text-align: center;max-width: 250px;width: 250px;">
                                    Nome
                                </th>
                                <th style="text-align: center;">
                                    Requisição ERP
                                </th>
                                <th style="text-align: center;">
                                    Contato ID CRM
                                </th>
                                <!-- <th style="text-align: center;">
                                    Etapas
                                </th> -->
                                <th style="text-align: center;width: 45px;">
                                    Sinc.
                                </th>
                                <th style="text-align: center;width: 100px;">
                                    Data
                                </th>
                                <th style="text-align: center;width: 100px;">
                                    Status
                                </th>
                                <th style="text-align: center;width: 100px;">
                                    Status
                                </th>                                
                                <th style="text-align: center;width: 100px;">
                                    Origem
                                </th>
                            </tr>
                        </thead>
                        <tbody v-if="existeDados('a')">
                            <template v-for="aluno_captado,item in captados_webhook" >
                                <tr v-if="aluno_captado.tipoeventoaviso=='c'" :key="item">
                                    <td style="text-align: center;" class="align-middle">
                                        <a :href="'/Access/Unity/Unitie/'+oferta.unity_id+'/captacao/wh/'+aluno_captado.id" class="btn btn-primary btn-sm"><b-icon icon="eye-fill" font-scale="1.3"></b-icon></a>
                                    </td>
                                    <td class="align-middle" style="text-align: center;font-weight: bold;">
                                        {{ item+1 }}
                                    </td>
                                    <td class="align-middle" style="text-align: center;font-weight: bold;">
                                        {{ aluno_captado.id }}
                                    </td>
                                    <td class="align-middle" style="text-align: left;font-weight: bold;text-overflow: ellipsis;max-width:120px;overflow: hidden;white-space: nowrap;">
                                        {{ aluno_captado.nomeinscrito }}
                                    </td>
                                    <td class="align-middle" style="text-align: center;font-weight: bold;">
                                        {{aluno_captado.requisicao_id}}
                                    </td>
                                    <td class="align-middle" style="text-align: center;font-weight: bold;">
                                        {{ (aluno_captado.request_body ? JSON.parse(aluno_captado.request_body).contato.id : '') }}
                                    </td>
                                    <!-- <td id="td-html" class="align-middle" v-html="converteJsonObjeto(aluno_captado.request_body).descricao">
                                        
                                    </td> -->
                                    <td style="white-space: normal;text-align: center;" class="align-middle">
                                        <b-icon v-if="aluno_captado.processado == 1" icon="check-lg" font-scale="1.3" style="color:green"></b-icon>
                                        <b-icon v-else icon="x-lg" font-scale="1.3" style="color:red"></b-icon>
                                    </td>
                                    <td style="text-align: center;" class="align-middle">
                                        {{ formatDate(aluno_captado.created_at,2) }}
                                    </td>
                                    <td class="align-middle" style="text-align: center;font-weight: bold;">
                                        <div v-if="aluno_captado.status=='success' && aluno_captado.requisicao_id>0" class="alert alert-success text-center" role="alert" style="margin-bottom: 0px;padding: 5px 10px 5px 10px;">
                                            <b>{{ aluno_captado.status }}</b>
                                        </div>
                                        <div v-else-if="aluno_captado.requisicao_id>0" class="alert alert-danger text-center" role="alert" style="margin-bottom: 0px;padding: 5px 10px 5px 10px;">
                                            <b>{{ aluno_captado.status }}</b>
                                        </div>
                                        <div v-else-if="aluno_captado.processado==1 && aluno_captado.status!='success'" class="alert alert-danger text-center" role="alert" style="margin-bottom: 0px;padding: 5px 10px 5px 10px;">
                                            <b>{{ aluno_captado.status }}</b>
                                        </div>
                                    </td>
                                    <td class="align-middle" style="text-align: center;font-weight: bold; width: 200px;">
                                        <div v-if="aluno_captado.processado == 1 && aluno_captado.status_inscricao=='success' && aluno_captado.requisicao_id>0" class="alert alert-success text-center" role="alert" style="margin-bottom: 0px;padding: 5px 10px 5px 10px;">
                                            <b>{{ aluno_captado.status_inscricao }}</b>
                                        </div>
                                        <div v-else-if="aluno_captado.processado == 1 && aluno_captado.status_inscricao=='error' && aluno_captado.requisicao_id>0" class="alert alert-danger text-center" role="alert" style="margin-bottom: 0px;padding: 5px 10px 5px 10px;">
                                            <b>{{ aluno_captado.status_inscricao }}</b>
                                        </div>
                                        <div v-else-if="aluno_captado.processado==1 && aluno_captado.status_inscricao=='pending'" class="alert alert-info text-center" role="alert" style="margin-bottom: 0px;padding: 5px 10px 5px 10px;">
                                            <b>{{ aluno_captado.status_inscricao }}</b>
                                        </div>
                                    </td>
                                    <td style="white-space: normal;text-align: center;" class="align-middle">
                                        <b>{{ aluno_captado.origem_inclusao }}</b>
                                    </td>
                                </tr>

                            </template>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="11" style="text-align: center;font-weight: bold;">
                                    Não existem dados para exibir
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                </div>
                <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                    <table class="table table-hover table-sm" style="white-space: normal;border-top: 0px;">
                        <thead>
                            <tr style="background-color: #1c4d5a;color:white;">
                                <th style="text-align: center;width: 100px;">
                                    Entrar
                                </th>
                                <th style="text-align: center;width: auto;">
                                    Item
                                </th>
                                <th style="text-align: center;max-width: 120px;width: 120px;">
                                    ID
                                </th>
                                <th style="text-align: center;max-width: 250px;width: 250px;">
                                    Nome
                                </th>
                                <th style="text-align: center;">
                                    Requisição ERP
                                </th>
                                <th style="text-align: center;">
                                    Contato ID CRM
                                </th>
                                <!-- <th style="text-align: center;">
                                    Etapas
                                </th> -->
                                <th style="text-align: center;width: 45px;">
                                    Sinc.
                                </th>
                                <th style="text-align: center;width: 100px;">
                                    Data
                                </th>
                                <th style="text-align: center;width: 100px;">
                                    Status
                                </th>
                                <th style="text-align: center;width: 100px;">
                                    Status
                                </th>
                                <th style="text-align: center;width: 100px;">
                                    Origem
                                </th>
                                <th style="text-align: center;width: 100px;">
                                    Origem
                                </th>
                            </tr>
                        </thead>
                        <tbody v-if="existeDados('m')">
                            <template v-for="aluno_captado,item in captados_webhook" >
                                <tr v-if="aluno_captado.tipoeventoaviso=='c'" :key="item">
                                    <td style="text-align: center;" class="align-middle">
                                        <a :href="'/Access/Unity/Unitie/'+oferta.unity_id+'/captacao/wh/'+aluno_captado.id" class="btn btn-primary btn-sm"><b-icon icon="eye-fill" font-scale="1.3"></b-icon></a>
                                    </td>
                                    <td class="align-middle" style="text-align: center;font-weight: bold;">
                                        {{ item+1 }}
                                    </td>
                                    <td class="align-middle" style="text-align: center;font-weight: bold;">
                                        {{ aluno_captado.id }}
                                    </td>
                                    <td class="align-middle" style="text-align: left;font-weight: bold;text-overflow: ellipsis;max-width:120px;overflow: hidden;white-space: nowrap;">
                                        {{ aluno_captado.nomeinscrito }}
                                    </td>
                                    <td class="align-middle" style="text-align: center;font-weight: bold;">
                                        {{aluno_captado.requisicao_id}}
                                    </td>
                                    <td class="align-middle" style="text-align: center;font-weight: bold;">
                                        {{ (aluno_captado.request_body ? JSON.parse(aluno_captado.request_body).contato.id : '') }}
                                    </td>
                                    <!-- <td id="td-html" class="align-middle" v-html="converteJsonObjeto(aluno_captado.request_body).descricao">
                                        
                                    </td> -->
                                    <td style="white-space: normal;text-align: center;" class="align-middle">
                                        <b-icon v-if="aluno_captado.processado == 1" icon="check-lg" font-scale="1.3" style="color:green"></b-icon>
                                        <b-icon v-else icon="x-lg" font-scale="1.3" style="color:red"></b-icon>
                                    </td>
                                    <td style="text-align: center;" class="align-middle">
                                        {{ formatDate(aluno_captado.created_at,2) }}
                                    </td>
                                    <td class="align-middle" style="text-align: center;font-weight: bold;">
                                        <div v-if="aluno_captado.status=='success' && aluno_captado.requisicao_id>0" class="alert alert-success text-center" role="alert" style="margin-bottom: 0px;padding: 5px 10px 5px 10px;">
                                            <b>{{ aluno_captado.status }}</b>
                                        </div>
                                        <div v-else-if="aluno_captado.requisicao_id>0" class="alert alert-danger text-center" role="alert" style="margin-bottom: 0px;padding: 5px 10px 5px 10px;">
                                            <b>{{ aluno_captado.status }}</b>
                                        </div>
                                        <div v-else-if="aluno_captado.processado==1 && aluno_captado.status!='success'" class="alert alert-danger text-center" role="alert" style="margin-bottom: 0px;padding: 5px 10px 5px 10px;">
                                            <b>{{ aluno_captado.status }}</b>
                                        </div>
                                    </td>
                                    <td class="align-middle" style="text-align: center;font-weight: bold; width: 200px;">
                                        <div v-if="aluno_captado.processado == 1 && aluno_captado.status_inscricao=='success' && aluno_captado.requisicao_id>0" class="alert alert-success text-center" role="alert" style="margin-bottom: 0px;padding: 5px 10px 5px 10px;">
                                            <b>{{ aluno_captado.status_inscricao }}</b>
                                        </div>
                                        <div v-else-if="aluno_captado.processado == 1 && aluno_captado.status_inscricao=='error' && aluno_captado.requisicao_id>0" class="alert alert-danger text-center" role="alert" style="margin-bottom: 0px;padding: 5px 10px 5px 10px;">
                                            <b>{{ aluno_captado.status_inscricao }}</b>
                                        </div>
                                        <div v-else-if="aluno_captado.processado==1 && aluno_captado.status_inscricao=='pending'" class="alert alert-info text-center" role="alert" style="margin-bottom: 0px;padding: 5px 10px 5px 10px;">
                                            <b>{{ aluno_captado.status_inscricao }}</b>
                                        </div>
                                    </td>                                    
                                    <td style="white-space: normal;text-align: center;" class="align-middle">
                                        <b>{{ aluno_captado.origem_inclusao }}</b>
                                    </td>
                                </tr>

                            </template>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="11" style="text-align: center;font-weight: bold;">
                                    Não existem dados para exibir
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:['captacao','oferta'],
    data(){
        return{
            captados_webhook:[]
        }
    },
    methods:{
        existeDados(tipoeventoaviso){
            for(let i = 0;i<this.captados_webhook.length;i++){
                if(this.captados_webhook[i].tipoeventoaviso==tipoeventoaviso){
                    return true
                }
            }
            return false
        },
        converteJsonObjeto(dados){
            
            //var x = JSON.stringify(dados.substring(1,dados.length-1),null,'\t');
            var a = JSON.parse(dados)
            console.log('a',a)
            return a
        },
        formatDate(date,idioma) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            if(idioma==1){
                let dt = [year, month, day].join('-');
                if(dt=='1969-12-31'){
                    return null
                }else{
                    return dt;
                }
            }else if(idioma==2){
                let dt = [day, month, year].join('/');
                if(dt=='31/12/1969'){
                    return null
                }else{
                    return dt;
                }
            }else{
                let dt = [year, month, day].join('-');
                if(dt=='1969-12-31'){
                    return null
                }else{
                    return dt;
                }
            }
        },
    },
    created(){
        console.log(this.captacao.data);
        this.captados_webhook = this.captacao;
    }
}
</script>

<style scoped>
    .div-crud-sistema{
            background: white;
            padding: 0px;
            padding-top: 10px;
            border-radius: 0px 0px 10px 10px;
            
    }

    .nav-item{
        width: 33.333%!important;
        text-align: center;
        border-radius: 10px 10px 10px 10px!important;
    }

    .tab-pane{
        overflow: auto;
        width: 100%;
    }
    .div-crud-sistema > .col-2 > .nav-pills > .nav-link,
    .div-crud-sistema > .nav > .nav-item > .nav-link{
        color:black!important;
        border-radius: 10px 10px 0px 0px!important;
        border: 1px solid #1c4d5a;
        
        height: 100%;
        padding-left: 10px;
    }

    .div-crud-sistema > .row > .col-2 > .nav-pills > .active,
    .div-crud-sistema > .nav > .nav-item > .active{
        color:white!important;
        background-color:#1c4d5a!important;
        border-radius: 10px 10px 0px 0px!important;
        border: 1px solid #1c4d5a;
        
        height: 100%;
        padding-left: 10px;
    }

    .tab-content > .active {
        background-color:#fff!important;
        border: 1px solid #1c4d5a;
    }

    #td-html > p {
        margin:0px!important;
    }

    .nav-tabs {
        border-bottom: 5px solid #1c4d5a;
    }

    .table thead th {
        vertical-align: bottom;
        border: 0px solid #1c4d5a;
    }

    p{
        margin-bottom:0px;
    }

</style>
