<template>
  <div class="migalhas" v-if="perfil_usuario!=''" id="migalhas">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb" style="padding: 0.35rem 1rem;">
            <li v-if="perfil_usuario==1" class="breadcrumb-item active" aria-current="page"><b>{{ titulo }}</b></li>
            <li v-else-if="perfil_usuario==2" class="breadcrumb-item active" aria-current="page"><b>{{ titulo }}</b></li>
            <li v-else-if="perfil_usuario==3" class="breadcrumb-item active" aria-current="page"><b>{{ titulo }}</b></li>
            <li v-else-if="perfil_usuario==100" class="breadcrumb-item active" aria-current="page"><b>{{ titulo }}</b></li>
            <li v-else class="breadcrumb-item active" aria-current="page"><b> OPS</b></li>
            <li v-if="perfil_usuario==1 || perfil_usuario==2 || perfil_usuario==3" class="breadcrumb-item" aria-current="page"><a href="#" @click.prevent="selecionarUnidade();">Selecionar Unidade</a></li>
        </ol>
    </nav>
    <modal-component titulo="VISUALIZADORUNIDADES" label-btn-fechar="Fechar" id="visualizadorUnidades" url-redirect="" :sem-padding="true">
        <template v-slot:conteudo>
            <div class="card">
                <div class="card-header" style="color:white;font-weight: bold;background-image: linear-gradient( to right, #1c4d5a, #1a7580,#1c4d5a );">
                    <b style="font-size: 12px;">NAVEGADOR DE UNIDADES</b>
                </div>
                <div class="card-body" style="font-size: 14px;text-align: left;">
                    <empresas-unidades url-unities="/Companies/Unity" ></empresas-unidades>
                </div>
            </div>                
        </template>
    </modal-component>
  </div>
</template>

<script>
export default {
    props:['titulo','perfil_usuario'],
    methods:{
        selecionarUnidade(){
            $('#visualizadorUnidades').modal('show');
        }
    }

}
</script>

<style>
    .migalhas{
        padding-bottom: 0px;
    }

    @media(max-width:600px){
        .migalhas{
            padding-bottom: 5px;
        }   
    }
</style>