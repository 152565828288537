<template>
  <div>
        <div class="card" style="margin-bottom: 15px;" >
            <div class="card-body">
                <div>
                    <slot name="csrf">

                    </slot>
                </div>
                <div class="form-row" style="padding:1rem;">
                    <div class="form-group col-md-1">
                        <label for="anoInicio"> <b>ANO LETIVO</b></label>
                        <input class="form-control" type="number" id="anoInicio" name="anoInicio" v-model="anoInicio" :readonly="visualizar"/>
                    </div>
                    <!-- <div class="form-group col-md-3">
                        <label for="CursoGvdasa"><b>CURSOS DISPONÍVEIS</b></label>
                        <select name="CursoGvdasa" id="CursoGvdasa" class="form-control" v-model="cod_curso_selecionado" :disabled="visualizar">
                            <option value="0" selected>SELECIONE O CURSO</option>
                            <option v-for="curso in todos_cursos" :key="curso.CODIGOCURSO" :value="curso.CODIGOCURSO">{{ curso.DESCRICAO + ' - ' + curso.CODIGOCURSO}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-2">
                        <label for="Semestre"><b>PERIODO</b></label>
                        <select name="Semestre" id="Semestre" class="form-control" v-model="semestre_selecionado" :disabled="visualizar">
                            <option :value="''">SELECIONE O PERIODO</option>
                            <option :value="0">Anual</option>
                            <option :value="1">1º Semestre </option>
                            <option :value="2">2º Semestre </option>
                        </select>
                    </div>
                    <div class="form-group col-md-2">
                        <label for="dataInicio"><b>DATA INICIO INSCRIÇÃO</b></label></br>
                        <date-picker v-model="dataInicio" format="DD/MM/YYYY" type="date" id="dataInicio" name="dataInicio" style="width: 100%;" placeholder="__/__/____" :disabled="visualizar"></date-picker>
                    </div>
                    <div class="form-group col-md-2">
                        <label for="dataTermino"><b>DATA TÉRMINO INSCRIÇÃO</b></label></br>
                        <date-picker v-model="dataTermino" format="DD/MM/YYYY" type="date" id="dataTermino" name="dataTermino" style="width: 100%;" placeholder="__/__/____" :disabled="visualizar"></date-picker>
                    </div> -->
                </div>
                <!-- <div class="form-row" style="text-align:right;">
                    <div class=" form-group col-md-6"> -->
                        <blockquote class="blockquote text-center">
                            <p class="mb-0">Ao acessar esta funcionalidade, o sistema automaticamente irá exibir os Processos Seletivos configurados no ERP para esta Empresa/Unidade no ERP.</p>
                            <p class="mb-0">Para atualizar a listagem de Processos Seletivos configurados, clique em Filtrar</p>
                            <footer class="blockquote-footer"><cite title="Observação"><b>Observação: </b>Se não exibir os Processos Seletivos, entre em contato com o Responsável pelo ERP na Instituição para que seja ajustado.<br>Nossa ferramenta apenas comunica-se com a API do ERP nos servidores da Intituição.</cite></footer>
                            <button type="button" class="btn btn-primary btn-sm" @click="getProcessoSeletivoERP()">Filtrar</button>  <a :href="'/Access/Unity/Unitie/'+unitie.id+'/oferta_processo_seletivo'"  class="btn btn-primary btn-sm" >Exportações</a>
                        </blockquote>
                    <!-- </div>
                </div> -->
            </div>
        </div>
        <div v-if="processos_filtrados.length>0">
            <div class="form-row"  v-for="processo_seletivo,i in processos_filtrados" :key="i" >
                <div class="form-group col-md-12">
                    <content-component :id="(processo_seletivo.descricaoProcesso!='' && processo_seletivo.idProcesso>0?processo_seletivo.descricaoProcesso+processo_seletivo.idProcesso:'ERRONÃOENCONTRADO')" :padrao-exibicao="false"
                    >
                        <template v-slot:cabecalho >
                            <b>{{ (processo_seletivo.descricaoProcesso!='' && processo_seletivo.idProcesso>0?processo_seletivo.descricaoProcesso:'INFORMAÇÃO') }} {{ (processo_seletivo.descricaoModalidade!=''? ' - Modalidade: ' + processo_seletivo.descricaoModalidade:'') }}</b>
                        </template>
                        <template v-slot:conteudo >
                            
                            <table class="table table-sm table-hover ">
                                <thead style="background:#1c4d5a!important;color:white;white-space: nowrap;">
                                    <tr>
                                        <th class="align-middle">
                                            #ITEM
                                        </th>
                                        <th class="align-middle">
                                            Id Processo
                                        </th>
                                        <th class="align-middle">
                                            Ano
                                        </th>
                                        <th class="align-middle">
                                            Semestre
                                        </th>
                                        <th class="align-middle">
                                            Chamada
                                        </th>
                                        <th class="align-middle">
                                            Curso
                                        </th>
                                        <th class="align-middle">
                                            Tipo Processo
                                        </th>
                                        <th class="align-middle">
                                            Turno
                                        </th>
                            
                                        <th class="align-middle">
                                            Inicio Inscrição
                                        </th>
                                        <th class="align-middle">
                                            Término Inscrição
                                        </th>
                                        <th class="align-middle">
                                            Vagas
                                        </th>
                                        <th class="align-middle">
                                            Valor
                                        </th>
                                        <th class="align-middle">
                                            Configurado
                                        </th>
                                        <!--<th class="align-middle">
                                            Término Turma
                                        </th>-->
                                        <th>
                                            
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="processos_filtrados.length>0" >
                                    <template v-for="curso, item in processo_seletivo.cursos" >
                                        <tr :key="item">
                                            <td class="align-middle">
                                                {{ item + 1 }}
                                            </td>
                                            <td>
                                                {{ processo_seletivo.idProcesso }}
                                            </td>
                                            <td class="align-middle">
                                                {{ processo_seletivo.ano }}
                                            </td>
                                            <td class="align-middle">
                                                {{ processo_seletivo.semestre }}
                                            </td>
                                            <td class="align-middle">
                                                {{ processo_seletivo.chamada }}
                                            </td>
                                            <td class="align-middle">
                                                {{ curso.descricaoCurso }} 
                                            </td>
                                            <td class="align-middle">
                                                {{ processo_seletivo.descricaoTipoProcesso }}
                                            </td>
                                            <td class="align-middle">
                                                {{ curso.descricaoTurno }}
                                            </td>
                                            <td class="align-middle">
                                                {{ formatDate(processo_seletivo.dataInicio,2) }}
                                            </td>
                                            <td class="align-middle">
                                                {{ formatDate(processo_seletivo.dataTermino,2) }}
                                            </td>
                                            <td class="align-middle">
                                                {{ curso.vagas }}
                                            </td>
                                            <td class="align-middle">
                                                <b style="color:red;">{{ (processo_seletivo.valores[0].isento ? 'Isento' : $root.numberToReal(parseFloat(processo_seletivo.valores[0].valorInscricao)))  }}</b>
                                            </td>
                                            <td class="align-middle">
                                                <b-icon v-if="curso.turma_configurada" icon="check-lg" font-scale="1.3" style="color:green"></b-icon>
                                                <b-icon v-else icon="x-lg" font-scale="1.3" style="color:red"></b-icon>
                                            </td>
                                            <td class="align-middle">
                                                <button class="btn btn-sm btn-primary" @click="curso.configurarturma=!curso.configurarturma,desSelecionaPSE(),getTurmasERP(curso,processo_seletivo)" style="white-space: nowrap;" :disabled="visualizar || curso.configurarturma"><i class="fa-solid fa-gear"></i></button>
                                            </td>
                                        </tr>
                                        <tr v-show="curso.configurarturma">
                                            <td class="align-middle">
                                                <b>Turmas: </b>
                                            </td>
                                            <td colspan="10"  class="align-middle">
                                                <select name="listaTurmasCurso" id="listaTurmasCurso" v-model="curso.turma" class="form-control">
                                                    <option value="0">Selecione a Turma</option>
                                                    <option v-for="turma,item_turma in curso.listaturmas" :key="item_turma" :value="turma.CODIGOTURMA" :disabled="visualizar">ERP:{{ turma.DESCRICAOTURMA.trim() }} - CRM: {{ turma.DESCRICAOOFERTAPSE }}</option>
                                                </select>
                                            </td>
                                            <td class="align-middle">
                                                <b>Modalidade: {{ processo_seletivo.descricaoModalidade }}</b>
                                            </td>
                                            <!--td colspan="2"  class="align-middle">
                                                <select name="listaModalidades" id="listaModalidades" v-model="curso.idmodalidade" class="form-control">
                                                    <option value="0">Selecione a Modalidade</option>
                                                    <option v-for="modalidade, item_modalidade in modalidades" :key="item_modalidade" :value="modalidade.id" :disabled="visualizar">{{ modalidade.titulo}}</option>
                                                </select>
                                            </td-->
                                            <td class="align-middle">
                                                <b>Nível Ensino: {{ curso.DESCRICAONIVELENSINO }}</b>
                                            </td>
                                            <!--td colspan="2"  class="align-middle">
                                                <select name="listaNivelEnsino" id="listaNivelEnsino" v-model="curso.idnivelensino"  class="form-control">
                                                    <option value="0">Selecione o Nível de Ensino</option>
                                                    <option v-for="nivelensino, item_nivel in niveisensino" :key="item_nivel" :value="nivelensino.id" :disabled="visualizar">{{ nivelensino.titulo}}</option>
                                                </select>
                                            </td-->
                                            <td style="text-align: center; width: 150px!important;"  class="align-middle">
                                                <button class="btn btn-sm btn-primary" @click="salvarPse(processo_seletivo,curso);curso.configurarturma=!curso.configurarturma" style="white-space: nowrap;" :disabled="visualizar || curso.turma==null || curso.turma==0"><i class="fa-solid fa-floppy-disk"></i></button>
                                                <button class="btn btn-sm btn-primary" @click="excluirPse(processo_seletivo.idProcesso,curso.idCurso,curso.turma,curso.idTurno);curso.configurarturma=!curso.configurarturma" style="white-space: nowrap;" :disabled="visualizar || curso.turma==null || curso.turma==0"><b-icon icon="trash-fill" font-scale="1.3"></b-icon></button>
                                                <button class="btn btn-sm btn-primary" @click="curso.configurarturma=!curso.configurarturma" style="white-space: nowrap;" :disabled="visualizar"><i class="fa-solid fa-xmark"></i></button>
                                            </td>
                                            
                                        </tr>
                                        <tr v-if="curso.polos.length>0 && curso.configurarturma">
                                            <td colspan="14">
                                                <table class="table table-sm table-hover table-bordered">
                                                    <thead style="background:#1c4d5a!important;color:white;white-space: nowrap;">
                                                        <tr>
                                                            <th colspan="5" class="align-middle" style="width: 30px;">Polos vinculados ao Curso {{ curso.descricaoCurso }}</th>
                                                        </tr>
                                                        <tr>
                                                            <th class="align-middle" style="width: 30px;">Empresa Polo</th>
                                                            <th class="align-middle" style="width: 30px;">Unidade Polo</th>
                                                            <th class="align-middle">Descrição Polo</th>
                                                            <th class="align-middle" style="width: 30px;">Exportado</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="polo, ipl in curso.polos" :key="ipl">
                                                        <td class="align-middle">
                                                            {{ polo.idEmpresaPolo }}
                                                        </td>
                                                        <td class="align-middle">
                                                            {{ polo.idUnidadePolo }}
                                                        </td>
                                                        <td class="align-middle">
                                                            {{ polo.descricaoUnidadePolo }}
                                                        </td>
                                                        <td class="align-middle">
                                                            <b-icon v-if="polo.existe_crm" icon="check-lg" font-scale="1.3" style="color:green"></b-icon>
                                                            <b-icon v-else icon="x-lg" font-scale="1.3" style="color:red"></b-icon>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </template>
                                    
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="7"  class="align-middle" style="text-align:center;">
                                            <b>Não há turmas filtradas, filtre um turma utilizando os campos acima!!!</b>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </template>
                    </content-component>
                    
                </div>
            </div>
        </div>
        <div v-else>
            <div class="card" style="margin-bottom: 15px;" >
                <div class="card-body text-center">
                    {{ (msgCancelamento ? msgCancelamento : "Não existem informções de Processos Seletivos para exibir, clique em Filtrar.") }}
                    
                </div>
            </div>
        </div>
        
        <modal-component titulo="Exportação CRM" label-btn-fechar="OK" id="SalvarTurmasPse" :sem-padding="true">
            <template v-slot:titulo>
                Exportação CRM
            </template>
            <template v-slot:conteudo>
                <div>
                    <table class="table table-sm table-hover" style="margin: 0px;">
                        <thead style="background:#1c4d5a!important;color:white;white-space: nowrap;">
                            <tr>
                                <th class="align-middle" style="text-align:center;border: 0px;">
                                    MENSAGEM
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style="padding:0px;">
                                    <div class="alert alert-danger" v-if="error.msg" role="alert" style="margin:0px;text-align: center;">
                                        {{ error.msg }}
                                    </div>
                                    <div class="alert alert-success" v-else role="alert" style="margin:0px;text-align: center;">
                                        Turma exportada para o CRM!
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
            </template>
        </modal-component>
        <modal-component titulo="Exportação CRM" label-btn-fechar="OK" id="ExclusaoTurmaPse" :sem-padding="true" :url-redirect="dadosFiltro.anoliberacaoinscricaocursoweb ? urlRedirect:''">
            <template v-slot:titulo>
                Exportação CRM
            </template>
            <template v-slot:conteudo>
                <div v-if="error.msg!=''">
                    <table class="table table-sm table-hover" style="margin: 0px;">
                        <thead style="background:#1c4d5a!important;color:white;white-space: nowrap;">
                            <tr>
                                <th class="align-middle" style="text-align:center;border: 0px;">
                                    MENSAGEM
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style="padding:0px;">
                                    <div class="alert alert-danger" v-if="error.msg" role="alert" style="margin:0px;text-align: center;">
                                        {{ error.msg }}
                                    </div>
                                    <div class="alert alert-success" v-else role="alert" style="margin:0px;text-align: center;">
                                        Turma exportada para o CRM!
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>
        </modal-component>
        <loading id="carregandoPses" titulo="Buscando Processos Seletivos Ativos no ERP ..." :sem-padding="true" gif-carregando="/imagens/carregando.gif" @cancelaRequisicao="cancelarRequisicao()"></loading>
        <loading id="carregandoCursos" titulo="Obtendo dados de cursos do PSE ..." :sem-padding="true" gif-carregando="/imagens/carregando.gif" @cancelaRequisicao="cancelarRequisicao()"></loading>
        <loading id="carregandoTurmas" titulo="Obtendo dados de Turmas do PSE no ERP <br>Verificando se os Polos existem no CRM ..." :sem-padding="true" gif-carregando="/imagens/carregando.gif" @cancelaRequisicao="cancelarRequisicao()"></loading>        
        <loading id="salvandoConfiguracoes" titulo="Salvando as configurações" :sem-padding="true" gif-carregando="/imagens/carregando.gif" @cancelaRequisicao="cancelarRequisicao()"></loading>        
  </div>
</template>

<script>
import { throwStatement, tsMethodSignature } from '@babel/types';

export default {
    props:['usuarioLogado','unitie','urlRedirect','filtroEditado','visualizar'],
    data(){
        return{
            anoInicio:null,
            dataInicio:null,
            dataTermino:null,
            dataInicioFormat:null,
            dataTerminoFormat:null,
            todos_cursos:[],
            processos_filtrados:[],
            modalidades:[],
            niveisensino:[],
            cod_curso_selecionado:0,
            pse_salvar:{
                processo:{},
                turma_salva:{}
            },
            urlapigvdasa:null,
            tokenapigvdasa:null,
            urlapirubeus:null,
            tokenapirubeus:null,
            filtroPesquisaProcessoSeletivo:{
                ano:null,
                semestre:null,
                idEmpresa:null,
                idUnidade:null,
                idUnidadePk:null,
                dataInicio:null,
                dataTermino:null,
                idCurso:null,
                curso:null,
                oferta_processo_seletivo:0,
                oferta_Inscricao_web:1,
                inscricao_web_aluno:0,
                importar_parcela:0,
                download_contrato:0
            },
            curso_selecionado:{
                CODIGOCURSO:null,
            },
            turma_selecionada:{

            },
            semestre_selecionado:"",
            ofertaCursoTurma:{
                codCurso:null,
                codOferta:null,
                codigo:null,
                nomeCurso:null,
                descricaoCurso:null,
                nome:null,
                codUnidade:null,
                inicioInscricao:null,
                terminoInscricao:null,
                inicioMatricula:null,
                terminoMatricula:null,
                inicioCurso:null,
                terminoCurso:null,
                codNivelEnsino:2,
                modalidade:1,
                codLocalOferta:null,
                origem:null

            },
            dadosFiltro:{

            },
            error:{
                msg:null,
            },
            CancelToken:null,
            cancelTokenSource:null,
            msgCancelamento:null,
        }
    },
    created(){
        // this.anoInicio = this.anoTurma;
        // 
         
        if(this.unitie.urlapigvdasa!="" && this.unitie.urlapigvdasa){
            this.urlapigvdasa = this.unitie.urlapigvdasa;
        }else if(this.unitie.companies.urlapigvdasa!="" && this.unitie.companies.urlapigvdasa){
            this.urlapigvdasa = this.unitie.companies.urlapigvdasa;
        } 

        if(this.unitie.tokenapigvdasa!="" && this.unitie.tokenapigvdasa){
            this.tokenapigvdasa = this.unitie.tokenapigvdasa;
        }else if(this.unitie.companies.tokenapigvdasa!="" && this.unitie.companies.tokenapigvdasa){
            this.tokenapigvdasa = this.unitie.companies.tokenapigvdasa;
        } 

        if(this.unitie.urlapirubeus!="" && this.unitie.urlapirubeus){
            this.urlapirubeus = this.unitie.urlapirubeus;
        }else if(this.unitie.companies.urlapirubeus!="" && this.unitie.companies.urlapirubeus){
            this.urlapirubeus = this.unitie.companies.urlapirubeus;
        } 

        if(this.unitie.tokenapirubeus!="" && this.unitie.tokenapirubeus){
            this.tokenapirubeus = this.unitie.tokenapirubeus;
        }else if(this.unitie.companies.tokenapirubeus!="" && this.unitie.companies.tokenapirubeus){
            this.tokenapirubeus = this.unitie.companies.tokenapirubeus;
        } 

        // if(this.urlapigvdasa!="" && this.tokenapigvdasa!=""){
        //     this.getCursosERP();
        // }

        let dataAtual = new Date();
        if(this.filtroEditado.id>0){
            dataAtual =new Date()
            this.anoInicio = this.filtroEditado.anoliberacaoinscricaocursoweb
            this.cod_curso_selecionado = this.filtroEditado.idcurso
            this.filtroPesquisaProcessoSeletivo.curso = this.filtroEditado.nomecurso
            this.semestre_selecionado = (this.filtroEditado.semestre==null ? '':this.filtroEditado.semestre);
            this.dataInicio = new Date(this.filtroEditado.datainicio)
            this.dataTermino = new Date(this.filtroEditado.datatermino)
        }else{

            this.anoInicio = dataAtual.getFullYear();
            

        }
        this.filtroPesquisaProcessoSeletivo.idEmpresa = this.unitie.companies.codigoempresa
        this.filtroPesquisaProcessoSeletivo.idUnidade = this.unitie.codigounidade
        // this.getProcessoSeletivoERP()
        
        
    },
    watch:{
        cod_curso_selecionado(new_curso,old_curso){
            if(new_curso!=old_curso){

                for(let i=0;i<this.todos_cursos.length;i++){
                    // console.log(this.todos_cursos[i].CODIGOCURSO) 
                    if(this.todos_cursos[i].CODIGOCURSO==new_curso){
                        this.curso_selecionado=this.todos_cursos[i]
                        this.filtroPesquisaProcessoSeletivo.curso = this.curso_selecionado.DESCRICAO
                        // console.log('CURSO',this.cursos[i].DESCRICAO)
                    }
                }

                this.cod_curso_selecionado = new_curso
                this.ofertaCursoTurma.curso
                if(new_curso>0){
                    this.filtroPesquisaProcessoSeletivo.idCurso = new_curso
                }else{
                    this.filtroPesquisaProcessoSeletivo.idCurso = null
                }
            }
        },
        semestre_selecionado(new_semestre,old_semestre){
            if(new_semestre!=old_semestre){
                this.semestre_selecionado = new_semestre;
                this.filtroPesquisaProcessoSeletivo.semestre = this.semestre_selecionado;
            }

        },
        dataInicio(newDataInicio,oldDataInicio){
            if(newDataInicio!=oldDataInicio){
                if(this.formatDate(this.dataInicio,1)=='1969-12-31'){
                    // console.log('data',this.dataInicio)
                    this.filtroPesquisaProcessoSeletivo.dataInicio=null
                    this.dataInicio = null
                }else{
                    this.dataInicio = newDataInicio;
                    this.filtroPesquisaProcessoSeletivo.dataInicio = this.formatDate(this.dataInicio,1)
                }
            }
        },
        dataTermino(newDataTermino,oldDataTermino){
            if(newDataTermino!=oldDataTermino){
                if(this.formatDate(this.dataTermino,1)=='1969-12-31'){
                    // console.log('data',this.dataInicio)
                    this.filtroPesquisaProcessoSeletivo.dataTermino=null
                    this.dataTermino = null
                }else{
                        this.dataTermino = newDataTermino;
                        this.filtroPesquisaProcessoSeletivo.dataTermino = this.formatDate(newDataTermino,1);
                    }
            }
        },
        anoInicio(newAno,oldAno){
            if(newAno!=oldAno){
                this.anoInicio=newAno
                this.filtroPesquisaProcessoSeletivo.ano = newAno;
            }
        }
    },
    methods:{
        desSelecionaPSE(){
            this.pse_salvar = {
                processo:{},
                turma_salva:{}
            }
        },
        cancelarRequisicao(){
            
            this.cancelTokenSource.cancel("Pesquisa de PSES do ERP cancelada");
            this.msgCancelamento = "Pesquisa de PSES do ERP cancelada"
            
        },
        formatDate(date,idioma) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            if(idioma==1){
                return [year, month, day].join('-');
            }else if(idioma==2){
                return [day, month, year].join('/');
            }else{
                return [year, month, day].join('-');
            }
        },
        async getCursoERP(curso){
            var config = {
                    method: 'get',
                    url: '/api/v1/Erp/Curso/' + this.unitie.id +'/id/'+curso.idCurso,
                };
                
                //executa requisição
                await axios(config)
                .then(response => {
                    // curso = response.data.cursoErp;
                    curso.CODIGOCURSO = response.data.cursoErp.CODIGOCURSO
                    curso.DESCRICAOESTRUTURAACADEMICA = response.data.cursoErp.DESCRICAOESTRUTURAACADEMICA
                    curso.DESCRICAONIVELENSINO = response.data.cursoErp.DESCRICAONIVELENSINO
                    curso.CODIGONIVELENSINO = response.data.cursoErp.CODIGONIVELENSINO
                    curso.CODIGOESTRUTURAACADEMICA = response.data.cursoErp.CODIGOESTRUTURAACADEMICA
                    // console.log('in curso',curso)
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        async getTurmasERP(curso,pse){
            // if(curso.listaturmas==null){
                
                $('#carregandoTurmas').modal('show');

                let dadosPesquisa = {
                    ano: this.anoInicio,
                    idCurso: curso.idCurso,
                    idEmpresa: this.unitie.companies.codigoempresa,
                    idUnidade: this.unitie.codigounidade,
                    idProcesso: pse.idProcesso,
                    codigoturno: curso.idTurno
                    
                }
                console.log('dadosPesquisa',dadosPesquisa);
                this.CancelToken = axios.CancelToken;
                this.cancelTokenSource = this.CancelToken.source();
                this.msgCancelamento = null

                var url= '/api/v1/Erp/Turmas/' + this.unitie.id;
               
                let formData = new FormData();
                formData.append('dados',JSON.stringify(dadosPesquisa))
                var config = {
                        // method: 'post',
                        // url: '/api/v1/Erp/Inscricoes/' + this.unitie.id,
                        // headers:{
                        //             'Accept': 'application/json',
                        //             'Authorization': 'Bearer '+this.tokenapigvdasa,
                        //             'Gvdasa':1
                        //         }
                        cancelToken: this.cancelTokenSource.token
                    };
                    
                    //executa requisição
                    await axios.post(url,formData,config)
                    .then(response => {
                        
                        curso.listaturmas = response.data.turmas
                        //console.log('in turmas',curso.listaturmas)
                        $('#carregandoTurmas').modal('hide');
                        
                        this.validaExistenciaPolos(curso)
                    })
                    .catch(function (error) {
                        console.log(error);
                        $('#carregandoTurmas').modal('hide');
                    });


            // }
        },
        async getTurmaConfiguradaErpByIdCurso(curso,pse){
            let dadosPesquisa = {
                ano: this.anoInicio,
                idCurso: curso.idCurso,
                idEmpresa: this.unitie.companies.codigoempresa,
                idUnidade: this.unitie.codigounidade,
                idProcesso: pse.idProcesso,
                idTurno:curso.idTurno
                
            }
            //console.log('dadosPesquisa',dadosPesquisa);
            this.CancelToken = axios.CancelToken;
            this.cancelTokenSource = this.CancelToken.source();
            this.msgCancelamento = null

            var url= '/api/v1/Erp/TurmaConfigurada/' + this.unitie.id;
            
            let formData = new FormData();
            formData.append('dados',JSON.stringify(dadosPesquisa))
            var config = {
                    // method: 'post',
                    // url: '/api/v1/Erp/Inscricoes/' + this.unitie.id,
                    // headers:{
                    //             'Accept': 'application/json',
                    //             'Authorization': 'Bearer '+this.tokenapigvdasa,
                    //             'Gvdasa':1
                    //         }
                    cancelToken: this.cancelTokenSource.token
                };
                
                //executa requisição
                await axios.post(url,formData,config)
                .then(response => {
                    
                    curso.turma = response.data.turma_configurada
                    curso.turma_configurada = (response.data.turma_configurada>0 ? true : false)
                   // console.log('in turmas configuradas',curso.turma)
                    
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        async getTodosCursosPse(){
            $('#carregandoCursos').modal('show');
            if(this.processos_filtrados.length>0){
                for(let iPse = 0; iPse < this.processos_filtrados.length; iPse++){
                    
                    for(let iCur = 0; iCur < this.processos_filtrados[iPse].cursos.length; iCur++){
                        
                        let cur = await this.getCursoERP(this.processos_filtrados[iPse].cursos[iCur])
                        let tur = await this.getTurmaConfiguradaErpByIdCurso(this.processos_filtrados[iPse].cursos[iCur],this.processos_filtrados[iPse]);
                        // console.log('cursos_ose',this.processos_filtrados[iPse].cursos[iCur])

                    }

                }
            }
            $('#carregandoCursos').modal('hide');
            
        },
        async getTodasTurmasPse(){
            
            if(this.processos_filtrados.length>0){
                for(let iPse = 0; iPse < this.processos_filtrados.length; iPse++){
                    
                    for(let iCur = 0; iCur < this.processos_filtrados[iPse].cursos.length; iCur++){
                        
                        
                        let tur = await this.getTurmasERP(this.processos_filtrados[iPse].cursos[iCur],this.processos_filtrados[iPse]);
                        // console.log('cursos_ose',this.processos_filtrados[iPse].cursos[iCur])

                    }

                }
            }
            
        },
        async getProcessoSeletivoERP(){
            this.CancelToken = axios.CancelToken;
            this.cancelTokenSource = this.CancelToken.source();
            this.msgCancelamento = null
            $('#carregandoPses').modal('show');
            var url= '/api/v1/Erp/ProcessoSeletivo/' + this.unitie.id;
            // console.log(this.filtroPesquisaProcessoSeletivo)
            let formData = new FormData();
            formData.append('filtroPesquisaProcessoSeletivo',JSON.stringify(this.filtroPesquisaProcessoSeletivo))
            var config = {
                    // method: 'post',
                    // url: '/api/v1/Erp/Inscricoes/' + this.unitie.id,
                    // headers:{
                    //             'Accept': 'application/json',
                    //             'Authorization': 'Bearer '+this.tokenapigvdasa,
                    //             'Gvdasa':1
                    //         }
                    cancelToken: this.cancelTokenSource.token
                };
                
                //executa requisição
                await axios.post(url,formData,config)
                .then(response => {
                    
                    if(response.data.InscricoesErp){
                        this.processos_filtrados = response.data.InscricoesErp
                        this.modalidades = response.data.modalidades
                        this.niveisensino = response.data.niveisensino
                        
                        $('#carregandoPses').modal('hide');
                        
                    }else{
                        console.log(response.data)
                        $('#carregandoPses').modal('hide');
                    }
                    
                })
                .catch(function (error) {
                    console.log(error);
                    $('#carregandoPses').modal('hide');
                });

                if(this.processos_filtrados.length>0){
                    this.getTodosCursosPse()
                }
                
                //console.log('curso',this.processos_filtrados);
        },
        async validaExistenciaPolos(curso){
            
            if(curso.polos && curso.polos.length>0 && !curso.polo_atualizado){
                
                $('#sincronizandoPolos').modal('show');

                for(let iPol = 0; iPol < curso.polos.length; iPol ++ ){
                    let codigoexterno = curso.polos[iPol].idEmpresaPolo+'-' + curso.polos[iPol].idUnidadePolo
                    this.localOfertaExisteCrmByCodigoExterno(curso.polos[iPol],codigoexterno)
                }

                $('#sincronizandoPolos').modal('hide');

            }

            curso.polo_atualizado = true;
        },
        async localOfertaExisteCrmByCodigoExterno(polo,codigoexterno){
            this.CancelToken = axios.CancelToken;
            this.cancelTokenSource = this.CancelToken.source();
            this.msgCancelamento = null
            var url= '/api/v1/Crm/localOferta/'+ this.unitie.id +'/codigoexterno/' +codigoexterno ;
            // console.log(this.filtroPesquisaProcessoSeletivo)
            
            var config = {
                    // method: 'post',
                    // url: '/api/v1/Erp/Inscricoes/' + this.unitie.id,
                    // headers:{
                    //             'Accept': 'application/json',
                    //             'Authorization': 'Bearer '+this.tokenapigvdasa,
                    //             'Gvdasa':1
                    //         }
                    cancelToken: this.cancelTokenSource.token
                };
               
                //executa requisição
                await axios.get(url,config)
                .then(response => {
                    console.log(polo)
                    polo.existe_crm = response.data.existe_crm
                    
                })
                .catch(function (error) {
                    console.log(error);

                });
        },
        salvarPse(processo_seletivo,curso){
            this.pse_salvar.processo = {
                ano:processo_seletivo.ano,
                idProcesso:processo_seletivo.idProcesso,
                semestre:processo_seletivo.semestre,
                chamada:processo_seletivo.chamada,
                dataInicio:processo_seletivo.dataInicio,
                dataTermino:processo_seletivo.dataTermino,
                dataLimiteVencimento:processo_seletivo.dataLimiteVencimento,
                descricaoProcesso:processo_seletivo.descricaoProcesso,
                descricaoTipoProcesso:processo_seletivo.descricaoTipoProcesso,
                idTipoProcesso:processo_seletivo.idTipoProcesso,
                descricao:processo_seletivo.descricaoProcesso,
                descricaoModalidade:processo_seletivo.descricaoModalidade,
                tipoModalidade:processo_seletivo.tipoModalidade,
                polos:processo_seletivo.polos,
            }

            this.pse_salvar.turma_salva = curso

            if(curso.turma==0){
                window.alert('Selecione uma turma antes de salvar');

            }else{
                $('#salvandoConfiguracoes').modal('show');
                console.log(processo_seletivo,JSON.stringify(this.pse_salvar))
                let url='/api/v1/Erp/SalvarPse/'+this.unitie.id
                // console.log('pse',processo_seletivo)
                let formData = new FormData();
                formData.append('processos_seletivos',JSON.stringify(processo_seletivo))
                formData.append('curso_salvo',JSON.stringify(curso))
                formData.append('pse_salvar',JSON.stringify(this.pse_salvar))
                //console.log(curso)
                
                let configuracao = {
                    // method:'post',
                    // headers:{
                    //     'Accept': 'application/json',
                    //     'Authorization': this.tokenapirubeus,
                    //     'Rubeus':1
                    // }
                }

                axios.post(url,formData,configuracao)
                    .then(response => {
                         console.log('response',response.data)
                        if(response.data.msg){
                            this.error.msg = response.data.msg
                            curso.turma = 0
                            
                            curso.turma_configurada = false
                        }else{
                            for(let i=0 ;i< this.processos_filtrados.length;i++){
                                if(this.processos_filtrados[i].idProcesso==processo_seletivo.idProcesso){
                                    this.processos_filtrados[i]=processo_seletivo
                                    this.error.msg = null;
                                    curso.turma_configurada = true
                                    //$('#$('#salvandoConfiguracoes').modal('show');').modal('show');
                                    
                                }
                            }
                        }
                        $('#salvandoConfiguracoes').modal('hide');
                        //console.log(response.data.processos_seletivos)
                        
                    })
                    .catch(errors => {
                        // this.errors = errors.response.data.errors
                        console.log(errors.response)
                    })

                
                //console.log(this.ofertaCursoTurma)
            }
            
        },
        excluirPse(idprocesso,course,school_class_id,idTurno){
            
            var config = {
                method: 'delete',
                url: '/api/v1/Erp/ExcluirPse/'+idprocesso+'/'+course+'/'+school_class_id+'/'+idTurno,
                // headers: {
                //         // 'Accept': 'application/json',
                //         // 'Authorization': this.$root.token(),
                //     },
                };
                //executa requisição
                axios(config)
                .then(response => {
                    //window.alert(response.data.msg);
                    
                    this.processos_filtrados.forEach(pse => {
                        if(pse.idProcesso==idprocesso){
                            pse.cursos.forEach(cur => {
                                if(cur.idCurso==course && cur.idTurno == idTurno){
                                //    window.alert(cur.turma)
                                   cur.turma = 0;
                                   cur.turma_configurada = false
                                   this.error.msg = response.data.msg
                                   //$('#ExclusaoTurmaPse').modal('show');
                                }
                            });
                        }
                    });
                    
                    
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }
}
</script>

<style>

</style>