<template>
    <div v-if="servicePart" class="modal show"  :id="'ExclusaoserviceParts'+id" tabindex="-1" role="dialog" :aria-labelledby="'ExclusaoservicePartsLabel'" aria-hidden="true" @click="fechar()">
        <div class="modal-dialog  modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Exclusão Serviçoes e Peças</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div v-if="servicePart" style="text-align:left;">
                        <b>ID:</b>{{ servicePart.id }}
                        <br/>
                        <b>Descrição:</b>{{ servicePart.descricao }}
                        <br/>
                    </div>
                    <div v-else style="text-align:center;">
                        <b>OPS...</b>
                    </div>
                    <b-alert style="margin-bottom:0px;text-align:center;" v-if="servicePartExcluida.status=='OK'" variant="success" show><b>{{ servicePartExcluida.mensagem }}</b></b-alert>
                    <b-alert style="margin-bottom:0px;text-align:center;" v-if="servicePartExcluida.status=='NOK'" variant="danger" show><b>{{ servicePartExcluida.mensagem }}</b></b-alert>
                </div>
                <div class="modal-footer">
                    <button v-if="!servicePartExcluida.status" type="button" class="btn btn-danger" :id="'btnOkExclusaoserviceParts'" @click="excluirservicePart(servicePart)">Excluir</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" :id="'Fechar'" @click="fechar()">Fechar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        urlRedirect:{type:String, requied:true},
        servicePart:{type:Object, requied:false},
        urlSistema:{type:String, requied:true},     
        id:{type:String,required:false}   
    },
    
    data(){
        return {
            servicePartSelecionada:{},
            servicePartExcluida:{},
        }
    },
    created(){
         this.servicePartSelecionada = this.servicePart
         console.log(this.servicePart)
    },
    methods:{
        redirect(){
            window.location.href = this.urlRedirect;
        },
        fechar(){
            this.servicePartExcluida = {}
        },
        excluirservicePart(servicePart){
            var config = {
            method: 'delete',
            url: '/api/v1'+this.urlSistema+'/'+servicePart.id,
            // headers: {
            //         'Accept': 'application/json',
            //         'Authorization': this.$root.token(),
            //     },
            };
            //executa requisição
            axios(config)
            .then(response => {

                this.servicePartExcluida = response.data;
                console.log(this.servicePartExcluida.status,response);
                this.$emit('exclusaoconcluida' /*, this.nclick*/);
                
            })
            .catch(function (error) {
                console.log(error);
            });
            console.log('#ExclusaoserviceParts'+this.id)
            // $('#ExclusaoserviceParts'+this.id).modal('');
        }
        
    }
}
</script>

<style>
    
</style>
