<template>
  <div id="cartao-menu-item">
      <div>
          <b-icon :icon="icone" :font-scale="tamanhoFonte"></b-icon>
      </div>
      <br/>
      <div class="link">
          <a :href="link" >{{descricaoLink}}</a>
      </div>
  </div>
</template>

<script>
export default {
    props:['descricaoLink','link','icone','tamanhoFonte'],
}
</script>

<style scoped>
    #cartao-menu-item{
        text-align: center;
        border: 1px solid #1c4d5a33;
        -webkit-box-shadow: 3px 5px 5px #1c4d5a75;
		-moz-box-shadow:    3px 5px 5px #1c4d5a75;
		box-shadow:         3px 5px 5px #1c4d5a75;
        padding: 20px;
        margin: 10px;
        max-width: 100%;
        min-width: 100%;
        max-height: 130px;
        min-height: 130px;
        background: white;
        border-radius: 10px;
    }



</style>
