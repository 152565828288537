<template>
    <div >
        <div :id="'Div' + nomeFormulario" :name="'Div' + nomeFormulario" class="div-crud-sistema">
            <form :method="metodoFormulario" :action="linkAction" :id="nomeFormulario" :name="nomeFormulario">
                <div v-if="!edit">
                    <slot name="csrf">

                    </slot>
                </div>
                <input type="hidden" name="id_empresa" :value="processo_seletivo.id">
                
                <div>
                    <div class="form-row">
                        <div class="form-group col-md-3">
                            <label for="idliberacaoinscricaocurso">ID Processo (ERP)</label>
                            <input :readonly="visualizar" v-if="!errors.idliberacaoinscricaocurso" type="text" class="form-control" id="idliberacaoinscricaocurso" name="idliberacaoinscricaocurso" placeholder="ID Processo (ERP)" v-model="processo_seletivo.idliberacaoinscricaocurso">
                            <input :readonly="visualizar" v-else type="text" class="form-control is-invalid" id="idliberacaoinscricaocurso" name="idliberacaoinscricaocurso" placeholder="ID Processo (ERP)" v-model="processo_seletivo.idliberacaoinscricaocurso">
                            <div class="invalid-feedback" v-if="errors.idliberacaoinscricaocurso">
                                {{ errors.idliberacaoinscricaocurso[0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <label for="ano">Ano (ERP)</label>
                            <input :readonly="visualizar" v-if="!errors.ano" type="text" class="form-control" id="ano" name="ano" placeholder="Ano (ERP)" v-model="processo_seletivo.ano">
                            <input :readonly="visualizar" v-else type="text" class="form-control is-invalid" id="ano" name="ano" placeholder="Ano (ERP)" v-model="processo_seletivo.ano">
                            <div class="invalid-feedback" v-if="errors.ano">
                                {{ errors.ano[0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <label for="semestre">Semestre (ERP)</label>
                            <input :readonly="visualizar" v-if="!errors.semestre" type="text" class="form-control" id="semestre" name="semestre" placeholder="Semestre (ERP)" v-model="processo_seletivo.semestre">
                            <input :readonly="visualizar" v-else type="text" class="form-control is-invalid" id="semestre" name="semestre" placeholder="Semestre (ERP)" v-model="processo_seletivo.semestre">
                            <div class="invalid-feedback" v-if="errors.semestre">
                                {{ errors.semestre[0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <label for="descricaonivelensino">Nível Ensino (ERP)</label>
                            <input :readonly="visualizar" v-if="!errors.descricaonivelensino" type="text" class="form-control" id="descricaonivelensino" name="descricaonivelensino" placeholder="Semestre (ERP)" v-model="processo_seletivo.descricaonivelensino">
                            <input :readonly="visualizar" v-else type="text" class="form-control is-invalid" id="descricaonivelensino" name="descricaonivelensino" placeholder="Semestre (ERP)" v-model="processo_seletivo.descricaonivelensino">
                            <div class="invalid-feedback" v-if="errors.descricaonivelensino">
                                {{ errors.descricaonivelensino[0] }}
                            </div>
                        </div>
                        
                        
                        
                        
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-3">
                            <label for="descricaoestruturaacademica">Estrutura Acadêmica (ERP)</label>
                            <input :readonly="visualizar" v-if="!errors.descricaoestruturaacademica" type="text" class="form-control" id="descricaoestruturaacademica" name="descricaoestruturaacademica" placeholder="Descrição Processo (ERP)" v-model="processo_seletivo.descricaoestruturaacademica">
                            <input :readonly="visualizar" v-else type="text" class="form-control is-invalid" id="descricaoestruturaacademica" name="descricaoestruturaacademica" placeholder="Descrição Processo (ERP)" v-model="processo_seletivo.descricaoestruturaacademica">
                            <div class="invalid-feedback" v-if="errors.descricaoestruturaacademica">
                                {{ errors.descricaoestruturaacademica[0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <label for="descricaoorigemmatricula">Origem de Matrícula (ERP)</label>
                            <input :readonly="visualizar" v-if="!errors.descricaoorigemmatricula" type="text" class="form-control" id="descricaoorigemmatricula" name="descricaoorigemmatricula" placeholder="Descrição Processo (ERP)" v-model="processo_seletivo.descricaoorigemmatricula">
                            <input :readonly="visualizar" v-else type="text" class="form-control is-invalid" id="descricaoorigemmatricula" name="descricaoorigemmatricula" placeholder="Descrição Processo (ERP)" v-model="processo_seletivo.descricaoorigemmatricula">
                            
                            <div class="invalid-feedback" v-if="errors.descricaoorigemmatricula">
                                {{ errors.descricaoorigemmatricula[0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <label for="publico">Público (ERP)</label>
                            <input :readonly="visualizar" v-if="!errors.publico" type="text" class="form-control" id="publico" name="publico" placeholder="Descrição Processo (ERP)" v-model="processo_seletivo.publico">
                            <input :readonly="visualizar" v-else type="text" class="form-control is-invalid" id="publico" name="publico" placeholder="Descrição Processo (ERP)" v-model="processo_seletivo.publico">
                            <div class="invalid-feedback" v-if="errors.publico">
                                {{ errors.publico[0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <label for="descricaoorigemmatricula">Origem de Matrícula (ERP)</label>
                            <input :readonly="visualizar" v-if="!errors.descricaoorigemmatricula" type="text" class="form-control" id="descricaoorigemmatricula" name="descricaoorigemmatricula" placeholder="Descrição Processo (ERP)" v-model="processo_seletivo.descricaoorigemmatricula">
                            <input :readonly="visualizar" v-else type="text" class="form-control is-invalid" id="descricaoorigemmatricula" name="descricaoorigemmatricula" placeholder="Descrição Processo (ERP)" v-model="processo_seletivo.descricaoorigemmatricula">
                            
                            <div class="invalid-feedback" v-if="errors.descricaoorigemmatricula">
                                {{ errors.descricaoorigemmatricula[0] }}
                            </div>
                        </div>
                    </div>
                    <!--div class="form-row">
                        <div class="form-group col-md-2">
                            <label for="inputCelular">Data Inicio</label>
                            <date-picker v-model="processo_seletivo.datainicio" v-if="!errors.datainicio" format="DD/MM/YYYY" type="date" id="datainicio" name="datainicio" style="width: 100%;" placeholder="__/__/____" :disabled="visualizar"></date-picker>
                            <date-picker v-model="processo_seletivo.datainicio" v-else format="DD/MM/YYYY" type="date" id="datainicio" name="datainicio" style="width: 100%;" placeholder="__/__/____" :disabled="visualizar"></date-picker>
                            <div class="invalid-feedback" v-if="errors.datainicio">
                                {{ errors.datainicio[0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-2">
                            <label for="datatermino">Data Término</label>
                            <date-picker v-model="processo_seletivo.datatermino" v-if="!errors.datatermino" format="DD/MM/YYYY" type="date" id="datatermino" name="datatermino" style="width: 100%;" placeholder="__/__/____" :disabled="visualizar"></date-picker>
                            <date-picker v-model="processo_seletivo.datatermino" v-else  format="DD/MM/YYYY" type="date" id="datatermino" name="datatermino" style="width: 100%;" placeholder="__/__/____" :disabled="visualizar"></date-picker>
                            
                            <div class="invalid-feedback" v-if="errors.datatermino">
                                {{ errors.datatermino[0] }}
                            </div>
                        </div>
                    </div-->
                    <div class="form-row" v-if="users.length>0">
                    </div>
                </div>
                <br/>
                <a :href="'/'+urlSistema" class="btn btn-primary">Cancelar</a>
                <!--button :disabled="visualizar" type="button" class="btn btn-primary" @click="insereOuAtualiza()">Salvar</button>
                <button type="button" class="btn btn-primary" @click="exportarPSE()">Exportar para Rubeus</button-->
            </form>
        </div>
        <div :id="'DivTableCurso' + nomeFormulario" :name="'DivTableCurso' + nomeFormulario" class="div-table-sistema">
            <div class="table-crud-sistema">
                <div class="form-row">
                    <table class="table table-sm table-hover " style="font-size: 11px;width: 99%;">
                        <thead style="background:#1c4d5a!important;color:white;">
                            <tr>
                                <th colspan="9" style="text-align:center;">
                                    Inscrição Web - Cursos vinculados
                                </th>
                            </tr>
                            <tr>
                                <th style="text-align:center;">
                                    Codigo Curso (ERP)
                                </th>
                                <th style="text-align:center;">
                                    Código Identificação (ERP)
                                </th>
                                <th style="text-align:center;">
                                    Descrição Curso
                                </th>
                                <!--th style="text-align:center;">
                                    Descrição Documento
                                </th>
                                <th style="text-align:center;">
                                    Periodo
                                </th>
                                <th style="text-align:center;">
                                    Tipo Curso
                                </th-->
                                <th style="text-align:center;">
                                    Nível de Ensino
                                </th>
                                <th style="text-align:center;">
                                    Modalidade
                                </th>
                                <th style="text-align:center;">
                                    Ativo
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="processo_seletivo.curso_inscricao">
                                <td style="text-align:center;">
                                    {{ processo_seletivo.curso_inscricao.codigocurso}}
                                </td>
                                <td style="text-align:center;">
                                    {{ processo_seletivo.curso_inscricao.codigoidentificacao }}
                                </td>
                                <td style="text-align:left;">
                                    {{ processo_seletivo.curso_inscricao.descricaocurso }}
                                </td>
                                <!--td style="text-align:left;">
                                    {{ processo_seletivo.curso_inscricao.descricaodocumento }}
                                </td>
                                <td style="text-align:center;">
                                    {{ processo_seletivo.curso_inscricao.periodo }}
                                </td>
                                <td style="text-align:center;">
                                    {{ processo_seletivo.curso_inscricao.tipocurso }}
                                </td-->
                                <td style="text-align:center;">
                                    {{ processo_seletivo.curso_inscricao.descricaonivelensino }}
                                </td>
                                <td style="text-align:center;">
                                    {{ processo_seletivo.curso_inscricao.descricaomodalidade }}
                                </td>
                                <td style="text-align:center;">
                                    {{ processo_seletivo.curso_inscricao.ativo }}
                                </td>
                            </tr>
                        </tbody>
        
                    </table>
                </div>
            </div>
        </div>
        <div v-if="msgErro && !success" style="margin-top: 15px;">
            <b-alert
                :show="dismissCountDownDanger"
                dismissible
                variant="danger"
                @dismissed="dismissCountDownDanger=0"
                @dismiss-count-down="countDownChangedDanger"
                >
                <strong>Mensagem:</strong> Não foi possível Exportar a Oferta: {{ ofertaCursoTurma.nome }}.<br/>
                <strong>Resposta ERP:</strong> {{ msgErro }}
            </b-alert>
        </div>
        <div v-if="success" style="margin-top: 15px;">
            <b-alert
                :show="dismissCountDown"
                dismissible
                variant="success"
                @dismissed="dismissCountDown=0"
                @dismiss-count-down="countDownChanged"
                >
                <strong>Mensagem:</strong> Oferta: {{ ofertaCursoTurma.nome }} exportada com sucesso para o CRM.<br/>
            </b-alert>
        </div>
        <div :id="'DivTableTurmas' + nomeFormulario" :name="'DivTableTurmas' + nomeFormulario" class="div-table-sistema">
            <div class="table-crud-sistema">
                <table class="table table-sm table-hover ">
                    <thead style="background:#1c4d5a!important;color:white;">
                        <tr>
                            <th colspan="16" style="text-align:center;">
                                Inscrição Web - Turmas vinculadas
                            </th>
                        </tr>
                        <tr>
                            <!--th style="text-align:center;">
                                ITEM
                            </th-->
                            <th style="text-align:center;">
                                Oferta
                            </th>
                            <!--th style="text-align:center;">
                                Ano
                            </th-->
                            <th style="text-align:center;">
                                Descrição Turma
                            </th>
                            <th style="text-align:center;">
                                Descrição Ciclo
                            </th>
                            <!--th style="text-align:center;">
                                Inicio
                            </th>
                            <th style="text-align:center;">
                                Término
                            </th-->
                            <th style="text-align:center;">
                                Inicio Inscrição
                            </th>
                            <th style="text-align:center;">
                                Término Inscrição
                            </th>
                            <th style="text-align:center;">
                                Vagas
                            </th>
                            <!--th style="text-align:center;">
                                Situação
                            </th-->
                            <th style="text-align:center;">
                                Valor
                            </th>
                            <th style="text-align:center;">
                                Expirado
                            </th>
                            <th style="text-align:center;">
                                Existe no CRM
                            </th>
                            <th style="text-align:center;">
                                Existe no ERP
                            </th>
                            <th style="text-align:center;">
                                
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="turma,i in processo_seletivo.turmas_inscricao" :key="i">
                            <!--td style="text-align:center;">
                                {{ i+1 }}
                            </td-->
                            <td style="text-align:center;white-space: nowrap;">
                                {{ turma.codigooferta }}
                            </td>
                            <!--td style="text-align:center;">
                                {{ turma.anoinicio }}
                            </td-->
                            <td style="text-align:left;white-space: nowrap;">
                                {{ turma.descricaoofertainscricao }}
                            </td>
                            <td style="text-align:center;white-space: nowrap;">
                                {{ turma.descricaociclo }}
                            </td>
                            <!--td style="text-align:center;">
                                {{ formatDate(turma.datainicio,2) }}
                            </td>
                            <td style="text-align:center;">
                                {{ formatDate(turma.datatermino,2) }}
                            </td-->
                            <td style="text-align:center;">
                                {{ formatDate(turma.datainicioinscricao,2) }}
                            </td>
                            <td style="text-align:center;">
                                {{ formatDate(turma.dataterminoinscricao,2) }}
                            </td>
                            <td style="text-align:center;">
                                {{ (turma.tamanhoturmaximo>=0 ? turma.tamanhoturmaximo :'') }}
                            </td>
                            <!--td style="text-align:center;">
                                {{ turma.situacaoturma }}
                            </td-->
                            <td style="text-align:center;" >
                                <b style="color:red;">{{ (turma.valoroferta==0 ? 'Isento':$root.numberToReal(turma.valoroferta)) }}</b>
                            </td>
                            <td style="text-align:center;">
                                <b style="color:red;">{{ (turma.expirado==false ? 'Não':'Sim') }}</b>
                            </td>
                            <td style="text-align:center;">
                                <b style="color:red;">{{ (turma.existe_crm==false ? 'Não':'Sim') }}</b>
                            </td>
                            <td style="text-align:center;">
                                <b style="color:red;">{{ (turma.existe_erp ? 'Sim':'Não') }}</b>
                            </td>
                            <td style="text-align:center;">
                                <div class="dropdown" >
                                    <button class="btn btn-sm btn-primary dropdown-toggle" type="button" id="dropdownBotaoOpcoes" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Opções
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownBotaoOpcoes">
                                        <a  v-if="turma.expirado && turma.existe_crm" class="dropdown-item" href="#" @click.prevent="exportarOfertaTurmaRubeus(turma,processo_seletivo.curso_inscricao)" :disabled="visualizar">Expirar CRM</a>
                                        <a  v-if="turma.existe_erp && !turma.expirado" class="dropdown-item" href="#" @click.prevent="exportarOfertaTurmaRubeus(turma,processo_seletivo.curso_inscricao)" :disabled="visualizar">{{ (turma.existe_crm==false?'Exportar CRM':'Atualizar CRM') }}</a>
                                        <a v-if="1 > 0" class="dropdown-item" :href="'/Access/Unity/Unitie/'+unidade.id+'/captacao/'+turma.id">WebHooks</a>
                                        <a v-if="Array.isArray(turma.planosPagamento) && turma.planosPagamento.length > 0" class="dropdown-item" href="#" @click.prevent="verPlanoPagamento( turma.planosPagamento[0])">Plano de Pagamento</a>
                                    </div>
                                </div>
                                <!--button v-if="turma.expirado && turma.existe_crm" class="btn btn-sm btn-primary" @click.prevent="exportarOfertaTurmaRubeus(turma,processo_seletivo.curso_inscricao)">Expirar CRM</button>
                                <button v-if="turma.existe_erp && !turma.expirado" class="btn btn-sm btn-primary" @click.prevent="exportarOfertaTurmaRubeus(turma,processo_seletivo.curso_inscricao)">{{ (turma.existe_crm==false?'Exportar CRM':'Atualizar CRM') }}</button-->
                          
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colspan="16">
                                A opção <b style="color:red;">[Expirar CRM]</b> irá aparecer somente se a oferta estiver expirada ou não existir mais no ERP e existir no CRM
                            </th>
                        </tr>
                        <tr>
                            <th colspan="16">
                                A opção <b style="color:red;">[Atualizar CRM]</b> irá aparecer somente se a oferta existir no ERP e no CRM
                            </th>
                        </tr>
                        <tr>
                            <th colspan="16">
                                A opção <b style="color:red;">[Exportar CRM]</b> irá aparecer somente se a oferta existir no ERP e não existir no CRM
                            </th>
                        </tr>
                    </tfoot>
    
                </table>
            </div>
        </div>

        <modal-component titulo="Exportação CRM" label-btn-fechar="OK" id="ExportacaoCrmMsg" :sem-padding="true">
            <template v-slot:titulo>
                Exportação CRM
            </template>
            <template v-slot:conteudo>
                <div v-if="ofertaCursoTurma">
                    <table class="table table-sm table-hover" style="margin: 0px;">
                        <thead style="background:#1c4d5a!important;color:white;white-space: nowrap;">
                            <tr>
                                <th colspan="2" class="align-middle" style="text-align:center;border: 0px;">
                                    CURSO
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colspan="2" style="padding:0px;">
                                    <div class="alert alert-success" role="alert" style="margin:0px;text-align: center;">
                                        Turma Exportada para o CRM
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="text-align:left;">
                                    <b>Código:</b> {{ ofertaCursoTurma.codCurso }}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="text-align:left;">
                                    <b>Curso:</b> {{ ofertaCursoTurma.nomeCurso }}                                        
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="text-align:left;">
                                    <b>Descrição:</b> {{ ofertaCursoTurma.descricaoCurso }}                                        
                                </td>
                            </tr>
                            <tr>
                                <td style="text-align:left;">
                                    <b>Data Inicio:</b> {{ formatDate(ofertaCursoTurma.inicioCurso,2) }}
                                </td>
                                <td style="text-align:left;">
                                    <b>Data Término:</b> {{ formatDate(ofertaCursoTurma.terminoCurso,2) }}                                        
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="table table-sm table-hover" style="margin: 0px;">
                        <thead style="background:#1c4d5a!important;color:white;white-space: nowrap;">
                            <tr>
                                <th colspan="2" class="align-middle" style="text-align:center;border: 0px;">
                                    TURMA
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colspan="2" style="text-align:left;">
                                    <b>Oferta:</b> {{ ofertaCursoTurma.codOferta }}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="text-align:left;">
                                    <b>Descrição:</b> {{ ofertaCursoTurma.nome }}                                       
                                </td>
                            </tr>
                            <tr>
                                <td style="text-align:left;">
                                    <b>Data Inicio:</b> {{ formatDate(ofertaCursoTurma.inicioInscricao,2) }}
                                </td>
                                <td style="text-align:left;">
                                    <b>Data Término:</b> {{ formatDate(ofertaCursoTurma.terminoInscricao,2) }}                                        
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="table table-sm table-hover" style="margin: 0px;">
                        <thead style="background:#1c4d5a!important;color:white;white-space: nowrap;">
                            <tr>
                                <th colspan="2" class="align-middle" style="text-align:center;border: 0px;">
                                    MATRÍCULA
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style="text-align:left;">
                                    <b>Data Inicio:</b> {{ formatDate(ofertaCursoTurma.inicioMatricula,2) }}
                                </td>
                                <td style="text-align:left;">
                                    <b>Data Término:</b> {{ formatDate(ofertaCursoTurma.terminoMatricula,2) }}                                      
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="table table-sm table-hover" style="margin: 0px;">
                        <thead style="background:#1c4d5a!important;color:white;white-space: nowrap;">
                            <tr>
                                <th colspan="2" class="align-middle" style="text-align:center;border: 0px;">
                                    INSCRICAO
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style="text-align:left;">
                                    <b>Data Inicio:</b> {{ formatDate(ofertaCursoTurma.inicioInscricao,2) }}
                                </td>
                                <td style="text-align:left;">
                                    <b>Data Término:</b> {{ formatDate(ofertaCursoTurma.terminoInscricao,2) }}                                      
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
            </template>
        </modal-component>
        <modal-component @fecharmodal="resetarPlanoPagamento()" titulo="Plano de Pagamento" data-backdrop="static" label-btn-fechar="OK" id="MostraPlanoPagamento" :sem-padding="true" >
            <template v-slot:titulo>
                Plano de Pagamento
            </template>
            <template v-slot:conteudo>
                <div v-if="plano_pagamento_visualizar">
                    <table class="table table-sm table-hover" style="margin: 0px;">
                        <thead style="background:#1c4d5a!important;color:white;white-space: nowrap;">
                            <tr>
                                <th class="align-middle" style="text-align:center;border: 0px;">
                                    DETALHES DO PLANO DE PAGAMENTO
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <!--tr>
                                <td style="padding:0px;">
                                    <div class="alert alert-success" role="alert" style="margin:0px;text-align: center;">
                                        Plano de Pagamento da Oferta
                                    </div>
                                </td>
                            </tr-->
                            <tr>
                                <td style="text-align:left;">
                                    <b>Descrição:</b> {{ plano_pagamento_visualizar.descricaoPlanoPagamento }}                                        
                                </td>
                            </tr>
                            <tr>
                                <td style="text-align:left;">
                                    <b>Qtd Parcelas:</b> {{ plano_pagamento_visualizar.parcelas.length }}                                        
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="table table-sm table-hover" style="margin: 0px;" v-if="plano_pagamento_visualizar.parcelas.length>0">
                        <thead style="background:#1c4d5a!important;color:white;white-space: nowrap;">
                            <tr>
                                <th colspan="4" class="align-middle" style="text-align:center;border: 0px;">
                                    {{ (plano_pagamento_visualizar.quantidadeParcelas>1 ? "PARCELAS" : "PARCELA") }}
                                </th>
                            </tr>
                            <tr>
                                <th class="align-middle" style="text-align:center;border: 0px;">
                                    Tipo Parcela
                                </th>
                                <th class="align-middle" style="text-align:center;border: 0px;">
                                    Evento
                                </th>
                                <th class="align-middle" style="text-align:center;border: 0px;">
                                    Nº Parcela
                                </th>
                                <th class="align-middle" style="text-align:center;border: 0px;">
                                    Valor
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="ppg,item in plano_pagamento_visualizar.parcelas" :key="item">
                                <td style="text-align:center;">
                                    {{ ppg.descricaoTipoParcela }}
                                </td>
                                <td style="text-align:center;">
                                    {{ ppg.eventos[0].descricaoEvento }}
                                </td>
                                <td style="text-align:center;">
                                    {{ ppg.numeroParcela }}
                                </td>
                                <td style="text-align:center;">
                                    {{ $root.numberToReal(parseFloat(ppg.eventos[0].valor)) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                </div>
                
            </template>
        </modal-component>
        <loading id="exportarInscricoesWeb" titulo="Exportando Oferta para o CRM ..." :sem-padding="true" gif-carregando="/imagens/carregando.gif" @cancelaRequisicao="cancelarRequisicao()"></loading>
    </div>
</template>

<script>


export default{

    methods:{
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },
        countDownChangedDanger(dismissCountDownDanger) {
            this.dismissCountDownDanger = dismissCountDownDanger
        },
        showAlertDanger() {
            this.dismissCountDownDanger = this.dismissSecsDanger
        },
        dissmiss(id){
            this.$bvModal.hide(id);
        },
        novo(id){
            this.user = {}
            this.modoeditar = false
            this.modoexcluir = false
            this.modonovo = true
            this.texto_modal = "Novo"
            this.user.id=id
            // console.log(this.user)
        },
        editar(usuarioSelecionado){
            this.user = usuarioSelecionado;
            this.modoeditar = true
            this.modoexcluir = false
            this.modonovo = false
            this.texto_modal = "Editar"
            // console.log(this.user)
            // console.log(this.user)
        },
        excluir(usuarioSelecionado){
            this.user = usuarioSelecionado;
            this.modoeditar = false
            this.modoexcluir = true
            this.modonovo = false
            this.texto_modal = "Excluir"
            // console.log(this.user)
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.nameState = valid
            return valid
        },
        resetModal() {
            this.name = ''
            this.nameState = null
        },
        removerPorId: function (id) {
            var _vm = this; //Adicionando a instância à uma variável, para que possa ser acessada dentro do forEach`
            this.users.forEach(function(el, index) {
                if(el.id === id)
                    _vm.users.splice(index, 1);
            })
        },
        /*exportarPSE(){
            // console.log(this.processo_seletivo)
            var url= '/api/v1/Crm/exportarPSERubeus/' + this.unidade.id;
            // console.log(url)
            let formData = new FormData();
            let datas={
                datainicio:this.formatDate(this.processo_seletivo.datainicio),
                datatermino:this.formatDate(this.processo_seletivo.datatermino)
            }

            console.log(this.processo_seletivo)
            formData.append('dadosPse', JSON.stringify(this.processo_seletivo))
            formData.append('datasPse',JSON.stringify(datas));
            let configuracao = {
                // method:'post',
                // headers:{
                //     // // 'Accept': 'application/json',
                //     // // 'Authorization': this.$root.token(),
                // }
            }
            
            axios.post(url,formData,configuracao)
                .then(response => {
                    this.transacaoStatus = response.status
                    console.log(response.data)
                    // this.processo_seletivo = response.data.dadosOferta;
                    // this.errors={};
                    window.alert('Exportação realizada com sucesso.')
                    // $('#MensagemSistema').modal('show');
                })
                .catch(errors => {
                    this.errors = errors.response.data.errors
                    console.log(errors.response)
                })

        },*/
        resetarPlanoPagamento(){
            this.plano_pagamento_visualizar = null
            console.log(this.plano_pagamento_visualizar);
        },
        verPlanoPagamento( planosPagamento ){
            if(planosPagamento.length>0){
                this.plano_pagamento_visualizar = planosPagamento
                console.log('plano_pagamento_visualizar',this.plano_pagamento_visualizar)
                $('#MostraPlanoPagamento').modal('show')
            }
        },
        exportarOfertaTurmaRubeus(turma,curso){
            //console.log('processo',this.processo_seletivo)
            // console.log('Curso',curso)
            $('#exportarInscricoesWeb').modal('show');
            console.log('turma',turma)
            this.ofertaCursoTurma.idLiberacaoInscricaoCurso = this.processo_seletivo.idliberacaoinscricaocurso;
            this.ofertaCursoTurma.idLiberacaoInscricaoTurma = turma.turma_vinculo_inscricao.idliberacaoinscricaoturma;
            this.ofertaCursoTurma.ano = turma.anoinicio;
            this.ofertaCursoTurma.semestre = this.processo_seletivo.semestre;
            this.ofertaCursoTurma.idNivelEnsino = curso.codigonivelensino;
            this.ofertaCursoTurma.descricaoNivelEnsino = curso.descricaonivelensino
            this.ofertaCursoTurma.idEstruturaAcademica = turma.modality.idmodalidadeexportacao
            this.ofertaCursoTurma.descricaoEstruturaAcademica = turma.modality.modalidade
            this.ofertaCursoTurma.idOrigemMatricula = this.processo_seletivo.idorigemmatricula
            this.ofertaCursoTurma.descricaoOrigemMatricula = this.processo_seletivo.descricaoorigemmatricula
            this.ofertaCursoTurma.publico = this.processo_seletivo.publico
            this.ofertaCursoTurma.codCurso = curso.codigocurso
            this.ofertaCursoTurma.codOferta = turma.codigooferta
            this.ofertaCursoTurma.codigo = turma.codigooferta
            this.ofertaCursoTurma.nomeCurso = curso.descricaocurso
            this.ofertaCursoTurma.descricaoCurso = curso.descricaocurso
            this.ofertaCursoTurma.nome = turma.descricaoofertainscricao
            this.ofertaCursoTurma.complemento = turma.descricaocomplemento
            this.ofertaCursoTurma.codUnidade = this.unidade.companies.codigoempresa + '-' + this.unidade.codigounidade
            this.ofertaCursoTurma.inicioInscricao = turma.datainicioinscricao.substr(0, 10)
            this.ofertaCursoTurma.terminoInscricao = turma.dataterminoinscricao.substr(0, 10)
            this.ofertaCursoTurma.inicioMatricula = turma.datainicio
            this.ofertaCursoTurma.terminoMatricula = turma.datatermino
            this.ofertaCursoTurma.inicioCurso = turma.datainicio
            this.ofertaCursoTurma.terminoCurso = turma.datatermino
            this.ofertaCursoTurma.codNivelEnsino = curso.codigonivelensino;
            this.ofertaCursoTurma.modalidade = turma.modality.idmodalidadeexportacao;
            this.ofertaCursoTurma.codLocalOferta = this.unidade.companies.codigoempresa + '-' + this.unidade.codigounidade
            this.ofertaCursoTurma.origem = this.unidade.origem
            this.ofertaCursoTurma.valor = turma.valoroferta
            this.ofertaCursoTurma.plano_pagamento = ( turma.planosPagamento.length > 0 ? turma.planosPagamento[0] : null)
            this.ofertaCursoTurma.vagasMinimo = turma.tamanhoturmaminimo
            this.ofertaCursoTurma.vagasMaximo = turma.tamanhoturmaximo
            // console.log('aaa',this.turmasFiltradas)
            let url='/api/v1/Crm/cadastroOferta/'+this.unidade.id
            // console.log(url)
           
            let formData = new FormData();
            formData.append('dadosOferta',JSON.stringify(this.ofertaCursoTurma))

            console.log(this.ofertaCursoTurma)
            
            let configuracao = {
                // method:'post',
                // headers:{
                //     'Accept': 'application/json',
                //     'Authorization': this.tokenapirubeus,
                //     'Rubeus':1
                // }
            }

            axios.post(url,formData,configuracao)
                .then(response => {
                    console.log('oferta',response.data)

                    if(response.data.length> 0 && response.data[0].error){
                        // let msgerro = JSON.parse(response.data[0].message)
                        // window.alert('Resposta CRM: ' + msgerro.errors);

                        let erro_msg = JSON.parse(response.data[0].message);
                        this.msgErro = erro_msg.errors
                        console.log(this.msgErro)
                        this.success = false;
                        this.showAlertDanger();
                        // $('#exportarInscricoesWeb').modal('hide');
                        
                    }else{
                        console.log('turma',turma.descricaoofertainscricao)
                        this.success = true;
                        this.ofertaCursoTurma.nome = turma.descricaoofertainscricao
                        // $('#exportarInscricoesWeb').modal('hide');
                        $('#ExportacaoCrmMsg').modal('show');
                        this.showAlert();
                    }

                    $('#exportarInscricoesWeb').modal('hide');
                })
                .catch(errors => {
                    // this.errors = errors.response.data.errors
                    console.log('erro',errors)
                })

            
            console.log(this.ofertaCursoTurma)
            


        },
        formatDate(date,idioma) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            if(idioma==1){
                let dt = [year, month, day].join('-');
                if(dt=='1969-12-31'){
                    return null
                }else{
                    return dt;
                }
            }else if(idioma==2){
                let dt = [day, month, year].join('/');
                if(dt=='31/12/1969'){
                    return null
                }else{
                    return dt;
                }
            }else{
                let dt = [year, month, day].join('-');
                if(dt=='1969-12-31'){
                    return null
                }else{
                    return dt;
                }
            }
        },
    },
    mounted(){
        // this.$root.atualizaCookie('token');
    },
    created(){

        if(this.processo){
            this.processo_seletivo = this.processo;
            var dataIni = new Date(this.processo_seletivo.datainicio);
            var dataTer = new Date(this.processo_seletivo.datatermino);
            this.processo_seletivo.datainicio = dataIni;
            this.processo_seletivo.datatermino = dataTer;
            console.log(this.processo_seletivo)
            this.users = this.usuarios;
            this.doc=this.processo.cnpj;
            
            if(this.processo.responsavel_id>0){
                this.responsavel_id=this.processo.responsavel_id
            }

            
        }else{
            this.processo_seletivo.CadastroTipo=2;
            this.tipoCadastro=2;
            this.processo_seletivo.estado="0";
        }
    },
    props:{
        unidade:{
            type:Object,
            required:true
        },
        urlSistema:{
            type:String,
            required:true
        },
        visualizar:{
            type:Boolean,
            required:true
        },
        nomeFormulario:{
            type:String,
            required:true
        },
        metodoFormulario:{
            type:String,
            required:true
        },
        linkAction:{
            type:String,
            required:true
        },
        processo:{
            type:Object,
            required:false
        },
        usuarioAutenticado:{
            type:Object,
            required:false
        },
        usuarios:[],
        edit:{
            type:Boolean,
            required:true
        }
    },
    watch: {
    // whenever question changes, this function will run
        texto_modal(newTexto, oldTtexto) {
            if (newTexto!=oldTtexto) {
                this.texto_modal=newTexto;
            }
        }
        ,tipoCadastro(newTipoCadastro, oldTipoCadastro) {
            if (newTipoCadastro!=oldTipoCadastro) {
                this.processo_seletivo.CadastroTipo=newTipoCadastro;
                this.tipoCadastro=newTipoCadastro;
                this.errors={};
            }
        },
        doc(newdoc, olddoc) {
            if (newdoc!=olddoc) {
                this.processo_seletivo.cnpj=newdoc;
                this.doc=newdoc;
            }
        },
    },
    data(){
        return{
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                suffix: '',
                precision: 2,
                masked: false
            },
            dismissSecs: 7,
            dismissCountDown: 0,
            dismissSecsDanger: 7,
            dismissCountDownDanger: 0,
            transacaoStatus:null,
            modonovo:false,
            modoeditar:false,
            modoexcluir:false,
            Novo:false,
            texto_modal:'',
            nameState: null,
            senhaState:null,
            emailState:null,
            submittedNames: [],
            tipoCadastro:null,
            doc:null,
            processo_seletivo:{
                id:null,
                nomecompleto:null,
                nomereduzido:null,
                cnpj:null,
                logradouro:null,
                numero:null,
                complemento:null,
                cep:null,
                pais:null,
                estado:null,
                cidade:null,
                bairro:null,
                site:null,
                email:null,
                tipocurso:null,
                celular:null,
                codigoempresa:null,
                /**
                 * cpf = 1
                 * cnpj = 2
                 */
                CadastroTipo:0,
                // link_integracao_gvdasa:null,
                // token_api_gvdasa:null,
                // link_integracao_rubeus:null,
                // token_api_rubeus:null,
                // origem:null,

            },
            plano_pagamento_visualizar:null,
            ofertaCursoTurma:{
                ano:null,
                semestre:null,
                idLiberacaoInscricaoCurso:null,
                idNivelEnsino:null,
                descricaoNivelEnsino:null,
                idEstruturaAcademica:null,
                descricaoEstruturaAcademica:null,
                idOrigemMatricula:null,
                descricaoOrigemMatricula:null,
                idLiberacaoInscricaoTurma:null,
                publico:null,
                codCurso:null,
                codOferta:null,
                codigo:null,
                nomeCurso:null,
                descricaoCurso:null,
                nome:null,
                complemento:null,
                codUnidade:null,
                inicioInscricao:null,
                terminoInscricao:null,
                inicioMatricula:null,
                terminoMatricula:null,
                inicioCurso:null,
                terminoCurso:null,
                codNivelEnsino:2,
                modalidade:1,
                codLocalOferta:null,
                origem:null,
                plano_pagamento:null,
                valor:null,
                vagasMinimo:null,
                vagasMaximo:null

            },
            user:{
                id:null,
                name:null,
                email:null,
                senha:null,
            },
            responsavel_id:0,
            users:[],
            errors:{},
            empresa_exportada_crm:[{
                id:null,
                codigo:null,
                descricao:null,
                nome:null,
                oridem:null
            }],
            msgErro:null,
            success:null
        }
    },
}
</script>

<style scoped>
        
    form{
        text-align: left!important;
    }

    .div-crud-sistema{
        background: white;
        padding: 15px;
        border-radius: 0px 0px 10px 10px;

    }

    .div-table-sistema{
        background: white;
        padding: 15px;
        border-radius: 10px;
        margin-top:15px;

    }

    .table-crud-sistema{
        background: white;
        border-radius: 0px 0px 10px 10px;
        overflow-x: auto;
    }

</style>
