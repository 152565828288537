<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <content-component :padrao-exibicao="true" :id="'totalizadodes_'+unidade.id" style="margin-bottom: 0px;padding-left:10px;padding-bottom: 20px;padding-right: 10px;-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;" estilo="height:12px;text-align:left;" estilo-body="padding:0px;overflow-x: hidden;">
                            <template v-slot:cabecalho>
                                <b>Totalizadores de WebHooks por status de Inscrição</b>
                            </template>
                            <template v-slot:conteudo>
                                <div class="row">
                                    <div class="col-md-12 text-center">
                                        <small style="font-size:8pt;color:red;font-weight:bolder;">(Clique nos cards do dashboart abaixo para filtrar os WebHooks por Status)</small>
                                    </div>
                                </div>
                                <div class="row" style="padding:10px!important;width: auto;">
                                    <div class="col-md-3" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                                        <div class="alert alert-warning  text-center" role="alert" style="height: auto;cursor: pointer;" @click.prevent="filtraWebHooksStatus('sem_processamento')">
                                            <h6 style="white-space:nowrap;font-weight: bold;">WebHooks</h6>
                                            <hr>
                                            <h6 class="alert-heading"><b-icon icon="speedometer2" font-scale="1.4"></b-icon><br><div style="padding-top: 10px;font-weight: bolder;">{{ SemProcessamentoTotal }}</div></h6>
                                            <hr>
                                            <h6 style="white-space:nowrap;font-weight: bold;">Aguardando</h6>
                                        </div>
                                    </div>
                                    <div class="col-md-3" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                                        <div class="alert alert-primary text-center" role="alert" style="height: auto;cursor: pointer;" @click.prevent="filtraWebHooksStatus('pending')">
                                            <h6 style="white-space:nowrap;font-weight: bold;">WebHooks</h6>
                                            <hr>
                                            <h6 class="alert-heading"><b-icon icon="speedometer2" font-scale="1.4"></b-icon><br><div style="padding-top: 10px;font-weight: bolder;">{{ PendingTotal }}</div></h6>
                                            <hr>
                                            <h6 style="white-space:nowrap;font-weight: bold;">Pendente</h6>
                                        </div>
                                    </div>
                                    <div class="col-md-3" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                                        <div class="alert alert-danger text-center" role="alert" style="height: auto;cursor: pointer;" @click.prevent="filtraWebHooksStatus('error')">
                                            <h6 style="white-space:nowrap;font-weight: bold;">WebHooks</h6>
                                            <hr>
                                            <h6 class="alert-heading"><b-icon icon="speedometer2" font-scale="1.4"></b-icon><br><div style="padding-top: 10px;font-weight: bolder;">{{ ErrorTotal }}</div></h6>
                                            <hr>
                                            <h6 style="white-space:nowrap;font-weight: bold;">Erro</h6>
                                        </div>
                                    </div>
                                    <div class="col-md-3" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                                        <div class="alert alert-success text-center" role="alert" style="height: auto;cursor: pointer;" @click.prevent="filtraWebHooksStatus('success')">
                                            <h6 style="white-space:nowrap;font-weight: bold;">WebHooks</h6>
                                            <hr>
                                            <h6 class="alert-heading"><b-icon icon="speedometer2" font-scale="1.4"></b-icon><br><div style="padding-top: 10px;font-weight: bolder;">{{ SuccessTotal }}</div></h6>
                                            <hr>
                                            <h6 style="white-space:nowrap;font-weight: bold;">Sucesso</h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" style="padding:10px!important;width: auto;">
                                    <div class="col-md-12">
                                        <content-component :padrao-exibicao="false" id="listagemWebHooks" style="margin-bottom: 15px;padding-left: 10px;padding-right: 10px;" estilo="height:12px;text-align:left;">
                                            <template v-slot:cabecalho>
                                                <b>Lista de WebHooks - Status: </b> {{ status_processamento }}
                                            </template>
                                            <template v-slot:conteudo>
                                                <table class="table table-hover table-sm" style="white-space: normal;border-top: 0px;width:100%;">
                                                    <thead>
                                                        <tr style="background-color: #1c4d5a;color:white;">
                                                            <th style="text-align: center;">
                                                                Ações
                                                            </th>
                                                            <th style="text-align: center;">
                                                                ID
                                                            </th>
                                                            <th style="text-align: center;width: auto;">
                                                                Notificações
                                                            </th>
                                                            <th style="text-align: center;">
                                                                Nome
                                                            </th>
                                                            <!--th style="text-align: center;max-width: 120px;width: 120px;">
                                                                Curso
                                                            </th-->
                                                            <th colspan="2" style="text-align: center;max-width: 350px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">
                                                                Turma ERP
                                                            </th>
                                                            
                                                            <!-- <th style="text-align: center;text-overflow: ellipsis;">
                                                                Req. ERP
                                                            </th> -->
                                                            <th style="text-align: center;text-overflow: ellipsis;width:10px;overflow: hidden;white-space: nowrap;">
                                                                ID CRM
                                                            </th>
                                                            <!-- <th style="text-align: center;width: auto;">
                                                                Reg. CRM
                                                            </th> -->
                                                            <!-- <th style="text-align: center;text-overflow: ellipsis;">
                                                                Etapas
                                                            </th> -->
                                                            <th style="text-align: center;width: 45px;">
                                                                Sinc.
                                                            </th>
                                                            <th style="text-align: center;width: 100px;">
                                                                Data
                                                            </th>
                                                            <th style="text-align: center;width: 100px;">
                                                                Atualização
                                                            </th>
                                                            <!-- <th style="text-align: center;">
                                                                Status Req.
                                                            </th> -->
                                                            <th style="text-align: center;width: 100px;">
                                                                Status
                                                            </th>
                                                            <th style="text-align: center;width: 100px;">
                                                                Origem
                                                            </th>
                                                            <th style="text-align: center;width: 100px;">
                                                                Tipo
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody v-if="web_hooks.data && web_hooks.data.length>0">
                                                        <template v-for="aluno_captado,item in web_hooks.data" >
                                                            <!-- <tr v-if="aluno_captado.tipoeventoaviso=='c'" :key="item"> -->
                                                            <tr :key="item">
                                                                <td style="text-align: center;white-space: nowrap;width:180px!important;max-width:200px!important;" class="align-middle">
                                                                    <a v-if="aluno_captado.turma_deleted_at==null" :href="'/Access/Unity/Unitie/'+unidade.id+'/captacao/wh/'+aluno_captado.id" class="btn btn-primary btn-sm" style="width: 40px;"><i class="fa-sharp fa-solid fa-eye fa-xl"></i></a>
                                                                    <a v-else :href="'/Access/Unity/Unitie/'+unidade.id+'/captacao/wh/'+aluno_captado.id" class="btn btn-primary btn-sm disabled" style="width: 40px;"><i class="fa-sharp fa-solid fa-eye fa-xl"></i></a>
                                                                    <button v-if="aluno_captado.processado==1 && aluno_captado.turma_deleted_at==null" class="btn btn-primary btn-sm" style="width: 40px;" @click.prevent="modalReprocessamentoWebHook(aluno_captado)"><i class="fa-solid fa-arrows-rotate fa-rotate-90"></i></button>
                                                                    <button v-else class="btn btn-primary btn-sm" style="width: 40px;" disabled><i class="fa-solid fa-arrows-rotate fa-rotate-90"></i></button>
                                                                    <button v-if="aluno_captado.processado==0 && aluno_captado.tipoeventoaviso=='c' &&aluno_captado.enviado_job==0 && aluno_captado.processado==0 && aluno_captado.turma_deleted_at==null" class="btn btn-primary btn-sm" style="width: 40px;" @click.prevent="enviaParaFilaTrabalhos(aluno_captado)"><i class="fa-regular fa-paper-plane"></i></button>
                                                                    <button v-else class="btn btn-primary btn-sm" style="width: 40px;" disabled><i class="fa-regular fa-paper-plane"></i></button>
                                                                    <button v-if="usuarioLogado.perfil_usuario==1" class="btn btn-primary btn-sm" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;"><b-icon icon="trash-fill" font-scale="1.3" @click.prevent="MensagemExcluirWebHook(aluno_captado)"></b-icon></button>
                                                                </td>
                                                                <td class="align-middle" style="text-align: center;font-weight: bold;">
                                                                    {{ aluno_captado.id }}
                                                                </td>
                                                                <td class="align-middle" style="text-align: center;font-weight: bold;">
                                                                    <a :href="'/Access/Unity/Unitie/'+unidade.id+'/notificacoes/webhook/'+aluno_captado.id" class="btn btn-sm btn-primary" target="_blank">
                                                                        Notas <span :class="'badge badge-'+(aluno_captado.qtd_notificacoes_nao_lidas>0 ? 'danger' : 'primary')">&nbsp;{{ aluno_captado.qtd_notificacoes_nao_lidas }}&nbsp;</span>
                                                                    </a>
                                                                </td>
                                                                <td class="align-middle" style="text-align: left;font-weight: bold;text-overflow: ellipsis;max-width:120px;overflow: hidden;white-space: nowrap;">
                                                                    {{ aluno_captado.nomeinscrito }}
                                                                </td>
                                                                
                                                                <td class="align-middle" style="text-align: left;font-weight: bold;text-overflow: ellipsis;max-width:200px;overflow: hidden;white-space: nowrap;">
                                                                    {{ aluno_captado.descricaocompleta }}
                                                                </td>
                                                                <td style="white-space: normal;text-align: center;" class="align-middle">
                                                                    <b-icon v-if="aluno_captado.turma_deleted_at==null" icon="check-lg" font-scale="1.3" style="color:green"></b-icon>
                                                                    <b-icon v-else icon="x-lg" font-scale="1.3" style="color:red"></b-icon>
                                                                </td>
                                                                <!-- <td class="align-middle" style="text-align: center;font-weight: bold;">
                                                                    {{aluno_captado.requisicao_id}}
                                                                </td> -->
                                                                <td class="align-middle" style="text-align: center;font-weight: bold;">
                                                                    {{ aluno_captado.contatoid }}
                                                                </td>
                                                                <!-- <td class="align-middle" style="text-align: center;font-weight: bold;">
                                                                    {{ aluno_captado.registroprocesso }}
                                                                </td> -->
                                                                <td style="white-space: normal;text-align: center;" class="align-middle">
                                                                    <b-icon v-if="aluno_captado.processado == 1" icon="check-lg" font-scale="1.3" style="color:green"></b-icon>
                                                                    <b-icon v-else icon="x-lg" font-scale="1.3" style="color:red"></b-icon>
                                                                </td>
                                                                <td style="text-align: center;white-space: nowrap;" class="align-middle">
                                                                    {{  daraHoraBrasileiro(aluno_captado.created_at) }}
                                                                </td>
                                                                <td style="text-align: center;white-space: nowrap;" class="align-middle">
                                                                    {{  daraHoraBrasileiro(aluno_captado.updated_at) }}
                                                                </td>
                                                                <!-- <td :class="'align-middle ' + getEstyloStatusRequisicao(aluno_captado)" style=";white-space: nowrap;padding:5px!important;font-size: 11px!important;text-align: center; width: auto!important;border-radius: 0px;">
                                                                    <b>{{ getEstyloDescricaoStatusRequisicao(aluno_captado) }}</b>
                                                                </td> -->
                                                                <td :class="'align-middle ' + getEstyloStatusInscricao(aluno_captado)" style=";white-space: nowrap;padding:5px!important;font-size: 11px!important;text-align: center; width: auto!important;border-radius: 0px;">
                                                                    <b>{{ getEstyloDescricaoStatusRequisicao((aluno_captado)) }}</b>
                                                                </td>
                                                                <td style="white-space: normal;text-align: center;" class="align-middle">
                                                                    <b>{{ aluno_captado.origem_inclusao }}</b>
                                                                </td>
                                                                <td style="text-align: left;font-weight: bold;text-overflow: ellipsis;max-width:140px;overflow: hidden;white-space: nowrap;" class="align-middle">
                                                                    <b>{{ ( aluno_captado.tipoeventoaviso=='c' ? "Insc. Confirmada" : ( aluno_captado.tipoeventoaviso=='a' ? "Contrato Assinado" : ( aluno_captado.tipoeventoaviso=='m' ? "Matriculado":( aluno_captado.tipoeventoaviso=='v' ? "Envia Valor CRM":( aluno_captado.tipoeventoaviso=='f' ? "Atualização Cadastral":( aluno_captado.tipoeventoaviso=='d' ? "Documentos":"Outros" ) ) ) ) ) ) }}</b>
                                                                </td>
                                                            </tr>
                        
                                                        </template>
                                                    </tbody>
                                                    <tbody v-else>
                                                        <tr>
                                                            <td colspan="10" style="text-align: center;font-weight: bold;">
                                                                Não existem dados para exibir
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td colspan="13" style="text-align: center;background-color: #1c4d5a;color:white;" class="align-middle">
                                                                <paginate class="padding text-center">
                                                                    <li v-for=" link, key in web_hooks.links" :key="key" :class="(link.active ? 'page-item active':(link.url ? 'page-item':'page-item disabled'))" style="cursor:pointer;">
                                                                        <a class="page-link" tabindex="-1" @click="paginacaoConfirmados(link)" style="border-color: #fff!important;">{{ (key==0 ? "<<" :(key==web_hooks.links.length-1 ? ">>": link.label ) ) }}        </a>
                                                                    </li>
                                                                </paginate>
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </template>
                                        </content-component>
                                    </div>
                                </div>
                            </template>
                </content-component>

            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <content-component :padrao-exibicao="false" :id="'totalizadodes_inscricao_'+unidade.id" style="margin-bottom: 15px;padding-left: 10px;padding-right: 10px;" estilo="height:12px;text-align:left;" estilo-body="padding:0px;overflow-x: hidden;">
                    <template v-slot:cabecalho>
                        <b>Totalizadores de Processos Inscrição Web</b>
                    </template>
                    <template v-slot:conteudo>
                        <div class="row" style="padding:10px!important;width: auto;-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;" >
                            <div class="col-md-4" v-if="unidade.oferta_Inscricao_web==1">
                                <div class="alert alert-success text-center" role="alert" style="height: 150px;cursor: pointer;" @click="OfertaInscricaoWeb()">
                                    <h6 class="alert-heading"><b-icon icon="speedometer2" font-scale="1.7"></b-icon><br><div style="padding-top: 10px;font-weight: bolder;">{{ insc.length }}</div></h6>
                                    <hr>
                                    <h6>Processos de Inscrição Web</h6>
                                </div>
                            </div>
                            <div class="col-md-8" v-if="unidade.oferta_Inscricao_web==1">
                                <Bar
                                id="my-chart-id"
                                :options="chartOptionsInsc"
                                :data="chartDataInsc"
                                />
                            </div>
                        </div>
                        <div class="row" style="padding:10px!important;width: auto;-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                            <div class="col-md-4" v-if="unidade.oferta_Inscricao_web==1">
                                <div class="alert alert-success text-center" role="alert" style="height: 150px;cursor: pointer;" @click="OfertaInscricaoWeb()">
                                    <h6 class="alert-heading"><b-icon icon="speedometer2" font-scale="1.7"></b-icon><br><div style="padding-top: 10px;font-weight: bolder;">{{ turmasInsc.length }}</div></h6>
                                    <hr>
                                    <h6>Ofertas de Inscrição Web</h6>
                                </div>
                            </div>
                            <div class="col-md-8" v-if="unidade.oferta_Inscricao_web==1">
                                <Bar
                                id="my-chart-id"
                                :options="chartOptionsTurmaInsc"
                                :data="chartDataTurmaInsc"
                                />
                            </div>
                        </div>
                
                        <div class="row" style="width: auto;-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;" v-if="unidade.oferta_processo_seletivo==1">
                            <div class="col-md-12 text-center">
                                <h4>Totalizadores de Processos Seletivos</h4>
                            </div>
                        </div>
                        <div class="row" style="padding:10px!important;width: auto;-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                            <div class="col-md-4" v-if="unidade.oferta_processo_seletivo==1">
                                <div class="alert alert-success text-center" role="alert" style=";height: 150px;cursor: pointer;" @click="OfertaProcessoSeletivo()">
                                    <h6 class="alert-heading"><b-icon icon="speedometer2" font-scale="1.7"></b-icon><br><div style="padding-top: 10px;font-weight: bolder;">{{ pses.length }}</div></h6>
                                    <hr>
                                    <h6>Processos Seletivos Web</h6>
                                </div>
                            </div>
                            <div class="col-md-8" v-if="unidade.oferta_processo_seletivo==1">
                                <Bar
                                id="my-chart-id"
                                :options="chartOptionsPse"
                                :data="chartDataPse"
                                Title="Total de Processos Seletivos por Ano"
                                />
                            </div>
                        </div>
                
                        <div class="row" style="padding:10px!important;width: auto;-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                            <div class="col-md-4" v-if="unidade.oferta_processo_seletivo==1">
                                <div class="alert alert-success text-center" role="alert" style="height: 150px;cursor: pointer;" @click="OfertaProcessoSeletivo()">
                                    <h6 class="alert-heading"><b-icon icon="speedometer2" font-scale="1.7"></b-icon><br><div style="padding-top: 10px;font-weight: bolder;">{{ turmasPse.length }}</div></h6>
                                    <hr>
                                    <h6>Ofertas de Processos Seletivos</h6>
                                </div>
                            </div>
                            <div class="col-md-8" v-if="unidade.oferta_processo_seletivo==1">
                                <Bar
                                id="my-chart-id"
                                :options="chartOptionsTurmaPse"
                                :data="chartDataTurmaPse"
                                />
                            </div>
                        </div>        
                    </template>
                </content-component>
            </div>
        </div>
        <loading :id="'EnviaWebHookProcessamento'+unidade.id" titulo="Enviando WebHook para reprocessamento, Aguarde..." :sem-padding="true" gif-carregando="/imagens/carregando.gif"></loading>
        <loading :id="'EnviaWebHookProcessamentoJob'+this.unidade.id" titulo="Enviando WebHook para fila de Jobs, Aguarde..." :sem-padding="true" gif-carregando="/imagens/carregando.gif"></loading>
        <loading :id="'WebHooks'+unidade.id" titulo="Solicitando os dados de WebHooks, Aguarde..." :sem-padding="true" gif-carregando="/imagens/carregando.gif"></loading>
        <loading :id="'ExcluindoWebHooks'+unidade.id" titulo="Excluindo WebHook, Aguarde..." :sem-padding="true" gif-carregando="/imagens/carregando.gif"></loading>
    </div>
    
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'


ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
export default {
    name: 'BarChart',
    components: { Bar },
    props:['unidade','usuarioLogado','insc','pses','totalPse','totalInsc','turmasPse','turmasInsc','totalTurmasPse','totalTurmasInsc','totalPending','totalError','totalSuccess','totalSemProcessamento','mostrarLoading'],
    data(){
        return{
            show_loading:0,
            exibe_tabela:false,
            status_processamento:null,
            url_requisicao:'/api/v1/Crm/getWebHookConfirmadosUnityStatus/',
            chartOptionsPse: {
                responsive: true,
                maintainAspectRatio: false,
            },
            chartOptionsInsc: {
                responsive: true,
                maintainAspectRatio: false,
            },
            chartOptionsTurmaInsc: {
                responsive: true,
                maintainAspectRatio: false,
            },
            chartOptionsTurmaPse: {
                responsive: true,
                maintainAspectRatio: false,
            },
            web_hooks:{data:[]},
            PendingTotal:null,
            ErrorTotal:null,
            SuccessTotal:null,
            SemProcessamentoTotal:null
        }
    },
    computed:{
        colMd(){
            // return (12 / (this.unidade.oferta_Inscricao_web + this.unidade.oferta_processo_seletivo + 1));
            return (12 / 3);
        },
        chartDataPse(){
            
            var chartDataPse = {
                                labels: [],
                                datasets: [ { data: [],label:"Ano",backgroundColor: '#d7f3e3', } ]
                            }
            
            for(let i = 0; i < this.totalPse.length; i++){
                
                chartDataPse.labels.push(this.totalPse[i].ano);
                chartDataPse.datasets[0].data.push(this.totalPse[i].qtd);
                
            }
            console.log(chartDataPse);
            return chartDataPse
        },
        chartDataInsc(){
            
            var chartDataInsc = {
                                labels: [],
                                datasets: [ { data: [],label:"Ano",backgroundColor: '#d7f3e3', } ]
                            }
            
            for(let i = 0; i < this.totalInsc.length; i++){
                
                chartDataInsc.labels.push(this.totalInsc[i].ano);
                chartDataInsc.datasets[0].data.push(this.totalInsc[i].qtd);
                
            }
            console.log(chartDataInsc);
            return chartDataInsc
        },
        chartDataTurmaInsc(){
            
            var chartDataTurmaInsc = {
                                labels: [],
                                datasets: [ { data: [],label:"Ano",backgroundColor: '#d7f3e3', } ]
                            }
            
            for(let i = 0; i < this.totalTurmasInsc.length; i++){
                
                chartDataTurmaInsc.labels.push(this.totalTurmasInsc[i].anoinicio);
                chartDataTurmaInsc.datasets[0].data.push(this.totalTurmasInsc[i].qtd);
                
            }
            console.log(chartDataTurmaInsc);
            return chartDataTurmaInsc
        },
        chartDataTurmaPse(){
            
            var chartDataTurmaPse = {
                                labels: [],
                                datasets: [ { data: [],label:"Ano",backgroundColor: '#d7f3e3', } ]
                            }
            
            for(let i = 0; i < this.totalTurmasPse.length; i++){
                
                chartDataTurmaPse.labels.push(this.totalTurmasPse[i].anoinicio);
                chartDataTurmaPse.datasets[0].data.push(this.totalTurmasPse[i].qtd);
                
            }
            console.log(chartDataTurmaPse);
            return chartDataTurmaPse
        }
        
    },
    created(){
        this.PendingTotal = this.totalPending.total;
        this.ErrorTotal = this.totalError.total;
        this.SuccessTotal = this.totalSuccess.total;
        this.SemProcessamentoTotal = this.totalSemProcessamento.total;    
        console.log('this.totalSemProcessamento',this.totalSemProcessamento.total) 
        this.show_loading = this.mostrarLoading;
    },
    mounted() {
        console.log('totalPse',this.totalPse)
        console.log('totalInsc',this.totalInsc)
        this.filtraWebHooksStatus('sem_processamento');
        
    },
    watch:{
        
    },
    methods:{
        enviaParaFilaTrabalhos(web_hook){
            var resposta = confirm("Você realmente deseja enviar este WebHook para a fila de Jobs?\nSe sim, após clicar no OK aguarde outra Mensagem Informando o Sucesso do Envio para Fila de Jobs");

            if(resposta == true){
                this.sendWebhookJob(web_hook);
            }
        },
        modalReprocessamentoWebHook(web_hook){
            var resposta = confirm("Você realmente deseja enviar este WebHook para reprocessamento?");

            if(resposta == true){
                this.reprocessWebhook(web_hook);
                //window.alert("WebHook Enviado para Reprocessamento com sucesso")
            }
        },
        sendWebhookJob(web_hook){
                $('#EnviaWebHookProcessamentoJob'+this.unidade.id).modal('show');
                let url='/api/v1/Crm/Unity/'+this.unidade.id+'/sendWebHookJob/webhook'                             
                let formData = new FormData();
                formData.append('_method','post');
                formData.append('web_hook', web_hook.id)
                let configuracao = {
                    // method:'put',
                    // headers:{
                    // //     'Accept': 'application/json',
                    // //     'Authorization': this.$root.token()
                    // },
                }
                
                axios.post(url, formData,configuracao)
                    .then(response => {
                        console.log('response.data.webhook',response.data)

                        if(response.data.erro==false){
                            web_hook.enviado_job = 1;
                            $('#EnviaWebHookProcessamentoJob'+this.unidade.id).modal('hide');
                            window.alert(response.data.mensagem)
                        }
                        
                    })
                    .catch(errors => {
                        this.errors = errors.response.data.errors
                        console.log(errors.response.data)
                        $('#EnviaWebHookProcessamentoJob'+this.unidade.id).modal('hide');
                        window.alert('houve um erro ao enviar o webhook para reprocessamento')

                    })

                    
                    
            // }
        },
        reprocessWebhook(web_hook){
                $('#EnviaWebHookProcessamento'+this.unidade.id).modal('show');
                let url='/api/v1/Crm/Unity/'+this.unidade.id+'/resend/webhook'                             
                let formData = new FormData();
                formData.append('_method','post');
                formData.append('web_hook', web_hook.id)
                let configuracao = {
                    // method:'put',
                    // headers:{
                    // //     'Accept': 'application/json',
                    // //     'Authorization': this.$root.token()
                    // },
                }
                
                axios.post(url, formData,configuracao)
                    .then(response => {
                        console.log(response.data)

                        if(response.data.erro==false){
                            this.SemProcessamentoTotal = response.data.total
                            this.getWebHookConfirmadosUnityStatus();
                            window.alert("WebHook Enviado para Reprocessamento com sucesso!")
                        }
                        
                        $('#EnviaWebHookProcessamento'+this.unidade.id).modal('hide');
                    })
                    .catch(errors => {
                        this.errors = errors.response.data.errors
                        console.log(errors.response.data)
                        window.alert('houve um erro ao enviar o webhook para reprocessamento')
                        $('#EnviaWebHookProcessamento'+this.unidade.id).modal('hide');

                    })

                    
                    
            // }
        },
        MensagemExcluirWebHook(web_hook){
            var resposta = confirm("Você realmente deseja Excluir este WebHook?\nSe sim, após clicar no OK aguarde outra Mensagem Informando o Sucesso da Exclusão do WebHook");

            if(resposta == true){
                this.excluirWebHook(web_hook);
            }
        },
        excluirWebHook(web_hook){
            
            $('#ExcluindoWebHooks'+this.unidade.id).modal('show');
            console.log('id',web_hook.id)
            var config = {
            method: 'delete',
            url: '/api/v1/Crm/webhook/'+web_hook.id,
            // headers: {
            //         'Accept': 'application/json',
            //         'Authorization': this.$root.token(),
            //     },
            };
            //executa requisição
            axios(config)
            .then(response => {
                console.log(response);
                if(response.data.error==false){
                    
                   window.alert(response.data.mensagem)
                }else{
                    window.alert(response.data.mensagem)
                }
                $('#ExcluindoWebHooks'+this.unidade.id).modal('hide');
            })
            .catch(function (error) {
                window.alert(error)
                $('#ExcluindoWebHooks'+this.unidade.id).modal('hide');
            });

            this.filtrarAposExcluir()
        },
        getEstyloStatusInscricao(web_hook){
            if(web_hook.processado == 1 && ( ( (web_hook.status_inscricao=='success' || web_hook.status_post_contrato =='success' || web_hook.status_valormatricula=='success') && web_hook.requisicao_id>0 ) || (web_hook.status_atualizacao_cadastral=='success'))){
                return 'alert alert-success text-center'
            }else if (web_hook.processado == 1 && web_hook.status_inscricao=='error' && web_hook.requisicao_id>0){
                return 'alert alert-danger text-center'
            }else if(web_hook.processado==1 && web_hook.status_inscricao=='pending'){
                return 'alert alert-primary text-center'
            }
        },
        getEstyloStatusRequisicao(web_hook){
            var status = (web_hook.tipoeventoaviso=='c' && web_hook.status_inscricao!=null ?
                    web_hook.status_inscricao : (web_hook.tipoeventoaviso=='a' && web_hook.status_post_contrato!=null ? 
                        web_hook.status_post_contrato  : (web_hook.tipoeventoaviso=='a' && web_hook.status_inscricao!=null ? 
                            web_hook.status_inscricao : (web_hook.tipoeventoaviso=='m' && web_hook.status_inscricao!=null ? 
                                web_hook.status_inscricao : (web_hook.tipoeventoaviso=='v' && web_hook.status_valormatricula!=null ? 
                                    web_hook.status_valormatricula : (web_hook.tipoeventoaviso=='f' && web_hook.status_atualizacao_cadastral!=null ? 
                                        web_hook.status_atualizacao_cadastral : web_hook.status
                                        
                                    )
                                )
                            )
                        )
                    )
                )
            console.log('getEstyloStatusRequisicao',status,web_hook.id,web_hook.tipoeventoaviso)
            if((status=='success' && web_hook.requisicao_id>0) || (status=='success' && web_hook.tipoeventoaviso=='f')){
                
                return 'alert alert-success text-center'
            }else if (status=='error' && web_hook.requisicao_id>0){
                return 'alert alert-danger text-center'
            }else if(web_hook.processado==1 && status!='success' && status!='Não Foi possível obter dados da Oportunidade no CRM.'){
                return 'alert alert-primary text-center'
            }else if(web_hook.processado==0 && status=='Reprocessar WebHook'){
                return 'alert alert-warning text-center text-danger bg-warning'
            }else if(web_hook.processado==1 && status=='Não Foi possível obter dados da Oportunidade no CRM.'){
                
                return 'alert alert-danger text-center'
            }
        },
        getEstyloDescricaoStatusRequisicao(aluno_captado){
            var status = (aluno_captado.tipoeventoaviso=='c' && aluno_captado.status_inscricao!=null ?
                    aluno_captado.status_inscricao : (aluno_captado.tipoeventoaviso=='a' && aluno_captado.status_post_contrato!=null ? 
                        aluno_captado.status_post_contrato  : (aluno_captado.tipoeventoaviso=='a' && aluno_captado.status_inscricao!=null ? 
                            aluno_captado.status_inscricao : (aluno_captado.tipoeventoaviso=='m' && aluno_captado.status_inscricao!=null ? 
                                aluno_captado.status_inscricao : (aluno_captado.tipoeventoaviso=='v' && aluno_captado.status_valormatricula!=null ? 
                                    aluno_captado.status_valormatricula : (aluno_captado.tipoeventoaviso=='f' && aluno_captado.status_atualizacao_cadastral!=null ? 
                                        aluno_captado.status_atualizacao_cadastral : aluno_captado.status
                                        
                                    )
                                )
                            )
                        )
                    )
                )
            if(status==null || status==''){
                return '';
            }else if(status=='success' || status==2){
                return 'SUCESSO'
            }else if (status=='error' || status==3){
                return 'ERRO'
            }else if(status=='pending' || status==1){
                return 'PENDENTE'
            }else if(status=='warning' || status==4){
                return 'AVISO'
            }else {
                return status
            }
        },
        paginacaoConfirmados(link){
            if(link.url){
                this.url_requisicao = link.url; // ajustando a url com parâmetros de pagina
                //  console.log(this.urlUnidades)
                this.getWebHookConfirmadosUnityStatus(1);
                // console.log('paginacaoConfirmados',link.url)
            }

        },
        getWebHookConfirmadosUnityStatus(exibe_loading = 0){
            console.log(this.unidade);

            if(exibe_loading==1){

                $('#WebHooks'+this.unidade.id).modal('show');

            }
            //this.url_requisicao = this.url_requisicao+this.unitie.id

            console.log('URL: ' + this.paginacaoConfirmados)
            var config = {
                method: 'get',
                url: this.url_requisicao,
                headers: {
                        'CSRF_TOKEN': 'TESTE',
                //         'Authorization': this.$root.token(),
                    },
                };
                //executa requisição
                axios(config)
                .then(response => {

                    if(response.data){
                        
                        this.web_hooks = response.data.webhooks
                        
                        if(response.data.status=="success"){
                        
                            this.SuccessTotal = response.data.total
                            console.log('this.SuccessTotal',response.data.total)
                    
                        }else if(response.data.status=="pending"){
                            
                            this.PendingTotal = response.data.total
                            console.log('this.PendingTotal',response.data.total)
    
                        }else if(response.data.status=="error"){
    
                            this.ErrorTotal = response.data.total
                            console.log('this.ErrorTotal',response.data.total)
    
                        }else{
    
                            this.SemProcessamentoTotal = response.data.total
                            console.log('this.SemProcessamentoTotal',response.data.total)
                        }
                    }

                    // console.log('web_hooks',this.web_hooks)
                    if(exibe_loading==1){
                        if(this.unidade!=null){
                            $('#WebHooks'+this.unidade.id).modal('hide');
                        }
                    }
                })
                .catch(function (error) {
                    console.log('error',error);
                    window.alert(error)
                    if(exibe_loading==1){
                        if(this.unidade!=null){

                            $('#WebHooks'+this.unidade.id).modal('hide');

                        }else{
                            document.getElementById('logout-form').submit();
                        }
                    }
                    //this.erro = error
                    // console.log(error);
                });
        },
        OfertaInscricaoWeb(){
            window.location.href = "/Access/Unity/Unitie/"+this.unidade.id+"/oferta_inscricao_web";
        },
        OfertaProcessoSeletivo(){
            window.location.href = "/Access/Unity/Unitie/"+this.unidade.id+"/oferta_processo_seletivo";
        },
        filtraWebHooksStatus(status){
            this.url_requisicao = '/api/v1/Crm/getWebHookConfirmadosUnityStatus/' + this.unidade.id +'/status/'+ status
            
            this.getWebHookConfirmadosUnityStatus(this.show_loading);
            this.exibe_tabela = true;
            if(status=='success'){
                this.status_processamento = "SUCESSO"

            }else if(status=='error'){
                this.status_processamento = "ERRO"

            }else if(status=='pending'){
                this.status_processamento = "PENDENTE"

            }else{
                this.status_processamento = "AGUARDANDO"
            }
        },
        daraHoraBrasileiro(date){
            let dataHora = new Date(date)
            // let dataHoraUtc = new Date()
            return dataHora.toLocaleString('pt-BR', { timezone: 'UTC' })
        },
        filtrarAposExcluir(){
            if(this.status_processamento == "SUCESSO"){
                
                this.filtraWebHooksStatus("success")

            }else if(this.status_processamento == "ERRO"){
                
                this.filtraWebHooksStatus("error")

            }else if(this.status_processamento == "PENDENTE"){
                
                this.filtraWebHooksStatus("pending")

            }else{
                this.filtraWebHooksStatus("aguardando")
            }
        }

    }
}
</script>

<style scoped>
    .alert{
        padding: 0.5rem 0.75rem;
        margin: 1px;
        min-width: 135px;
    }
    .table-crud-sistema{
        background: white;
        border-radius: 0px 0px 10px 10px;
        overflow-x: auto;
    }

    

    .table-crud-sistema{
        background: white;
        border-radius: 0px 0px 10px 10px;
        overflow-x: auto;
    }

    .card-body {
        padding-left: 3px!important;
        padding-right: 3px!important;
        padding-bottom: 5px!important;
    }

    .btn-primary.disabled{
        background-color: #5d7773!important;

        border-color: #5d7773!important;
    }
</style>