<template>
  <div class="ficha-chamado-principal">
      <div class="ficha-chamado" :style="'border: 1px ' + corStatus + ' solid; text-align:left; margin-top:10px;border-radius: 3px;'">
        <table style="width:100%;margin:10px;cursor: pointer;" class="table-ficha-chamado" >
            <tr>
                <td style="width:40px;height:auto;">
                    <button class="btn" :id="'btn'+idComponente" @click="expandiRecolhe('linha'+idComponente,'btn'+idComponente)" :style="'color:white;font-weight:bold;width:25px;height:25px;margin:0px;padding:0px; background-color:' + corStatus + ';border-radius: 100px;'">+</button>&nbsp;&nbsp;&nbsp;
                </td>
                <td @click="expandiRecolhe('linha'+idComponente,'btn'+idComponente)">
                    <slot name="titulo">
                    </slot>
                </td>
            </tr>
        </table>
        <div class="row" :id="'linha'+idComponente" :style="'display:none;margin-left:2px;margin-right:2px;'">
            <div class="col-md-12 conteudo" style="text-align:justify;padding-left:15px;padding-right:15px;padding-bottom:15px;">
                <slot name="conteudo">
                </slot>
            </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
    props:{
        corStatus:{type:String,required:true},
        idComponente:{type:String,required:true},
    },
    methods:{
        expandiRecolhe(id_elemento,id_botao){
            if(document.getElementById(id_elemento).style.display == 'block'){
                $("#"+id_elemento).css("display", "none");
                $('#'+id_botao).text("+").attr({
                    title:"+"
                });
            }else{
                $("#"+id_elemento).css("display", "block");
                $('#'+id_botao).text("-").attr({
                    title:"-"
                });
            }
            console.log(document.getElementById(id_elemento).style.display)
        },
    }
}
</script>

<style>
    .ficha-chamado-principal{
        padding-left: 25px!important; 
        padding-right: 20px!important;
    }
   @media(max-width:600px){
        .card-body,.ficha-chamado{
            padding: 0.10rem;

        }

        .ficha-chamado{
            margin-top: 2px!important;
        }
        .ficha-chamado-principal{
            padding: 15px!important;
        }

        .table-ficha-chamado{
            margin: 5px!important;;
        }    

        .conteudo{
            padding-left: 10px!important;
            padding-right: 10px!important;
        }
    }
</style>