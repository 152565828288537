<template>
    <nav aria-label="Page navigation example ">
        <ul class="pagination justify-content-center" style="margin:5px;">
            <slot>
            </slot>            
        </ul>
        </nav>
</template>

<script>
export default {

}
</script>

<style>
    .page-item.active .page-link{
        background: #1c4d5a;
        border: 1px solid #fff;
        border-radius: 25px!important;
        
        
    }

    .page-item .page-link{
        color: #1c4d5a;
        border: 1px solid #1c4d5a;
        border-radius: 25px!important;
        margin: 2px;
    }

    .btn-primary,.btn-primary:visited{
        background: #1c4d5a;
        color: white;
        border-color: #1c4d5a;
        
    }

    .btn-primary:hover{
        color: #1c4d5a;
        background: white;
        border-color: #1c4d5a;
        
    }

    .btn-primary:active{
        color: #1c4d5a!important;
        background: white!important;
        border-color: #1c4d5a!important;
        
    }

    .page-item.disabled{
        border-color: #1c4d5a!important;
        border-radius: 25px!important;
    }
</style>