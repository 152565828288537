<template>
  <div class="container" >
    <div class="row justify-content-center">
        <div class="col-md-6">
            <div class="card" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                <div class="card-header"><h4>Login</h4></div>

                <div class="card-body">
                    <form method="POST" action="" @submit.prevent="login($event)">
                        <input type="hidden" :value="csrf_token" name="_token"/>

                        <div class="form-group row">
                            <div class="col-md-12" v-if="erros.email">
                                <div class="alert alert-danger" role="alert" v-if="erros.email">
                                    <strong>{{ (erros.email[0]=='These credentials do not match our records.' ? 'Essas credenciais não correspondem aos nossos registros.': erros.email[0]) }}</strong>
                                </div>
                            </div>
                            <label for="email" class="col-md-2 col-form-label text-md-right">E-mail</label>

                            <div class="col-md-10">
                                <input id="email" type="email" class="form-control  " name="email" value="" required autocomplete="email" v-model="email" autofocus>
                                
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="password" class="col-md-2 col-form-label text-md-right">Senha</label>

                            <div class="col-md-8" style="padding-right:0px!important;">
                                <input id="password" :type="showPassword" class="form-control" name="password" required autocomplete="current-password"  v-model="password">
                                


                                    <span class="invalid-feedback" role="alert">
                                        <strong>{{ message }}</strong>
                                    </span>

                            </div>
                            <div class="col-md-2">
                                <button type="button" class="btn btn-primary" style="width: 100%!important;max-width: 65px;" @click.prevent="mostrarSenha()"><b-icon icon="eye-fill" font-scale="1.3"></b-icon></button>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-md-6 offset-md-4">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" name="remember" id="remember">

                                    <label class="form-check-label" for="remember">
                                        Lembrar Senha
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row mb-0">
                            <div class="col-md-8 offset-md-4">
                                <button type="submit" class="btn btn-primary" >
                                    Login
                                </button>
                                <a class="btn btn-link" :href="linkEsqueceuSenha">
                                    Esqueceu sua senha?
                                </a>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <loading :id="'LoginUsuario'" titulo="Efetivando Logon do usuário, Aguarde..." :sem-padding="true" gif-carregando="/imagens/carregando.gif"></loading>
</div>
</template>

<script>
export default {
    props:['linkEsqueceuSenha','csrf_token','erros'],
    data(){
        return {
            message:'',
            email:'',
            password:'',
            showPassword:'password'
        }
    },
    methods:{
        mostrarSenha(){
            if(this.showPassword=='password'){
                this.showPassword='text'
            }else{
                this.showPassword='password'
            }
        },
        login(e){
            $('#LoginUsuario').modal('show');
            let url='/api/login'
            let configuracao = {
                method:'post',
                body:new URLSearchParams({
                    'email':this.email,
                    'password':this.password
                })
            }

            fetch(url, configuracao)
                .then(response => response.json())
                .then(data =>{
                    if(data.access_token){
                        document.cookie='token='+data.access_token
                    }else{
                        console.log(data);
                    }
                    
                })
                .catch(erro =>{
                    console.log(erro)
                    $('#LoginUsuario').modal('hide');
                })
                
                setInterval(() => {
                    
                    e.target.submit()
                }, 3000);
                
                
        }
    }
}
</script>

<style scoped>
    .container{
        padding-top: 5%;
    }
    .card{
            text-align: left;
            border: 1px solid #1c4d5a33;
            -webkit-box-shadow: 3px 5px 5px #1c4d5a75;
            -moz-box-shadow:    3px 5px 5px #1c4d5a75;
            box-shadow: 0px 0px 20px 6px #054253;


    }

    @media(max-width:600px){
        .card{
            text-align: left;
        }

        form{
            padding: 15px;
        }
    }
    .card-header{
        background-image: linear-gradient( to right, #1c4d5a, #1a7580,#1c4d5a );
        color: white;
    }

    h4{
        margin-bottom: 0rem;
        font-weight: 600;
    }

</style>
