<template>
    <div style="text-align:center;">
        <embed :src="(caminho!='' ? caminho : getDataTypeBase64())" :type="getDataTypeFile()" :width="getDataWidthFile()" :height="getDataHeightFile()"  style="max-width:100%;border:20px solid #1c4d5a!important;"/>
        <br><a :href="caminho" class="btn btn-primary btn-sm" target="_blank" :style="'width: '+getDataWidthFile()+';max-width:100%;'"><b-icon icon="eye-fill" font-scale="1.3"></b-icon> VISUALIZAR SOMENTE DOCUMENTO</a>
    </div>
</template>

<script>
export default {
  props:['arquivo','caminho'],
  methods:{
    getDataTypeBase64(){
        let arquivoNome = this.arquivo.nomeOriginal.split('.');
        let src = "";

        if(arquivoNome[arquivoNome.length-1]=='gif' || arquivoNome[arquivoNome.length-1]=='GIF'){
            src="data:image/gif;base64,"+this.arquivo.conteudo

            return src;
        }else if(arquivoNome[arquivoNome.length-1]=='png' || arquivoNome[arquivoNome.length-1]=='PNG'){
            src="data:image/png;base64,"+this.arquivo.conteudo

            return src;
        }else if(arquivoNome[arquivoNome.length-1]=='jpg' || arquivoNome[arquivoNome.length-1]=='JPG' || arquivoNome[arquivoNome.length-1]=='jpeg' || arquivoNome[arquivoNome.length-1]=='JPEG'){
            src="data:image/jpeg;base64,"+this.arquivo.conteudo

            return src;
        }else if(arquivoNome[arquivoNome.length-1]=='bmp' || arquivoNome[arquivoNome.length-1]=='BMP'){
            src="data:image/bmp;base64,"+this.arquivo.conteudo

            return src;
        }else if(arquivoNome[arquivoNome.length-1]=='pdf' || arquivoNome[arquivoNome.length-1]=='PDF'){
            src="data:application/pdf;base64,"+this.arquivo.conteudo

            return src;
        }else {
            src="data:text/plain;base64,"+this.arquivo.conteudo

            return src;
        }
        
    },
    getDataTypeFile(){
        let arquivoNome = this.arquivo.nomeOriginal.split('.');
        let type = "";

        if(arquivoNome[arquivoNome.length-1]=='gif' || arquivoNome[arquivoNome.length-1]=='GIF'){
            type="image/gif"

            return type;
        }else if(arquivoNome[arquivoNome.length-1]=='png' || arquivoNome[arquivoNome.length-1]=='PNG'){
            type="image/png"

            return type;
        }else if(arquivoNome[arquivoNome.length-1]=='jpg' || arquivoNome[arquivoNome.length-1]=='JPG' || arquivoNome[arquivoNome.length-1]=='jpeg' || arquivoNome[arquivoNome.length-1]=='JPEG'){
            type="image/jpeg"

            return type;
        }else if(arquivoNome[arquivoNome.length-1]=='bmp' || arquivoNome[arquivoNome.length-1]=='BMP'){
            type="image/bmp"

            return type;
        }else if(arquivoNome[arquivoNome.length-1]=='pdf' || arquivoNome[arquivoNome.length-1]=='PDF'){
            type="application/pdf"

            return type;
        }else {
            type="text/plain"

            return type;
        }
        
    },
    getDataWidthFile(){
        let arquivoNome = this.arquivo.nomeOriginal.split('.');
        let largura = "";

        if(arquivoNome[arquivoNome.length-1]=='gif' || arquivoNome[arquivoNome.length-1]=='GIF'){
            largura="auto"

            return largura;
        }else if(arquivoNome[arquivoNome.length-1]=='png' || arquivoNome[arquivoNome.length-1]=='PNG'){
            largura="auto"

            return largura;
        }else if(arquivoNome[arquivoNome.length-1]=='jpg' || arquivoNome[arquivoNome.length-1]=='JPG' || arquivoNome[arquivoNome.length-1]=='jpeg' || arquivoNome[arquivoNome.length-1]=='JPEG'){
            largura="auto"

            return largura;
        }else if(arquivoNome[arquivoNome.length-1]=='bmp' || arquivoNome[arquivoNome.length-1]=='BMP'){
            largura="auto"

            return largura;
        }else if(arquivoNome[arquivoNome.length-1]=='pdf' || arquivoNome[arquivoNome.length-1]=='PDF'){
            largura="100%"

            return largura;
        }else {
            largura="auto"

            return largura;
        }
        
    },
    getDataHeightFile(){
        let arquivoNome = this.arquivo.nomeOriginal.split('.');
        let altura = "";

        if(arquivoNome[arquivoNome.length-1]=='gif' || arquivoNome[arquivoNome.length-1]=='GIF'){
            altura="auto"

            return altura;
        }else if(arquivoNome[arquivoNome.length-1]=='png' || arquivoNome[arquivoNome.length-1]=='PNG'){
            altura="auto"

            return altura;
        }else if(arquivoNome[arquivoNome.length-1]=='jpg' || arquivoNome[arquivoNome.length-1]=='JPG' || arquivoNome[arquivoNome.length-1]=='jpeg' || arquivoNome[arquivoNome.length-1]=='JPEG'){
            altura="auto"

            return altura;
        }else if(arquivoNome[arquivoNome.length-1]=='bmp' || arquivoNome[arquivoNome.length-1]=='BMP'){
            altura="auto"

            return altura;
        }else if(arquivoNome[arquivoNome.length-1]=='pdf' || arquivoNome[arquivoNome.length-1]=='PDF'){
            altura="700px"

            return altura;
        }else {
            altura="auto"

            return altura;
        }
        
    }
  },
  created(){
    
  },
  data(){
    return{
        
    }
  }

}
</script>