<template>
    <div class="div-crud-sistema">
        <div :id="'Div' + nomeFormulario" :name="'Div' + nomeFormulario">
            <form :method="metodoFormulario" :action="linkAction" :id="nomeFormulario" :name="nomeFormulario">
                <div v-if="!edit">
                    <slot name="csrf">

                    </slot>
                </div>
                <input type="hidden" name="id_empresa" :value="cliente.id">
                <div class="form-row">
                    
                </div>
                <div v-if="cliente.CadastroTipo!=0 && cliente.CadastroTipo==1">
                    <div class="form-row">
                        <div class="form-group col-md-12 text-center">
                            <h6><b>DADOS CADASTRAIS DE USUÁRIO</b></h6>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-2">
                            <label for="tipoCadastroPessoa">Tipo de Cadastro</label>
                            <select  v-if="edit" disabled id="tipoCadastroPessoa" name="tipoCadastroPessoa" class="form-control" v-model="tipoCadastro">
                                <option value="1">CPF</option>
                            </select>
                            <select  :disabled="visualizar"  v-else id="tipoCadastroPessoa" name="tipoCadastroPessoa" class="form-control" v-model="tipoCadastro">
                                <option value="1">CPF</option>
                            </select>
                            <div class="invalid-feedback" v-if="errors.nome">
                                {{ errors.nome[0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-2">
                            <label for="inputNome">Perfil de Usuário</label>
                            
                            <select  :disabled="visualizar" id="perfil_usuario" name="tipoCadastroPessoa" class="form-control" v-model="cliente.perfil_usuario"  v-if="!errors.perfil_usuario">
                                <option value="0">Selecione o Perfil de Usuário</option>
                                <option v-for="perfil in perfil_usuario" :key="perfil.id" :value="perfil.id" v-if="usuarioAutenticado.perfil_usuario<=perfil.id">{{ perfil.descricao }}</option>
                            </select>
                            <select  :disabled="visualizar" id="perfil_usuario" name="tipoCadastroPessoa" class="form-control is-invalid" v-model="cliente.perfil_usuario" v-else>
                                <option value="0">Selecione o Perfil de Usuário</option>
                                <option v-for="perfil in perfil_usuario" :key="perfil.id" :value="perfil.id" v-if="usuarioAutenticado.perfil_usuario<=perfil.id">{{ perfil.descricao }}</option>
                            </select>
                            <div class="invalid-feedback" v-if="errors.perfil_usuario">
                                {{ errors.perfil_usuario[0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-2">
                            <label for="inputNome">Nome Completo</label>
                            <input :readonly="visualizar" v-if="!errors.nome" type="text" class="form-control" id="inputNome" name="inputNome" placeholder="Nome Completo" v-model="cliente.nome">
                            <input :readonly="visualizar" v-else type="text" class="form-control is-invalid" id="inputNome" name="inputNome" placeholder="Nome Completo" v-model="cliente.nome">
                            <div class="invalid-feedback" v-if="errors.nome">
                                {{ errors.nome[0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-2">
                            <label for="inputCPF">CPF</label>
                            <the-mask :readonly="visualizar" :masked="true" v-if="!errors.cpf" :mask="['###.###.###-##']" class="form-control" id="inputCPF" name="inputCPF" placeholder="CPF" v-model="doc"/>
                            <the-mask :readonly="visualizar" :masked="true" v-else :mask="['###.###.###-##']" class="form-control is-invalid" id="inputCPF" name="inputCPF" placeholder="CPF" v-model="doc"/>
                            <div class="invalid-feedback" v-if="errors.cpf">
                                {{ errors.cpf[0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-2" >
                            <label for="inputCodigoEmpresa">Ativo</label><br/>
                                <b-form-checkbox
                                    id="cadastroAtivo"
                                    v-model="cliente.ativo"
                                    name="cadastroAtivo"
                                    :value="'Ativo'"
                                    :unchecked-value="'Inativo'"
                                    :disabled="visualizar|| urlSistema=='Account/MyAccount'"
                                    :switch="true"
                                    style="vertical-align: bottom !important;"
                                ></b-form-checkbox>
                        </div>
                        <div class="form-group col-md-2" >
                            <label for="inputCodigoEmpresa">Usuário para Tarefas Automática</label><br/>
                            <b-form-checkbox
                                :id="'tarefa_automatica'+cliente.id"
                                v-model="cliente.tarefa_automatica"
                                name="tarefa_automatica"
                                :value="1"
                                :unchecked-value="0"
                                :disabled="visualizar"
                                :switch="true"
                            ></b-form-checkbox>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="inputEmail">E-mail/Login</label>
                            <input :readonly="visualizar" type="email" v-if="!errors.email" class="form-control" id="inputEmail" name="inputEmail" placeholder="E-mail/Login" v-model="cliente.email">
                            <input :readonly="visualizar" type="email" v-else class="form-control is-invalid"  id="inputEmail" name="inputEmail" placeholder="E-mail/Login" v-model="cliente.email"></input>
                            <div class="invalid-feedback" v-if="errors.email">
                                {{ errors.email[0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-2">
                            <label for="password">Senha</label>
                            <input :readonly="visualizar" type="password" v-if="!errors.password" class="form-control" id="password" name="password" placeholder="Senha" v-model="cliente.password">
                            <input :readonly="visualizar" type="password" v-else class="form-control is-invalid" id="password" name="password" placeholder="Senha" v-model="cliente.password">
                            <div class="invalid-feedback" v-if="errors.password">
                                {{ errors.password[0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-2">
                            <label for="password_confirmation">Confirmação de Senha</label>
                            <input :readonly="visualizar" type="password" v-if="!errors.password_confirmation" class="form-control" id="password_confirmation" name="password_confirmation" placeholder="Confirmação de Senha" v-model="cliente.password_confirmation">
                            <input :readonly="visualizar" type="password" v-else class="form-control is-invalid" id="password_confirmation" name="password_confirmation" placeholder="Confirmação de Senha" v-model="cliente.password_confirmation">
                            <div class="invalid-feedback" v-if="errors.password_confirmation">
                                {{ errors.password_confirmation[0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-2">
                            <label for="inputFone">Telefone</label>
                            <the-mask :readonly="visualizar" :masked="true" v-if="!errors.telefone" :mask="['(##)####-####','(##)#####-####']" type="text" class="form-control" id="inputFone" name="inputFone" placeholder="Telefone" v-model="cliente.telefone"/>
                            <the-mask :readonly="visualizar" :masked="true" v-else :mask="['(##)####-####','(##)#####-####']" type="text" class="form-control is-invalid" id="inputFone" name="inputFone" placeholder="Telefone" v-model="cliente.telefone"/>
                            <div class="invalid-feedback" v-if="errors.telefone">
                                {{ errors.telefone[0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-2">
                            <label for="inputCelular">Celular</label>
                            <the-mask :readonly="visualizar" :masked="true" :mask="['(##)####-####','(##)#####-####']" type="text" class="form-control" id="inputCelular" name="inputCelular" placeholder="Celular" v-model="cliente.celular"/>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-12 text-center">
                            <h6><b>NOTIFICAÇÕES</b></h6>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <b-form-checkbox
                                :id="'comunica_login'+cliente.id"
                                v-model="cliente.comunica_login"
                                name="comunica_login"
                                :value="1"
                                :unchecked-value="0"
                                :disabled="visualizar"
                                :switch="true"
                            >Recebe notificação por e-mail a cada novo login</b-form-checkbox>
                        </div>
                    </div>
                    <div class="form-row" v-if="showUnities" style="overflow-x: auto;">                        
                            <div class="form-group col-md-12" >
                                <table class="table table-sm table-hover" style="width:100%;font-size:10pt;">
                                    <thead style="background:#1c4d5a!important;color:white;">
                                        <tr>
                                            <th colspan="8" style="text-align:center;">Unidades Vinculadas</th>
                                        </tr>
                                        <tr>
                                            <th style="text-align:center;" class="align-middle">Unidade (ERP)</th>
                                            <th style="text-align:center;" class="align-middle">Nome Completo</th>
                                            <th style="text-align:center;" class="align-middle">Oferta de Processo Seletivo</th>
                                            <th style="text-align:center;" class="align-middle">Oferta de Inscricao Web</th>
                                            <th style="text-align:center;" class="align-middle">Inscricao de Aluno</th>
                                            <th style="text-align:center;" class="align-middle">Importação de Parcela</th>
                                            <th style="text-align:center;" class="align-middle">Download de Contrato</th>
                                            <th style="text-align:center;" class="align-middle">Ação</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="unidade in usuario_unidades" :key="unidade.id">
                                            <td style="text-align:center;" class="align-middle"> {{ unidade.codigounidade }} </td>
                                            <td class="align-middle" style="white-space: nowrap;"> {{ unidade.nomecompleto }} {{ unidade.vincular }} </td>
                                            <td style="text-align:center;" class="align-middle"> 
                                                <div v-if="unidade.op_oferta_processo_seletivo==1">
                                                    <b-form-checkbox
                                                    :id="'oferta_processo_seletivo'+unidade.id"
                                                    v-model="unidade.oferta_processo_seletivo"
                                                    name="oferta_processo_seletivo"
                                                    :value="1"
                                                    :unchecked-value="0"
                                                    :disabled="visualizar || urlSistema=='Account/MyAccount'"
                                                    :switch="true"
                                                ></b-form-checkbox>
                                                </div>
                                                <div v-else>
                                                    <b-form-checkbox
                                                    :id="'oferta_processo_seletivo'+unidade.id"
                                                    v-model="unidade.oferta_processo_seletivo"
                                                    name="oferta_processo_seletivo"
                                                    :value="1"
                                                    :unchecked-value="0"
                                                    disabled
                                                    :switch="true"
                                                ></b-form-checkbox>
                                                </div>
                                            </td>
                                            <td style="text-align:center;" class="align-middle"> 
                                                <div v-if="unidade.op_oferta_Inscricao_web==1">
                                                    <b-form-checkbox
                                                    :id="'oferta_Inscricao_web'+unidade.id"
                                                    v-model="unidade.oferta_Inscricao_web"
                                                    name="oferta_Inscricao_web"
                                                    :value="1"
                                                    :unchecked-value="0"
                                                    :disabled="visualizar || urlSistema=='Account/MyAccount'"
                                                    :switch="true"
                                                ></b-form-checkbox>
                                                </div>
                                                <div v-else>
                                                    <b-form-checkbox
                                                    :id="'oferta_Inscricao_web'+unidade.id"
                                                    v-model="unidade.oferta_Inscricao_web"
                                                    name="oferta_Inscricao_web"
                                                    :value="1"
                                                    :unchecked-value="0"
                                                    disabled
                                                    :switch="true"
                                                ></b-form-checkbox>
                                                </div>
                                            </td>
                                            <td style="text-align:center;" class="align-middle"> 
                                                <div v-if="unidade.op_inscricao_web_aluno==1">
                                                    <b-form-checkbox
                                                    :id="'inscricao_web_aluno'+unidade.id"
                                                    v-model="unidade.inscricao_web_aluno"
                                                    name="inscricao_web_aluno"
                                                    :value="1"
                                                    :unchecked-value="0"
                                                    :disabled="visualizar || urlSistema=='Account/MyAccount'"
                                                    :switch="true"
                                                ></b-form-checkbox>
                                                </div>
                                                <div v-else>
                                                    <b-form-checkbox
                                                    :id="'inscricao_web_aluno'+unidade.id"
                                                    v-model="unidade.inscricao_web_aluno"
                                                    name="inscricao_web_aluno"
                                                    :value="1"
                                                    :unchecked-value="0"
                                                    disabled
                                                    :switch="true"
                                                ></b-form-checkbox>
                                                </div>
                                            </td>
                                            <td style="text-align:center;" class="align-middle"> 
                                                <div v-if="unidade.op_importar_parcela==1">
                                                    <b-form-checkbox
                                                    :id="'importar_parcela'+unidade.id"
                                                    v-model="unidade.importar_parcela"
                                                    name="importar_parcela"
                                                    :value="1"
                                                    :unchecked-value="0"
                                                    :disabled="visualizar || urlSistema=='Account/MyAccount'"
                                                    :switch="true"
                                                ></b-form-checkbox>
                                                </div>
                                                <div v-else>
                                                    <b-form-checkbox
                                                    :id="'importar_parcela'+unidade.id"
                                                    v-model="unidade.importar_parcela"
                                                    name="importar_parcela"
                                                    :value="1"
                                                    :unchecked-value="0"
                                                    disabled
                                                    :switch="true"
                                                ></b-form-checkbox>
                                                </div>
                                            </td>
                                            <td style="text-align:center;" class="align-middle"> 
                                                <div v-if="unidade.op_download_contrato==1">
                                                    <b-form-checkbox
                                                    :id="'download_contrato'+unidade.id"
                                                    v-model="unidade.download_contrato"
                                                    name="download_contrato"
                                                    :value="1"
                                                    :unchecked-value="0"
                                                    :disabled="visualizar || urlSistema=='Account/MyAccount'"
                                                    :switch="true"
                                                    ></b-form-checkbox>
                                                </div>
                                                <div v-else>
                                                    <b-form-checkbox
                                                    :id="'download_contrato'+unidade.id"
                                                    v-model="unidade.download_contrato"
                                                    name="download_contrato"
                                                    :value="1"
                                                    :unchecked-value="0"
                                                    disabled
                                                    :switch="true"
                                                    ></b-form-checkbox>
                                                </div>
                                            </td>
                                            <td style="text-align:center;" class="align-middle">
                                                <button class="btn btn-primary btn-sm" @click.prevent="desvincularUnidade(unidade)" :disabled="visualizar || urlSistema=='Account/MyAccount'">Desvincular</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            
                        
                    </div>
                    <div class="form-row" v-if="showUnities &&  urlSistema=='Users/User'" style="overflow-x: auto;">
                        
                            <div class="form-group col-md-12" >
                                <table class="table table-sm table-hover" style="width:100%;font-size:10pt;">
                                    <thead style="background:#1c4d5a!important;color:white;">
                                        <tr>
                                            <th colspan="8" style="text-align:center;">Unidades Disponíveis</th>
                                        </tr>
                                        <tr>
                                            <th style="text-align:center;" class="align-middle">Unidade (ERP)</th>
                                            <th style="text-align:center;" class="align-middle">Nome Completo</th>
                                            <th style="text-align:center;" class="align-middle">Oferta de Processo Seletivo</th>
                                            <th style="text-align:center;" class="align-middle">Oferta de Inscricao Web</th>
                                            <th style="text-align:center;" class="align-middle">Inscricao de Aluno</th>
                                            <th style="text-align:center;" class="align-middle">Importação de Parcela</th>
                                            <th style="text-align:center;" class="align-middle">Download de Contrato</th>
                                            <th style="text-align:center;" class="align-middle">Ação</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="unidade in todas_unidades" :key="unidade.id">
                                            <td style="text-align:center;" class="align-middle"> {{ unidade.codigounidade }} </td>
                                            <td class="align-middle" style="white-space: nowrap;"> {{ unidade.nomecompleto }} {{ unidade.vincular }} </td>
                                            <td style="text-align:center;" class="align-middle"> 
                                                <div v-if="unidade.op_oferta_processo_seletivo==1">
                                                    <b-form-checkbox
                                                    :id="'oferta_processo_seletivo'+unidade.id"
                                                    v-model="unidade.oferta_processo_seletivo"
                                                    name="oferta_processo_seletivo"
                                                    :value="1"
                                                    :unchecked-value="0"
                                                    :disabled="visualizar || urlSistema=='Account/MyAccount'"
                                                    :switch="true"
                                                ></b-form-checkbox>
                                                </div>
                                                <div v-else>
                                                    <b-form-checkbox
                                                    :id="'oferta_processo_seletivo'+unidade.id"
                                                    v-model="unidade.oferta_processo_seletivo"
                                                    name="oferta_processo_seletivo"
                                                    :value="1"
                                                    :unchecked-value="0"
                                                    disabled
                                                    :switch="true"
                                                ></b-form-checkbox>
                                                </div>
                                            </td>
                                            <td style="text-align:center;" class="align-middle"> 
                                                <div v-if="unidade.op_oferta_Inscricao_web==1">
                                                    <b-form-checkbox
                                                    :id="'oferta_Inscricao_web'+unidade.id"
                                                    v-model="unidade.oferta_Inscricao_web"
                                                    name="oferta_Inscricao_web"
                                                    :value="1"
                                                    :unchecked-value="0"
                                                    :disabled="visualizar || urlSistema=='Account/MyAccount'"
                                                    :switch="true"
                                                ></b-form-checkbox>
                                                </div>
                                                <div v-else>
                                                    <b-form-checkbox
                                                    :id="'oferta_Inscricao_web'+unidade.id"
                                                    v-model="unidade.oferta_Inscricao_web"
                                                    name="oferta_Inscricao_web"
                                                    :value="1"
                                                    :unchecked-value="0"
                                                    disabled
                                                    :switch="true"
                                                ></b-form-checkbox>
                                                </div>
                                            </td>
                                            <td style="text-align:center;" class="align-middle"> 
                                                <div v-if="unidade.op_inscricao_web_aluno==1">
                                                    <b-form-checkbox
                                                    :id="'inscricao_web_aluno'+unidade.id"
                                                    v-model="unidade.inscricao_web_aluno"
                                                    name="inscricao_web_aluno"
                                                    :value="1"
                                                    :unchecked-value="0"
                                                    :disabled="visualizar || urlSistema=='Account/MyAccount'"
                                                    :switch="true"
                                                ></b-form-checkbox>
                                                </div>
                                                <div v-else>
                                                    <b-form-checkbox
                                                    :id="'inscricao_web_aluno'+unidade.id"
                                                    v-model="unidade.inscricao_web_aluno"
                                                    name="inscricao_web_aluno"
                                                    :value="1"
                                                    :unchecked-value="0"
                                                    disabled
                                                    :switch="true"
                                                ></b-form-checkbox>
                                                </div>
                                            </td>
                                            <td style="text-align:center;" class="align-middle"> 
                                                <div v-if="unidade.op_importar_parcela==1">
                                                    <b-form-checkbox
                                                    :id="'importar_parcela'+unidade.id"
                                                    v-model="unidade.importar_parcela"
                                                    name="importar_parcela"
                                                    :value="1"
                                                    :unchecked-value="0"
                                                    :disabled="visualizar || urlSistema=='Account/MyAccount'"
                                                    :switch="true"
                                                ></b-form-checkbox>
                                                </div>
                                                <div v-else>
                                                    <b-form-checkbox
                                                    :id="'importar_parcela'+unidade.id"
                                                    v-model="unidade.importar_parcela"
                                                    name="importar_parcela"
                                                    :value="1"
                                                    :unchecked-value="0"
                                                    disabled
                                                    :switch="true"
                                                ></b-form-checkbox>
                                                </div>
                                            </td>
                                            <td style="text-align:center;" class="align-middle"> 
                                                <div v-if="unidade.op_download_contrato==1">
                                                    <b-form-checkbox
                                                    :id="'download_contrato'+unidade.id"
                                                    v-model="unidade.download_contrato"
                                                    name="download_contrato"
                                                    :value="1"
                                                    :unchecked-value="0"
                                                    :disabled="visualizar || urlSistema=='Account/MyAccount'"
                                                    :switch="true"
                                                    ></b-form-checkbox>
                                                </div>
                                                <div v-else>
                                                    <b-form-checkbox
                                                    :id="'download_contrato'+unidade.id"
                                                    v-model="unidade.download_contrato"
                                                    name="download_contrato"
                                                    :value="1"
                                                    :unchecked-value="0"
                                                    disabled
                                                    :switch="true"
                                                    ></b-form-checkbox>
                                                </div>
                                            </td>
                                            <td style="text-align:center;" class="align-middle">
                                                <button class="btn btn-primary btn-sm" @click.prevent="vincularUnidade(unidade)" :disabled="visualizar || urlSistema=='Account/MyAccount'">Vincular</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            
                        
                    </div>

                </div>
                <br/>
                <a :href="'/'+urlSistema" class="btn btn-primary">Cancelar</a>
                <button :disabled="visualizar" type="button" class="btn btn-primary" @click="insereOuAtualiza()">Salvar</button>

            </form>
        </div>
        <modal-component titulo="Mensagem do Sistema" label-btn-fechar="OK" id="MensagemSistema" :url-redirect="'/'+urlSistema+'/'" :sem-padding="true">
            <template v-slot:titulo>
            </template>
            <template v-slot:conteudo>
                <table class="table table-sm table-hover" style="margin: 0px;">
                        <thead style="background:#1c4d5a!important;color:white;white-space: nowrap;">
                            <tr>
                                <th colspan="2" style="text-align:center;border: 0px;">
                                    <slot name="titulo">
                                        USUÁRIO
                                    </slot>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="transacaoStatus">
                                <td colspan="2" style="padding:0px;">
                                        <b-alert style="margin-bottom:0px;text-align:center;" v-if="edit" variant="success" show><b>Atualizado com sucesso!</b></b-alert>
                                        <b-alert style="margin-bottom:0px;text-align:center;" v-else variant="success" show><b>Cadastrado com sucesso!</b></b-alert>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="text-align:left;">
                                    <b>ID:</b> {{ cliente.id }}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="text-align:left;">
                                    <b>NOME:</b> {{ cliente.nome }}                                
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="text-align:left;">
                                    <b>CPF:</b> {{ cliente.cpf }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
            </template>
        </modal-component>
        <loading id="SalvandoUsuario" titulo="Salvando Usuários, Aguarde..." :sem-padding="true" gif-carregando="/imagens/carregando.gif"></loading>
    </div>
</template>

<script>


export default{

    methods:{
        dissmiss(id){
            this.$bvModal.hide(id);
        },
        novo(id){
            this.user = {}
            this.modoeditar = false
            this.modoexcluir = false
            this.modonovo = true
            this.texto_modal = "Novo"
            this.user.id=id
            // console.log(this.user)
        },
        editar(usuarioSelecionado){
            this.user = usuarioSelecionado;
            this.modoeditar = true
            this.modoexcluir = false
            this.modonovo = false
            this.texto_modal = "Editar"
            // console.log(this.user)
            // console.log(this.user)
        },
        excluir(usuarioSelecionado){
            this.user = usuarioSelecionado;
            this.modoeditar = false
            this.modoexcluir = true
            this.modonovo = false
            this.texto_modal = "Excluir"
            // console.log(this.user)
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.nameState = valid
            return valid
        },
        resetModal() {
            this.name = ''
            this.nameState = null
        },
        removerPorId: function (id) {
            var _vm = this; //Adicionando a instância à uma variável, para que possa ser acessada dentro do forEach`
            this.users.forEach(function(el, index) {
                if(el.id === id)
                    _vm.users.splice(index, 1);
            })
        },
        insereOuAtualiza(){
            if(!this.edit){
                this.inserirUsuario();
                //console.log('insere')
            }else{
                this.atualizaUsuario();
                //console.log('atualiza')
            }
        },
        inserirUsuario(){
            // if(this.modonovo){
                let url='/api/v1/'+this.urlSistema;
                $('#SalvandoUsuario').modal('show');
                //console.log(this.cliente);
                let unities_user = ""
                let unities_unbind="";
                for(let i=0;i<this.usuario_unidades.length;i++){
                    if(i<this.usuario_unidades.length-1){
                        unities_user = unities_user + this.usuario_unidades[i].id+","
                    }else{
                        unities_user = unities_user + this.usuario_unidades[i].id
                    }
                }

                for(let i=0;i<this.todas_unidades.length;i++){
                    if(i<this.todas_unidades.length-1){
                        unities_unbind = unities_unbind + this.todas_unidades[i].id+","
                    }else{
                        unities_unbind = unities_unbind + this.todas_unidades[i].id
                    }
                }
                let formData = new FormData();
                formData.append('tipocadastro', this.tipoCadastro)
                formData.append('nome', (this.cliente.nome?this.cliente.nome:''))
                formData.append('cpf', (this.cliente.cpf?this.cliente.cpf:''))
                formData.append('perfil_usuario', (this.cliente.perfil_usuario?this.cliente.perfil_usuario:0))
                formData.append('telefone', (this.cliente.telefone?this.cliente.telefone:''))
                formData.append('celular', (this.cliente.celular?this.cliente.celular:''))
                formData.append('email',(this.cliente.email?this.cliente.email:''))
                formData.append('password',(this.cliente.password?this.cliente.password:''))
                formData.append('password_confirmation',(this.cliente.password_confirmation?this.cliente.password_confirmation:''))
                formData.append('ativo',(this.cliente.ativo=='Ativo'?'1':'0'))
                formData.append('comunica_login',(this.cliente.comunica_login==1?'1':'0'))
                formData.append('tarefa_automatica', (this.cliente.tarefa_automatica?this.cliente.tarefa_automatica:'0'))
                formData.append('unidades_usuario',JSON.stringify(this.usuario_unidades))
                formData.append('unidades_desvincular',JSON.stringify(this.todas_unidades))
                let configuracao = {
                    // method:'post',
                    // headers:{
                    //     // // 'Accept': 'application/json',
                    //     // // 'Authorization': this.$root.token(),
                    // }
                }

                axios.post(url,formData,configuracao)
                    .then(response => {
                        
                        this.transacaoStatus = response.status
                        this.cliente = response.data.usuarioNovo;
                        this.errors={};
                        this.cliente.CadastroTipo=1
                        $('#SalvandoUsuario').modal('hide');
                        $('#MensagemSistema').modal('show');
                    })
                    .catch(errors => {
                        this.errors = errors.response.data.errors
                        console.log(errors.response)
                        $('#SalvandoUsuario').modal('hide');
                    })
            // }
        },
        atualizaUsuario(){
            // if(this.modonovo){
                $('#SalvandoUsuario').modal('show');
                let url='/api/v1/'+this.urlSistema+'/'+this.cliente.id;
                console.log(url);
                let unities_user = ""
                let unities_unbind="";
                for(let i=0;i<this.usuario_unidades.length;i++){
                    if(i<this.usuario_unidades.length-1){
                        unities_user = unities_user + this.usuario_unidades[i].id+","
                    }else{
                        unities_user = unities_user + this.usuario_unidades[i].id
                    }
                }

                for(let i=0;i<this.todas_unidades.length;i++){
                    if(i<this.todas_unidades.length-1){
                        unities_unbind = unities_unbind + this.todas_unidades[i].id+","
                    }else{
                        unities_unbind = unities_unbind + this.todas_unidades[i].id
                    }
                }
                
                let formData = new FormData();
                formData.append('_method','patch');
                formData.append('tipocadastro', this.tipoCadastro)
                formData.append('nome', (this.cliente.nome?this.cliente.nome:''))
                formData.append('perfil_usuario', (this.cliente.perfil_usuario?this.cliente.perfil_usuario:0))
                formData.append('cpf', (this.cliente.cpf?this.cliente.cpf:''))
                formData.append('telefone', (this.cliente.telefone?this.cliente.telefone:''))
                formData.append('celular', (this.cliente.celular?this.cliente.celular:''))
                formData.append('email',(this.cliente.email?this.cliente.email:''))
                formData.append('password',(this.cliente.password?this.cliente.password:''))
                formData.append('password_confirmation',(this.cliente.password_confirmation?this.cliente.password_confirmation:''))
                formData.append('ativo',(this.cliente.ativo=='Ativo'?'1':'0'))
                formData.append('comunica_login',(this.cliente.comunica_login==1?'1':'0'))
                formData.append('tarefa_automatica', (this.cliente.tarefa_automatica?this.cliente.tarefa_automatica:'0'))
                formData.append('unidades_usuario',JSON.stringify(this.usuario_unidades))
                formData.append('unidades_desvincular',JSON.stringify(this.todas_unidades))
                //console.log(JSON.stringify(this.todas_unidades));
                
                let configuracao = {
                    // method:'put',
                    // headers:{
                    // //     'Accept': 'application/json',
                    // //     'Authorization': this.$root.token()
                    // },
                }
                
                axios.post(url, formData,configuracao)
                    .then(response => {
                        this.transacaoStatus = response.status
                        this.cliente = response.data.usuarioNovo;
                        console.log('User:',response.data.usuarioNovo)
                        this.errors={};
                        this.cliente.CadastroTipo=1
                        $('#SalvandoUsuario').modal('hide');
                        $('#MensagemSistema').modal('show');
                    })
                    .catch(errors => {
                        this.errors = errors.response.data.errors
                        console.log(errors.response)
                        $('#SalvandoUsuario').modal('hide');
                    })
            // }
        },
        excluirUsuarioCliente(id){
            
            if(this.modoexcluir){
                var config = {
                method: 'delete',
                url: '/api/v1/'+this.urlSistema+'/'+id,
                // headers: {
                //         // 'Accept': 'application/json',
                //         // 'Authorization': this.$root.token(),
                //     },
                };
                //executa requisição
                axios(config)
                .then(response => {
                    debugger;
                    // console.log(response.data.usuario)
                    this.$bvModal.hide('modal-prevent-closing')
                    this.$nextTick(() => {
                        window.alert("Usuário de Cliente excluído com sucesso");
                    })
                    this.removerPorId(id)
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
        },
        vincularUnidade(unidade){
            this.usuario_unidades.push(unidade);
            for(let i=0;i<this.unidades.length;i++){
                if(this.unidades[i].id==unidade.id){
                    this.unidades.splice(i,1);
                }
            }
            this.unidades = this.unidades.sort(function(a, b){
                //console.log(a.nomecompleto,b.nomecompleto)
                return a.nomecompleto < b.nomecompleto;

            })

            this.usuario_unidades = this.usuario_unidades.sort(function(a, b){
                
                return a.nomecompleto < b.nomecompleto;

            })
        },
        desvincularUnidade(unidade){
            
            this.unidades.push(unidade);
            //this.unidades.push(unidade);
            for(let i=0;i<this.usuario_unidades.length;i++){
                if(this.usuario_unidades[i].id==unidade.id){
                    this.usuario_unidades.splice(i,1);
                }
            }
            this.unidades.sort()
            this.usuario_unidades.sort()
        },
        
    },
    mounted(){
        // this.$root.atualizaCookie('token');
    },
    created(){
        if(this.pessoa){
            this.cliente = this.pessoa;
            //console.log(this.cliente)
            this.users = this.usuarios;
            this.doc=this.pessoa.cpf;
            this.cliente.CadastroTipo=1
            this.tipoCadastro=1
            this.usuario_unidades = this.unidades_usuario
            this.todas_unidades = this.unidades

            for(let j=0;j<this.usuario_unidades.length;j++){
                for(var i=0;i<this.todas_unidades.length;i++){
                // console.log()
                   // console.log(this.todas_unidades[i].codigounidade)
                    if(this.todas_unidades[i].id==this.usuario_unidades[j].id){
                        this.todas_unidades.splice(i,1);
                    }
                }
                
            }
            
            for(let i=0;i<this.todas_unidades.length;i++){
                this.todas_unidades[i].op_oferta_processo_seletivo = this.todas_unidades[i].oferta_processo_seletivo==1?true:false;
                this.todas_unidades[i].op_oferta_Inscricao_web = this.todas_unidades[i].oferta_Inscricao_web==1?true:false;
                this.todas_unidades[i].op_inscricao_web_aluno = this.todas_unidades[i].inscricao_web_aluno==1?true:false;
                this.todas_unidades[i].op_importar_parcela = this.todas_unidades[i].importar_parcela==1?true:false;
                this.todas_unidades[i].op_download_contrato = this.todas_unidades[i].download_contrato==1?true:false;
                
            }

            for(let i=0;i<this.usuario_unidades.length;i++){
                this.usuario_unidades[i].op_oferta_processo_seletivo = this.usuario_unidades[i].oferta_processo_seletivo==1?true:false;
                this.usuario_unidades[i].op_oferta_Inscricao_web = this.usuario_unidades[i].oferta_Inscricao_web==1?true:false;
                this.usuario_unidades[i].op_inscricao_web_aluno = this.usuario_unidades[i].inscricao_web_aluno==1?true:false;
                this.usuario_unidades[i].op_importar_parcela = this.usuario_unidades[i].importar_parcela==1?true:false;
                this.usuario_unidades[i].op_download_contrato = this.usuario_unidades[i].download_contrato==1?true:false;
                for(let a = 0; a< this.unity_user.length;a++){
                    if(this.unity_user[a].unity_id == this.usuario_unidades[i].id){
                        
                        this.usuario_unidades[i].oferta_processo_seletivo = this.unity_user[a].oferta_processo_seletivo
                        this.usuario_unidades[i].oferta_Inscricao_web = this.unity_user[a].oferta_Inscricao_web
                        this.usuario_unidades[i].inscricao_web_aluno = this.unity_user[a].inscricao_web_aluno
                        this.usuario_unidades[i].importar_parcela = this.unity_user[a].importar_parcela
                        this.usuario_unidades[i].download_contrato = this.unity_user[a].download_contrato
                    }
                }
            }

               
            
        }else{
            this.cliente.CadastroTipo=0;
            this.tipoCadastro=0;
            this.cliente.estado="0";
        }
    },
    props:{
        urlSistema:{
            type:String,
            required:true
        },
        visualizar:{
            type:Boolean,
            required:true
        },
        nomeFormulario:{
            type:String,
            required:true
        },
        metodoFormulario:{
            type:String,
            required:true
        },
        linkAction:{
            type:String,
            required:true
        },
        pessoa:{
            type:Object,
            required:false
        },
        usuarioAutenticado:{
            type:Object,
            required:false
        },
        usuarios:[],
        showUnities:{
            type:Boolean,
            required:true
        },
        unidades_usuario:[],
        unity_user:[],
        unidades:[],
        edit:{
            type:Boolean,
            required:true
        }
    },
    watch: {
       // whenever question changes, this function will run
        texto_modal(newTexto, oldTtexto) {
            if (newTexto!=oldTtexto) {
                this.texto_modal=newTexto;
            }
        }
        ,tipoCadastro(newTipoCadastro, oldTipoCadastro) {
            if (newTipoCadastro!=oldTipoCadastro) {
                this.cliente.CadastroTipo=newTipoCadastro;
                this.tipoCadastro=newTipoCadastro;
                this.errors={};
            }
        },
        doc(newdoc, olddoc) {
            if (newdoc!=olddoc) {
                this.cliente.cpf=newdoc;
                this.doc=newdoc;
            }
        },
    },
    data(){
        return{
            teste:null,
            transacaoStatus:null,
            modonovo:false,
            modoeditar:false,
            modoexcluir:false,
            Novo:false,
            texto_modal:'',
            nameState: null,
            senhaState:null,
            emailState:null,
            submittedNames: [],
            tipoCadastro:null,
            doc:null,
            cliente:{
                id:null,
                nome:null,
                cpf:null,
                email:null,
                password:null,
                password_confirmation:null,
                telefone:null,
                celular:null,
                
                /**
                 * cpf = 1
                 * cnpj = 2
                 */
                CadastroTipo:1,
                
            },
            user:{
                id:null,
                name:null,
                email:null,
                senha:null,
            },
            users:[],
            errors:{},
            usuario_unidades:[], // guarda as unidades que o usuário está vinculado
            todas_unidades:[],
            perfil_usuario:[
                {
                    id:1,
                    descricao:'Administrador de Sistema'
                },
                {
                    id:2,
                    descricao:'Responsável de Empresa'
                },
                {
                    id:3,
                    descricao:'Usuário de Empresa'
                }
            ]
        }
    }
}
</script>

<style scoped>
    .custom-control, .custom-checkbox{
        font-size: 10px;
    }
    
    form{
        text-align: left!important;
    }

    .div-crud-sistema{
        background: white;
        padding: 15px;
        border-radius: 10px;

    }

</style>
