<template>
  <div class="table-crud-sistema">
      <div class="row" style="margin-right: 0px; margin-left: 0px;">
        <div class="col" style="text-align:left;">
            <div class="padding">
                <slot name="botaonovo">

                </slot>
            </div>
        </div>
      </div>
    <table :class="classe">
        <thead style="background:#1c4d5a!important;color:white;">
            <tr>
                <th style="text-align:center;">
                    Empresa Vinculada
                </th>
                <th v-for="coluna in colunas" :key="coluna.id" style="text-align:center;">
                    {{ coluna.descricaocoluna }}
                </th>
                <th>

                </th>
                <!--<th style="text-align:center;">Ações</th>-->
            </tr>
        </thead>
        <tbody>
            <template v-for="pessoa,i in pessoas.data" >
            <tr :key="i">
                <td><b>{{ pessoa.companies.nomecompleto }} (EMPRESA: {{ pessoa.companies.codigoempresa }})</b></td>
                <td v-for="coluna in colunas" :key="coluna.id" :style="'text-align:'+coluna.alinhamentotexto+';'+ (coluna.nomecampo!='ativo' ? '' : 'font-weight:bold')" >
                    {{ pessoa[coluna.nomecampo] }}
                </td>
                <td :colspan="colunas.length" style="padding-left:15px">
                    <a :href="urlSistema+'/'+pessoa.id" v-if="visualizar" class="btn btn-primary btn-sm" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;"><!--Visualizar-->  <b-icon icon="eye-fill" font-scale="1.3"></b-icon></a>&nbsp;&nbsp;
                    <a :href="urlSistema+'/'+pessoa.id+'/edit'" v-if="editar" class="btn btn-primary btn-sm" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;"><!--Atualizar-->  <b-icon icon="pencil-fill" font-scale="1.3"></b-icon></a>&nbsp;&nbsp;
                    <a @click="selecionarPessoaExcluir(pessoa)" v-if="excluir" class="btn btn-primary btn-sm" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;"><!--Apagar-->  <b-icon icon="trash-fill" font-scale="1.3"></b-icon></a>&nbsp;&nbsp;
                    <a class="btn btn-primary btn-sm" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;" :href="'/Access/Unity/Unitie/'+pessoa.id"><b-icon icon="arrow-right-circle-fill" font-scale="1.3"></b-icon></a>
                </td> 
                <!--<td style="white-space: nowrap;text-align:center;">
                    
                </td>-->
            </tr>
            
            <!--<tr :key="pessoa.id">
            </tr>-->
            </template>
        </tbody>
    </table>
    <paginate class="padding">
        <li v-for=" link, key in pessoas.links" :key="key" :class="(link.active ? 'page-item active':(link.url ? 'page-item':'page-item disabled'))" style="cursor:pointer;">
            <a class="page-link" tabindex="-1" v-html="link.label" @click="paginacao(link)">{{ link.label }}

            </a>
        </li>
    </paginate>
    <company-unity-modal-exclusao @exclusaoconcluida="getUnities()" v-if="pessoaSelecionada" id="ExclusaoPessoa" :url-redirect="'/'+urlSistema+'/'" :url-sistema="urlSistema" :pessoa="pessoaSelecionada" :sem-padding="true">
        <template v-slot:titulo>
               EXCLUSÃO DE UNIDADE
        </template>
    </company-unity-modal-exclusao>
    <loading id="getUnitie" titulo="Obtendo Unidades, Aguarde..." :sem-padding="true" gif-carregando="/imagens/carregando.gif"></loading>
  </div>
</template>

<script>
export default {
    props:
    {
        urlSistema:{type:String,required:true},
        novo:{type:Boolean,required:true},
        visualizar:{type:Boolean,required:true},
        editar:{type:Boolean,required:true},
        excluir:{type:Boolean,required:true},
        classe:{
            type:String,
            required:false
        }
    },
    data(){
        return{
            colunas:null,
            pessoas:{data:null},
            pessoaSelecionada:{},
            urlApi:window.location.origin+'/api/v1'+this.urlSistema,
        }
    },
    created(){
         this.getUnities();
    },
    mounted(){
        // this.$root.atualizaCookie('token');
        // console.log(this.pessoas)
    },
    methods:{
        paginacao(link){
            if(link.url){
                this.urlApi = link.url; // ajustando a url com parâmetros de pagina
                //  console.log(this.urlApi)
                this.getUnities();
            }

        },
        selecionarPessoaExcluir(pessoa){
            this.pessoaSelecionada = pessoa
            $('#ExclusaoPessoa').modal('show');
            
        },
        excluirPessoa(){
            console.log('teste')
        },
        getUnities(){
            $('#getUnitie').modal('show');
            // Client/ClientUser
            //gera configurações de cabeçalho para requisição
            var config = {
                method: 'get',
                url: this.urlApi,
                // headers: {
                //         'Accept': 'application/json',
                //         'Authorization': this.$root.token(),
                //     },
                };
                //executa requisição
                axios(config)
                .then(response => {
                     console.log(response.data.Pessoas)
                    this.pessoas = response.data.Pessoas
                    this.colunas = response.data.CamposTabelaExibicao
                    $('#getUnitie').modal('hide');
                })
                .catch(function (error) {
                    console.log(error);
                    $('#getUnitie').modal('hide');
                });
            },
            
            // getCookie(name) {
            //     return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + name.replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
            // }
    }

}
</script>

<style scoped>
    .table-crud-sistema{
        background: white;
        border-radius: 0px 0px 10px 10px;
        overflow-x: auto;
    }

    table{
        
        overflow: auto;
        white-space: nowrap;
    }
    .padding{
        padding: 15px;
    }

    .page-item.active .page-link{
        background: #1c4d5a;
        border-color: #1c4d5a;
        color: white!important;
    }

    .page-item .page-link{
        color: #1c4d5a;
        border-color: #1c4d5a;
    }

    .btn-primary,.btn-primary:visited{
        background: #1c4d5a;
        color: white;
        border-color: #1c4d5a;
    }

    .btn-primary:hover{
        color: #1c4d5a;
        background: white;
        border-color: #1c4d5a;
    }

    .btn-primary:active{
        color: #1c4d5a!important;
        background: white!important;
        border-color: #1c4d5a!important;
    }

    .page-item.disabled{
        border-color: #1c4d5a;
    }

    @media(max-width: 600px){
        table{
            display: block;    
            overflow: auto;
            white-space: nowrap;
        }
    }
</style>
