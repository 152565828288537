<template>
  <div>
        <div>
            <slot name="csrf">

            </slot>
        </div>
        <!--<div class="form-row" style="padding:1rem;">
            <div class="form-group col-md-1">
                <label for="anoInicio"> <b>ANO LETIVO</b></label>
                <input class="form-control" type="number" id="anoInicio" name="anoInicio" v-model="anoInicio" :readonly="visualizar"/>
            </div>
            <div class="form-group col-md-3">
                <label for="CursoGvdasa"><b>CURSOS DISPONÍVEIS</b></label>
                <select name="CursoGvdasa" id="CursoGvdasa" class="form-control" v-model="cod_curso_selecionado" :disabled="visualizar">
                    <option value="0" selected>SELECIONE O CURSO</option>
                    <option v-for="curso in todos_cursos" :key="curso.CODIGOCURSO" :value="curso.CODIGOCURSO">{{ curso.DESCRICAO + ' - ' + curso.CODIGOCURSO}}</option>
                </select>
            </div>
            <div class="form-group col-md-2">
                <label for="Semestre"><b>PERIODO</b></label>
                <select name="Semestre" id="Semestre" class="form-control" v-model="semestre_selecionado" :disabled="visualizar">
                    <option :value="''">SELECIONE O PERIODO</option>
                    <option :value="0">Anual</option>
                    <option :value="1">1º Semestre </option>
                    <option :value="2">2º Semestre </option>
                </select>
            </div>
            <div class="form-group col-md-2">
                <label for="dataInicio"><b>DATA INICIO INSCRIÇÃO</b></label></br>
                <date-picker v-model="dataInicio" format="DD/MM/YYYY" type="date" id="dataInicio" name="dataInicio" style="width: 100%;" placeholder="__/__/____" :disabled="visualizar"></date-picker>
            </div>
            <div class="form-group col-md-2">
                <label for="dataTermino"><b>DATA TÉRMINO INSCRIÇÃO</b></label></br>
                <date-picker v-model="dataTermino" format="DD/MM/YYYY" type="date" id="dataTermino" name="dataTermino" style="width: 100%;" placeholder="__/__/____" :disabled="visualizar"></date-picker>
            </div>
        </div>-->
        <div class="card" style="margin-bottom: 15px;">
            <div class="card-body">
                
                        <!--<a class="btn btn-primary btn-sm" :href="urlRedirect">Cancelar</a>-->
                        <!--<button class="btn btn-sm btn-primary" @click="insereAtualiza(filtroPesquisaInscricao)" style="white-space: nowrap;" :disabled="visualizar">Salvar</button>-->
                        <blockquote class="blockquote text-center">
                            <p class="mb-0">Ao acessar esta funcionalidade, o sistema automaticamente irá exibir as Turmas liberadas para inscrição nesta Empresa/Unidade  no ERP.</p>
                            <p class="mb-0">Para atualizar a listagem de Turmas liberadas, clique em Filtrar Turmas</p>
                            <footer class="blockquote-footer"><cite title="Observação"><b>Observação: </b>Se não exibir as Turmas liberadas, entre em contato com o Responsável pelo ERP na Instituição para que seja ajustado.<br>Nossa ferramenta apenas comunica-se com a API do ERP nos servidores da Intituição.</cite></footer>
                            <button type="button" class="btn btn-primary btn-sm" @click="getLiberacaoInscricoesWebERP()">Filtrar Turmas</button> <a :href="'/Access/Unity/Unitie/'+unitie.id+'/oferta_inscricao_web'"  class="btn btn-primary btn-sm" >Exportações</a>
                        </blockquote>
                    
            </div>
        </div>
        <div v-if="msgErro && !success">
            <b-alert
                :show="dismissCountDownDanger"
                dismissible
                variant="danger"
                @dismissed="dismissCountDownDanger=0"
                @dismiss-count-down="countDownChangedDanger"
                >
                <strong>Mensagem:</strong> Não existem Liberações de Inscrições configuradas para esta unidade.<br/>
                <strong>Resposta ERP:</strong> {{ msgErro }}
            </b-alert>
        </div>
        <div v-if="success">
            <b-alert
                :show="dismissCountDown"
                dismissible
                variant="success"
                @dismissed="dismissCountDown=0"
                @dismiss-count-down="countDownChanged"
                >
                <strong>Mensagem:</strong> Lista de Inscrições do ERP Atualizada!<br/>
            </b-alert>
        </div>

        <div class="form-row" v-for="curso,i in turmasFiltradas" :key="i" >
            <div class="form-group col-md-12">
                <content-component :id="(curso.descricaoCurso!='' && curso.idCurso>0?curso.descricaoCurso+curso.idCurso:'ERRONÃOENCONTRADO')" :padrao-exibicao="false"
                >
                    <template v-slot:cabecalho >
                        <b>{{ (curso.descricaoCurso!='' && curso.idCurso>0?curso.descricaoCurso:'INFORMAÇÃO') }}</b>
                    </template>
                    <template v-slot:conteudo >
                        <table class="table table-sm table-hover ">
                            <thead style="background:#1c4d5a!important;color:white;white-space: nowrap;">
                                <tr>
                                    <th class="align-middle">
                                        #ITEM
                                    </th>
                                    <th class="align-middle">
                                        Curso
                                    </th>
                                    <th class="align-middle">
                                        Ciclo
                                    </th>
                                    <th class="align-middle">
                                        Ano Inicio
                                    </th>
                                    <th class="align-middle">
                                        Descrição Oferta
                                    </th>
                                    <th class="align-middle">
                                        Turma ERP
                                    </th>
                                    <th class="align-middle">
                                        Inicio Inscrição
                                    </th>
                                    <th class="align-middle">
                                        Término Inscrição
                                    </th>
                                    <th class="align-middle">
                                        Modalidade
                                    </th>
                                    <th class="align-middle">
                                        Valor
                                    </th>
                                    <th>
                                        
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-if="turmasFiltradas.length>0">
                                <tr v-for="turma, item in curso.turmas" :key="turma.idTurma" >
                                    <td class="align-middle">
                                        {{ item + 1 }} 
                                    </td>                                    
                                    <td class="align-middle">
                                        {{ curso.descricaoCurso }}
                                    </td>                                    
                                    <td class="align-middle">
                                        {{ turma.descricaoCiclo }}
                                    </td>   
                                    <td class="align-middle">
                                        {{ turma.anoInicio }}
                                    </td>
                                    <td class="align-middle">
                                        {{ turma.descricaoofertainscricao }} 
                                    </td>
                                    <td class="align-middle">
                                        <strong>{{ turma.descricaoTurma.trim() }}</strong>
                                    </td>
                                    <td class="align-middle">
                                        {{ formatDate(turma.dataInicioInscricao,2) }}
                                    </td>
                                    <td class="align-middle">
                                        {{ formatDate(turma.dataTerminoInscricao,2) }}
                                    </td>
                                    <td class="align-middle" v-if="turma.descricaoModalidade">
                                        {{ (turma.descricaoModalidade?turma.descricaoModalidade:'Turma não será exportada por falta de modalidade')  }}
                                    </td>
                                    <td class="align-middle" v-else>
                                        <b style="color:red;">Turma não será exportada por falta de modalidade</b>
                                    </td>
                                    <td class="align-middle">
                                        <b style="color:red;">{{ ( turma.planosPagamento.length > 0 ? $root.numberToReal(parseFloat(turma.planosPagamento[0].parcelas[0].eventos[0].valor)) : 'Isento') }}</b>
                                    </td>
                                    <td class="align-middle">
                                        <div class="dropdown" >
                                            <button class="btn btn-sm btn-primary dropdown-toggle" type="button" id="dropdownBotaoOpcoes" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Opções
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownBotaoOpcoes">
                                                <a  v-if="turma.descricaoModalidade" class="dropdown-item" href="#" @click.prevent="exportarOfertaTurmaRubeus(turma,curso)" :disabled="visualizar">Exportar CRM</a>
                                                <a v-if="turma.planosPagamento.length > 0" class="dropdown-item" href="#" @click.prevent="verPlanoPagamento( turma.planosPagamento[0])">Plano de Pagamento</a>
                                            </div>
                                        </div>
                                        <!--button v-if="turma.descricaoModalidade" class="btn btn-sm btn-primary" @click="exportarOfertaTurmaRubeus(turma,curso)" style="white-space: nowrap;" :disabled="visualizar">Exportar CRM</button-->
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="7"  class="align-middle" style="text-align:center;">
                                        <b>Não há turmas filtradas, filtre um turma utilizando os campos acima!!!</b>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                </content-component>
                
            </div>
        </div>
        <modal-component titulo="Exportação CRM" label-btn-fechar="OK" id="ExportacaoCrmMsg" :sem-padding="true">
            <template v-slot:titulo>
                Exportação CRM
            </template>
            <template v-slot:conteudo>
                <div v-if="ofertaCursoTurma">
                    <table class="table table-sm table-hover" style="margin: 0px;">
                        <thead style="background:#1c4d5a!important;color:white;white-space: nowrap;">
                            <tr>
                                <th colspan="2" class="align-middle" style="text-align:center;border: 0px;">
                                    CURSO
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colspan="2" style="padding:0px;">
                                    <div class="alert alert-success" role="alert" style="margin:0px;text-align: center;">
                                        Turma Exportada para o CRM
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="text-align:left;">
                                    <b>Código:</b> {{ ofertaCursoTurma.codCurso }}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="text-align:left;">
                                    <b>Curso:</b> {{ ofertaCursoTurma.nomeCurso }}                                        
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="text-align:left;">
                                    <b>Descrição:</b> {{ ofertaCursoTurma.descricaoCurso }}                                        
                                </td>
                            </tr>
                            <tr>
                                <td style="text-align:left;">
                                    <b>Data Inicio:</b> {{ formatDate(ofertaCursoTurma.inicioCurso,2) }}
                                </td>
                                <td style="text-align:left;">
                                    <b>Data Término:</b> {{ formatDate(ofertaCursoTurma.terminoCurso,2) }}                                        
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="table table-sm table-hover" style="margin: 0px;">
                        <thead style="background:#1c4d5a!important;color:white;white-space: nowrap;">
                            <tr>
                                <th colspan="2" class="align-middle" style="text-align:center;border: 0px;">
                                    TURMA
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colspan="2" style="text-align:left;">
                                    <b>Oferta:</b> {{ ofertaCursoTurma.codOferta }}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="text-align:left;">
                                    <b>Descrição:</b> {{ ofertaCursoTurma.nome }}                                       
                                </td>
                            </tr>
                            <tr>
                                <td style="text-align:left;">
                                    <b>Data Inicio:</b> {{ formatDate(ofertaCursoTurma.inicioInscricao,2) }}
                                </td>
                                <td style="text-align:left;">
                                    <b>Data Término:</b> {{ formatDate(ofertaCursoTurma.terminoInscricao,2) }}                                        
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="table table-sm table-hover" style="margin: 0px;">
                        <thead style="background:#1c4d5a!important;color:white;white-space: nowrap;">
                            <tr>
                                <th colspan="2" class="align-middle" style="text-align:center;border: 0px;">
                                    MATRÍCULA
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style="text-align:left;">
                                    <b>Data Inicio:</b> {{ formatDate(ofertaCursoTurma.inicioMatricula,2) }}
                                </td>
                                <td style="text-align:left;">
                                    <b>Data Término:</b> {{ formatDate(ofertaCursoTurma.terminoMatricula,2) }}                                      
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="table table-sm table-hover" style="margin: 0px;">
                        <thead style="background:#1c4d5a!important;color:white;white-space: nowrap;">
                            <tr>
                                <th colspan="2" class="align-middle" style="text-align:center;border: 0px;">
                                    INSCRICAO
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style="text-align:left;">
                                    <b>Data Inicio:</b> {{ formatDate(ofertaCursoTurma.inicioInscricao,2) }}
                                </td>
                                <td style="text-align:left;">
                                    <b>Data Término:</b> {{ formatDate(ofertaCursoTurma.terminoInscricao,2) }}                                      
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
            </template>
        </modal-component>
        <modal-component titulo="Exportação CRM" label-btn-fechar="OK" id="CadastroFiltro" :sem-padding="true" :url-redirect="dadosFiltro.anoliberacaoinscricaocursoweb ? urlRedirect:''">
            <template v-slot:titulo>
                Exportação CRM
            </template>
            <template v-slot:conteudo>
                <div v-if="dadosFiltro.anoliberacaoinscricaocursoweb">
                    <table class="table table-sm table-hover" style="margin: 0px;">
                        <thead style="background:#1c4d5a!important;color:white;white-space: nowrap;">
                            <tr>
                                <th colspan="2" class="align-middle" style="text-align:center;border: 0px;">
                                    MENSAGEM
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colspan="2" style="padding:0px;">
                                    <div class="alert alert-success" role="alert" style="margin:0px;text-align: center;">
                                        {{ (filtroEditado.id>0 ? 'Filtro atualizado com sucesso.' : 'Filtro cadastrado com sucesso.') }}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="text-align:left;">
                                    <b>Ano Inscrição:</b> {{ dadosFiltro.anoliberacaoinscricaocursoweb }}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="text-align:left;">
                                    <b>Curso:</b> {{ dadosFiltro.nomecurso }}                                        
                                </td>
                            </tr>
                            <tr v-if="dadosFiltro.semestre">
                                <td colspan="2" style="text-align:left;" v-if="dadosFiltro.semestre==0">
                                    <b>Periodo:</b> Anual                                       
                                </td>
                                <td colspan="2" style="text-align:left;" v-if="dadosFiltro.semestre==1">
                                    <b>Periodo:</b> 1º Semestre                                       
                                </td>
                                <td colspan="2" style="text-align:left;" v-if="dadosFiltro.semestre==2">
                                    <b>Periodo:</b> 2º Semestre                                            
                                </td>
                            </tr>
                            <tr v-if="dadosFiltro.datainicio || dadosFiltro.datatermino">
                                <td style="text-align:left;" v-if="dadosFiltro.datainicio" :colspan="dadosFiltro.datainicio ? '1' :'2'">
                                    <b>Data Inicio:</b> {{ formatDate(dadosFiltro.datainicio,2) }}
                                </td>
                                <td style="text-align:left;" v-if="dadosFiltro.datatermino" :colspan="dadosFiltro.datatermino ? '1' :'2'">
                                    <b>Data Término:</b> {{ formatDate(dadosFiltro.datatermino,2) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else>
                    <table class="table table-sm table-hover" style="margin: 0px;">
                        <thead style="background:#1c4d5a!important;color:white;white-space: nowrap;">
                            <tr>
                                <th colspan="2" class="align-middle" style="text-align:center;border: 0px;">
                                    MENSAGEM
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colspan="2" style="padding:0px;">
                                    <div class="alert alert-danger" role="alert" style="margin:0px;text-align: center;">
                                        {{ error.msg }}
                                    </div>
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                    
                </div>
            </template>
        </modal-component>
        <modal-component @fecharmodal="resetarPlanoPagamento()" titulo="Plano de Pagamento" data-backdrop="static" label-btn-fechar="OK" id="MostraPlanoPagamento" :sem-padding="true" >
            <template v-slot:titulo>
                Plano de Pagamento
            </template>
            <template v-slot:conteudo>
                <div v-if="plano_pagamento_visualizar">
                    <table class="table table-sm table-hover" style="margin: 0px;">
                        <thead style="background:#1c4d5a!important;color:white;white-space: nowrap;">
                            <tr>
                                <th class="align-middle" style="text-align:center;border: 0px;">
                                    DETALHES DO PLANO DE PAGAMENTO
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <!--tr>
                                <td style="padding:0px;">
                                    <div class="alert alert-success" role="alert" style="margin:0px;text-align: center;">
                                        Plano de Pagamento da Oferta
                                    </div>
                                </td>
                            </tr-->
                            <tr>
                                <td style="text-align:left;">
                                    <b>Descrição:</b> {{ plano_pagamento_visualizar.descricaoPlanoPagamento }}                                        
                                </td>
                            </tr>
                            <tr>
                                <td style="text-align:left;">
                                    <b>Qtd Parcelas:</b> {{ plano_pagamento_visualizar.parcelas.length }}                                        
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="table table-sm table-hover" style="margin: 0px;" v-if="plano_pagamento_visualizar.parcelas.length>0">
                        <thead style="background:#1c4d5a!important;color:white;white-space: nowrap;">
                            <tr>
                                <th colspan="4" class="align-middle" style="text-align:center;border: 0px;">
                                    {{ (plano_pagamento_visualizar.quantidadeParcelas>1 ? "PARCELAS" : "PARCELA") }}
                                </th>
                            </tr>
                            <tr>
                                <th class="align-middle" style="text-align:center;border: 0px;">
                                    Tipo Parcela
                                </th>
                                <th class="align-middle" style="text-align:center;border: 0px;">
                                    Evento
                                </th>
                                <th class="align-middle" style="text-align:center;border: 0px;">
                                    Nº Parcela
                                </th>
                                <th class="align-middle" style="text-align:center;border: 0px;">
                                    Valor
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="ppg,item in plano_pagamento_visualizar.parcelas" :key="item">
                                <td style="text-align:center;">
                                    {{ ppg.descricaoTipoParcela }}
                                </td>
                                <td style="text-align:center;">
                                    {{ ppg.eventos[0].descricaoEvento }}
                                </td>
                                <td style="text-align:center;">
                                    {{ ppg.numeroParcela }}
                                </td>
                                <td style="text-align:center;">
                                    {{ $root.numberToReal(parseFloat(ppg.eventos[0].valor)) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                </div>
                
            </template>
        </modal-component>
        
        <loading id="carregandoInscricoesWeb" titulo="Buscando Processos de Inscrições Web Ativos no ERP ..." :sem-padding="true" gif-carregando="/imagens/carregando.gif" @cancelaRequisicao="cancelarRequisicao()"></loading>
        <loading id="exportarInscricoesWeb" titulo="Exportando Ofertas para o CRM ..." :sem-padding="true" gif-carregando="/imagens/carregando.gif" @cancelaRequisicao="cancelarRequisicao()"></loading>
  </div>
</template>

<script>
import { throwStatement } from '@babel/types';

export default {
    props:['usuarioLogado','unitie','urlRedirect','filtroEditado','visualizar'],
    data(){
        return{
            dismissSecs: 7,
            dismissCountDown: 0,
            dismissSecsDanger: 7,
            dismissCountDownDanger: 0,
            anoInicio:null,
            dataInicio:null,
            dataTermino:null,
            dataInicioFormat:null,
            dataTerminoFormat:null,
            todos_cursos:[],
            turmasFiltradas:[],
            cod_curso_selecionado:0,
            urlapigvdasa:null,
            tokenapigvdasa:null,
            urlapirubeus:null,
            tokenapirubeus:null,
            filtroPesquisaInscricao:{
                ano:null,
                semestre:null,
                idEmpresa:null,
                idUnidade:null,
                idUnidadePk:null,
                dataInicio:null,
                dataTermino:null,
                idCurso:null,
                curso:null,
                oferta_processo_seletivo:0,
                oferta_Inscricao_web:1,
                inscricao_web_aluno:0,
                importar_parcela:0,
                download_contrato:0,
                somenteativo:0,
            },
            curso_selecionado:{
                CODIGOCURSO:null,
            },
            turma_selecionada:{

            },
            plano_pagamento_visualizar:null,
            semestre_selecionado:"",
            ofertaCursoTurma:{
                ano:null,
                semestre:null,
                idLiberacaoInscricaoCurso:null,
                idNivelEnsino:null,
                descricaoNivelEnsino:null,
                idEstruturaAcademica:null,
                descricaoEstruturaAcademica:null,
                idOrigemMatricula:null,
                descricaoOrigemMatricula:null,
                idLiberacaoInscricaoTurma:null,
                publico:null,
                codCurso:null,
                codOferta:null,
                codigo:null,
                nomeCurso:null,
                descricaoCurso:null,
                nome:null,
                codUnidade:null,
                inicioInscricao:null,
                terminoInscricao:null,
                inicioMatricula:null,
                terminoMatricula:null,
                inicioCurso:null,
                terminoCurso:null,
                codNivelEnsino:2,
                modalidade:null,
                codLocalOferta:null,
                origem:null,
                plano_pagamento:null,
                valor:null,
                vagasMinimo:null,
                vagasMaximo:null

            },
            dadosFiltro:{

            },
            error:{
                msg:null,
            },
            CancelToken:null,
            cancelTokenSource:null,
            msgCancelamento:null,
            msgErro:null,
            success:null
        }
    },
    created(){
        // this.anoInicio = this.anoTurma;
        // 
         
        if(this.unitie.urlapigvdasa!="" && this.unitie.urlapigvdasa){
            this.urlapigvdasa = this.unitie.urlapigvdasa;
        }else if(this.unitie.companies.urlapigvdasa!="" && this.unitie.companies.urlapigvdasa){
            this.urlapigvdasa = this.unitie.companies.urlapigvdasa;
        } 

        if(this.unitie.tokenapigvdasa!="" && this.unitie.tokenapigvdasa){
            this.tokenapigvdasa = this.unitie.tokenapigvdasa;
        }else if(this.unitie.companies.tokenapigvdasa!="" && this.unitie.companies.tokenapigvdasa){
            this.tokenapigvdasa = this.unitie.companies.tokenapigvdasa;
        } 

        if(this.unitie.urlapirubeus!="" && this.unitie.urlapirubeus){
            this.urlapirubeus = this.unitie.urlapirubeus;
        }else if(this.unitie.companies.urlapirubeus!="" && this.unitie.companies.urlapirubeus){
            this.urlapirubeus = this.unitie.companies.urlapirubeus;
        } 

        if(this.unitie.tokenapirubeus!="" && this.unitie.tokenapirubeus){
            this.tokenapirubeus = this.unitie.tokenapirubeus;
        }else if(this.unitie.companies.tokenapirubeus!="" && this.unitie.companies.tokenapirubeus){
            this.tokenapirubeus = this.unitie.companies.tokenapirubeus;
        } 

        if(this.urlapigvdasa!="" && this.tokenapigvdasa!=""){
            this.getCursosERP();
        }

        let dataAtual = new Date();
        if(this.filtroEditado.id>0){
            dataAtual =new Date()
            this.anoInicio = this.filtroEditado.anoliberacaoinscricaocursoweb
            this.cod_curso_selecionado = this.filtroEditado.idcurso
            this.filtroPesquisaInscricao.curso = this.filtroEditado.nomecurso
            
            this.semestre_selecionado = (this.filtroEditado.semestre==null ? '':this.filtroEditado.semestre);
            this.dataInicio = new Date(this.filtroEditado.datainicio)
            this.dataTermino = new Date(this.filtroEditado.datatermino)
        }else{

            this.anoInicio = dataAtual.getFullYear();
            

        }
        this.filtroPesquisaInscricao.idEmpresa = this.unitie.companies.codigoempresa
        this.filtroPesquisaInscricao.idUnidade = this.unitie.codigounidade
        //this.getLiberacaoInscricoesWebERP()
        
    },
    watch:{
        cod_curso_selecionado(new_curso,old_curso){
            if(new_curso!=old_curso){

                for(let i=0;i<this.todos_cursos.length;i++){
                    // console.log(this.todos_cursos[i].CODIGOCURSO) 
                    if(this.todos_cursos[i].CODIGOCURSO==new_curso){
                        this.curso_selecionado=this.todos_cursos[i]
                        this.filtroPesquisaInscricao.curso = this.curso_selecionado.DESCRICAO
                        // console.log('CURSO',this.cursos[i].DESCRICAO)
                    }
                }

                this.cod_curso_selecionado = new_curso
                this.ofertaCursoTurma.curso
                if(new_curso>0){
                    this.filtroPesquisaInscricao.idCurso = new_curso
                }else{
                    this.filtroPesquisaInscricao.idCurso = null
                }
            }
        },
        semestre_selecionado(new_semestre,old_semestre){
            if(new_semestre!=old_semestre){
                this.semestre_selecionado = new_semestre;
                this.filtroPesquisaInscricao.semestre = this.semestre_selecionado;
            }

        },
        dataInicio(newDataInicio,oldDataInicio){
            if(newDataInicio!=oldDataInicio){
                if(this.formatDate(this.dataInicio,1)=='1969-12-31'){
                    // console.log('data',this.dataInicio)
                    this.filtroPesquisaInscricao.dataInicio=null
                    this.dataInicio = null
                }else{
                    this.dataInicio = newDataInicio;
                    this.filtroPesquisaInscricao.dataInicio = this.formatDate(this.dataInicio,1)
                }
            }
        },
        dataTermino(newDataTermino,oldDataTermino){
            if(newDataTermino!=oldDataTermino){
                if(this.formatDate(this.dataTermino,1)=='1969-12-31'){
                    // console.log('data',this.dataInicio)
                    this.filtroPesquisaInscricao.dataTermino=null
                    this.dataTermino = null
                }else{
                        this.dataTermino = newDataTermino;
                        this.filtroPesquisaInscricao.dataTermino = this.formatDate(newDataTermino,1);
                    }
            }
        },
        anoInicio(newAno,oldAno){
            if(newAno!=oldAno){
                this.anoInicio=newAno
                //this.filtroPesquisaInscricao.ano = newAno;
            }
        }
    },
    methods:{
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },
        countDownChangedDanger(dismissCountDownDanger) {
            this.dismissCountDownDanger = dismissCountDownDanger
        },
        showAlertDanger() {
            this.dismissCountDownDanger = this.dismissSecsDanger
        },
        cancelarRequisicao(){
            
            this.cancelTokenSource.cancel("Pesquisa de PSES do ERP cancelada");
            this.msgCancelamento = "Pesquisa de PSES do ERP cancelada"
            
        },
        formatDate(date,idioma) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            if(idioma==1){
                return [year, month, day].join('-');
            }else if(idioma==2){
                return [day, month, year].join('/');
            }else{
                return [year, month, day].join('-');
            }
        },
        getCursosERP(){
            var config = {
                    method: 'get',
                    url: '/api/v1/Erp/Cursos/' + this.unitie.id,
                };
                
                //executa requisição
                axios(config)
                .then(response => {
                    //console.log(response.data.cursosErp)
                    this.todos_cursos = response.data.cursosErp
                    if(this.todos_cursos.length>0){
                        if(this.filtroEditado.id>0){
                            this.getLiberacaoInscricoesWebERP()
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getLiberacaoInscricoesWebERP(){
            $('#carregandoInscricoesWeb').modal('show');
            this.CancelToken = axios.CancelToken;
            this.cancelTokenSource = this.CancelToken.source();
            this.msgCancelamento = null
            var url= '/api/v1/Erp/Inscricoes/' + this.unitie.id;
            this.filtroPesquisaInscricao.somenteativo = 1
            console.log('filtro',this.filtroPesquisaInscricao)
            let formData = new FormData();
            formData.append('filtroPesquisaInscricao',JSON.stringify(this.filtroPesquisaInscricao))
            var config = {
            };
            
                //executa requisição
            axios.post(url,formData,config)
            .then(response => {
                if(response.data.InscricoesErp){
                    this.turmasFiltradas = response.data.InscricoesErp
                    console.log('resposta',response.data.InscricoesErp)
                    this.msgErro = "";
                    this.success = true;
                    $('#carregandoInscricoesWeb').modal('hide');
                }else if(response.data.message){

                    console.log(response.data.message)
                    this.msgErro = response.data.message
                    this.success = false;
                    $('#carregandoInscricoesWeb').modal('hide');
                    

                }else {
                    this.success = false;
                    console.log(response.data)
                    $('#carregandoInscricoesWeb').modal('hide');
                }
                this.showAlert();
                
            })
            .catch(function (error) {
                console.log(error);
                this.turmasFiltradas = [];
                $('#carregandoInscricoesWeb').modal('hide');
            });
        },
        resetarPlanoPagamento(){
            this.plano_pagamento_visualizar = null
            console.log(this.plano_pagamento_visualizar);
        },
        verPlanoPagamento( planosPagamento ){
            this.plano_pagamento_visualizar = planosPagamento
            console.log('plano_pagamento_visualizar',this.plano_pagamento_visualizar)
            $('#MostraPlanoPagamento').modal('show')
        },
        exportarOfertaTurmaRubeus(turma,curso){
            
            $('#exportarInscricoesWeb').modal('show');

            // console.log('Curso',curso)
            // console.log('turma',turma)
            this.ofertaCursoTurma.idLiberacaoInscricaoCurso = curso.idLiberacaoInscricaoCurso;
            this.ofertaCursoTurma.idLiberacaoInscricaoTurma = turma.idLiberacaoInscricaoTurma;
            this.ofertaCursoTurma.ano = turma.anoInicio;
            this.ofertaCursoTurma.semestre = curso.semestre;
            this.ofertaCursoTurma.idNivelEnsino = curso.idNivelEnsino;
            this.ofertaCursoTurma.descricaoNivelEnsino = curso.descricaoNivelEnsino
            this.ofertaCursoTurma.idEstruturaAcademica = turma.idModalidade
            this.ofertaCursoTurma.descricaoEstruturaAcademica = turma.descricaoModalidade
            this.ofertaCursoTurma.idOrigemMatricula = curso.idOrigemMatricula
            this.ofertaCursoTurma.descricaoOrigemMatricula = curso.descricaoOrigemMatricula
            this.ofertaCursoTurma.publico = curso.publico
            this.ofertaCursoTurma.codCurso = curso.idCurso
            this.ofertaCursoTurma.codOferta = curso.idLiberacaoInscricaoCurso+'-'+curso.idCurso+'-'+turma.idCiclo+'-'+turma.idTurma
            this.ofertaCursoTurma.codigo = curso.idLiberacaoInscricaoCurso+'-'+curso.idCurso+'-'+turma.idCiclo+'-'+turma.idTurma
            this.ofertaCursoTurma.nomeCurso = curso.descricaoCurso
            this.ofertaCursoTurma.descricaoCurso = curso.descricaoCurso
            this.ofertaCursoTurma.nome = turma.descricaoofertainscricao
            this.ofertaCursoTurma.complemento = turma.descricaocomplemento
            this.ofertaCursoTurma.codUnidade = this.unitie.companies.codigoempresa + '-' + this.unitie.codigounidade
            this.ofertaCursoTurma.inicioInscricao = turma.dataInicioInscricao
            this.ofertaCursoTurma.terminoInscricao = turma.dataTerminoInscricao
            this.ofertaCursoTurma.inicioMatricula = turma.dataInicio
            this.ofertaCursoTurma.terminoMatricula = turma.dataTermino
            this.ofertaCursoTurma.inicioCurso = turma.dataInicio
            this.ofertaCursoTurma.terminoCurso = turma.dataTermino

            
            this.ofertaCursoTurma.codNivelEnsino = curso.idNivelEnsino;
            this.ofertaCursoTurma.modalidade = turma.modalidade.idmodalidadeexportacao
            this.ofertaCursoTurma.codLocalOferta = this.unitie.companies.codigoempresa + '-' + this.unitie.codigounidade
            this.ofertaCursoTurma.origem = this.unitie.origem
            this.ofertaCursoTurma.vagasMinimo = turma.tamanhoturmaminimo
            this.ofertaCursoTurma.vagasMaximo = turma.tamanhoturmaximo
            this.ofertaCursoTurma.valor = ( turma.planosPagamento.length > 0 ? turma.planosPagamento[0].parcelas[0].eventos[0].valor : 0.00)
            this.ofertaCursoTurma.plano_pagamento = ( turma.planosPagamento.length > 0 ? turma.planosPagamento[0] : null)
             console.log('turma',turma.modalidade.idmodalidadeexportacao);
            let url='/api/v1/Crm/cadastroOferta/'+this.unitie.id
            // console.log(url)
           
            let formData = new FormData();
            formData.append('dadosOferta',JSON.stringify(this.ofertaCursoTurma))

            console.log(this.ofertaCursoTurma)
            
            let configuracao = {
                // method:'post',
                // headers:{
                //     'Accept': 'application/json',
                //     'Authorization': this.tokenapirubeus,
                //     'Rubeus':1
                // }
            }

            axios.post(url,formData,configuracao)
                .then(response => {
                    if(response.data.length>0 && response.data[0].error){
                        console.log(response.data)
                        let erro_msg = JSON.parse(response.data[0].message);
                        this.msgErro = erro_msg.errors
                        this.success = false;
                        this.showAlertDanger();
                        $('#exportarInscricoesWeb').modal('hide');
                    }else{
                        console.log('oferta',response.data)
                        this.success = true;
                        this.ofertaCursoTurma.nome = turma.descricaoofertainscricao
                        $('#exportarInscricoesWeb').modal('hide');
                        $('#ExportacaoCrmMsg').modal('show');
                    }
                })
                .catch(errors => {
                    // this.errors = errors.response.data.errors
                    console.log(errors)
                    $('#exportarInscricoesWeb').modal('hide');
                    this.showAlertDanger();
                })

            
            //console.log(this.ofertaCursoTurma)
            


        }
    }
}
</script>

<style>

</style>