<template>
    <div>
        <div class="div-crud-sistema" style="overflow-x: auto;">
            <div class="row">
                <div class="col" style="text-align: center;font-weight: bold;">
                    <h3><b>NOTAS DO WEBHOOK <a :href="'/Access/Unity/Unitie/'+unitie.id+'/captacao/wh/'+web_hook_nota.id" target="_blank">#{{ web_hook_nota.id }}</a></b></h3><br>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 text-center">
                    <div class="row">
                        <div class="col col-md-2 text-left"></div>
                        <div class="col col-md-8 text-left">
                            <b>TIPO WEBHOOK: </b>{{ (web_hook_nota.tipoeventoaviso=='c' ? 'Inscrição Confirmada' : (web_hook_nota.tipoeventoaviso=='a' ? 'Contrato Assinado' : (web_hook_nota.tipoeventoaviso=='m' ? 'Matriculado' : (web_hook_nota.tipoeventoaviso=='v' ? 'Envia Valor CRM' : (web_hook_nota.tipoeventoaviso=='f' ? 'Atualização Cadastral' : (web_hook_nota.tipoeventoaviso=='EnviaMatriculaCrm' ? 'Enviar Matrícula CRM' : (web_hook_nota.tipoeventoaviso=='d' ? 'Documentos' : 'Outros'))))))) }}<br>
                        </div>
                        <div class="col col-md-2 text-left"></div>
                    </div>
                    <div class="row">
                        <div class="col col-md-2 text-left"></div>
                        <div class="col col-md-8 text-left">
                            <b>CONTATO CRM: </b>{{ web_hook_nota.contatoid }}<br>
                        </div>
                        <div class="col col-md-2 text-left"></div>
                    </div>
                    <div class="row">
                        <div class="col col-md-2 text-left"></div>
                        <div class="col col-md-8 text-left">
                            <b>REGISTRO: </b>{{ web_hook_nota.registroprocesso }}<br>
                        </div>
                        <div class="col col-md-2 text-left"></div>
                    </div>
                    <div class="row">
                        <div class="col col-md-2 text-left"></div>
                        <div class="col col-md-8 text-left">
                            <b>NOME INSCRITO: </b>{{ web_hook_nota.nomeinscrito }}<br>
                        </div>
                        <div class="col col-md-2 text-left"></div>
                    </div>
                    <div class="row">
                        <div class="col col-md-2 text-left"></div>
                        <div class="col col-md-8 text-left">
                            <b>ID OFERTA: </b>{{ web_hook_nota.codigooferta }}<br>
                        </div>
                        <div class="col col-md-2 text-left"></div>
                    </div>
                    <div class="row">
                        <div class="col col-md-2 text-left"></div>
                        <div class="col col-md-8 text-left">
                            <b>OFERTA: </b>{{ oferta.descricaoturma }}<br>
                        </div>
                        <div class="col col-md-2 text-left"></div>
                    </div>
                    <div class="row">
                        <div class="col col-md-2 text-left"></div>
                        <div class="col col-md-8 text-left">
                            <b>ANO/SEMESTRE: </b>{{ oferta.anoinicio }}/{{ oferta.semestreinicio }}<br>
                        </div>
                        <div class="col col-md-2 text-left"></div>
                    </div>
                    <div class="row">
                        <div class="col col-md-2 text-left"></div>
                        <div class="col col-md-8 text-left">
                            <b>TURMA COMPLETO: </b>{{ oferta.descricaocompleta }}<br>
                        </div>
                        <div class="col col-md-2 text-left"></div>
                    </div>
                    <div class="row">
                        <div class="col col-md-2 text-left"></div>
                        <div class="col col-md-8 text-left">
                            <a class="btn btn-primary btn-sm" :href="'https://crmsantamarcelina.apprubeus.com.br/contato/'+web_hook_nota.contatoid+'/registro/'+web_hook_nota.registroprocesso" target="_blank">Ver no CRM</a>
                        </div>
                        <div class="col col-md-2 text-left"></div>
                    </div>
                </div>
            </div>
                <br>
            <div class="row" >
                <div class="col">
                    <table id="conteudo" class="table table-hover table-sm" style="white-space: normal;border-top: 0px;width:100%;">
                        <thead>
                            <tr style="background-color: #1c4d5a;color:white;">
                                <th style="text-align: center;width: 50px;">
                                    Ações
                                </th>
                                <!-- <th style="text-align: center;width: 50px;">
                                    Item
                                </th> -->
                                <th style="text-align: center;">
                                    Nota
                                </th>
                                <th style="text-align: center;width:120px;">
                                    Usuário Leitura
                                </th>
                                <th style="text-align: center;">
                                    Data Nota
                                </th>
                                <th style="text-align: center;">
                                    Data Visualização
                                </th>
                                <th style="text-align: center;">
                                    Tipo
                                </th>
                                <th style="text-align: center;width:50px;">
                                    Lida
                                </th>
                                
                            </tr>
                        </thead>
                        <tbody v-if="notas_webhook && notas_webhook.length>0">
                            <template v-for="nota,item in notas_webhook" >
                                <tr :key="item">
                                    <td style="text-align: center;white-space: nowrap;" class="align-middle">
                                        <a href="#conteudo" class="btn btn-primary btn-sm" style="width: 40px;" @click.prevent="setNotificacaoSelecionada(nota)"><i class="fa-sharp fa-solid fa-eye fa-xl"></i></a>
                                    </td>
                                    <!-- <td class="align-middle" style="text-align: center;font-weight: bold;">
                                        {{ nota.id }}
                                    </td> -->
                                    <td class="align-middle" style="text-align: left;white-space: nowrap!important;width: auto;max-width: 450px;overflow: hidden;text-overflow: ellipsis;">
                                        <div v-if="nota.lida==0" style="font-weight:900;text-align: left; white-space: nowrap !important; width: auto; max-width: 600px; overflow: hidden; text-overflow: ellipsis;" v-html="nota.mensagem"></div><div v-else v-html="nota.mensagem" style="text-align: left; white-space: nowrap !important; width: auto; max-width: 600px; overflow: hidden; text-overflow: ellipsis;"></div>
                                    </td>
                                    
                                    <td class="align-middle" style="text-align: center;font-weight: 900;text-overflow: ellipsis;max-width:200px;overflow: hidden;white-space: nowrap;">
                                        {{ (nota.user && nota.user.nome ? nota.user.nome : '') }}
                                    </td>
                                    <td style="white-space: normal;text-align: center;" class="align-middle">
                                        {{ daraHoraBrasileiro(nota.created_at) }}
                                    </td>
                                    
                                    <td class="align-middle" style="text-align: center;font-weight: 900;">
                                        {{ ( nota.dataleitura!= null ? daraHoraBrasileiro(nota.dataleitura) : '') }}
                                    </td>
                                    <td class="align-middle" style="text-align: center;font-weight: bold;">
                                        <div v-if="nota.tiponotificacao==1">Documentos</div>
                                        <div v-else>Outros</div>
                                    </td>
                                    <td class="align-middle" style="text-align: center;font-weight: bold;text-overflow: ellipsis;max-width:120px;overflow: hidden;white-space: nowrap;">
                                        <!-- <input type="checkbox" id="mensagem_lida" :checked="(nota.lida==1 ? true : false)" @click="SetNotaLida(nota)"> -->
                                        <b-icon v-if="nota.lida==1" icon="check-lg" font-scale="1.2" style="color:green"></b-icon>
                                        <b-icon v-else icon="x-lg" font-scale="1.2" style="color:red"></b-icon>
                                    </td>
                                </tr>
                                

                            </template>
                            <!-- <tr>
                                <td colspan="10" v-html="nota_selecionada.mensagem">

                                </td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        
        <modal-component titulo="VISUALIZADOR" label-btn-fechar="Fechar" id="visualizadorNotas" url-redirect="" :sem-padding="true">
            <template v-slot:conteudo>
                <div class="card">
                    <div class="card-header">
                        <b style="font-size: 12px;">VISUALIZADOR DE NOTAS</b>
                    </div>
                    <div class="card-body" v-html="nota_selecionada.mensagem" style="font-size: 14px;text-align: left;">
                        
                    </div>
                </div>                
            </template>
        </modal-component>
    </div>
</template>

<script>
export default {
    props:['unitie','usuarioLogado','webhook','notas','oferta'],
    data(){
        return{
            nota_selecionada:[],
           
            web_hook_nota:{},
            notas_webhook:[]
            
        }
    },
    methods:{
        daraHoraBrasileiro(date){
            let dataHora = new Date(date)
            // let dataHoraUtc = new Date()
            return dataHora.toLocaleString('pt-BR', { timezone: 'UTC' })
        },
        setNotificacaoSelecionada(nota){
            this.nota_selecionada = nota
            this.SetNotaLida(nota)
            $('#visualizadorNotas').modal('show');
        },
        SetNotaLida(nota){
            //window.alert("Marcar Nota como Lida")
            

            console.log(this.unidade);

            // if(exibe_loading==1){

            //     $('#WebHooks'+this.unitie.id).modal('show');

            // }
            //this.url_requisicao = this.url_requisicao+this.unitie.id

            console.log('URL: ' + '/api/v1/Access/Unity/Unitie/'+this.unitie.id+'/notificacoes/'+nota.id)
            var config = {
                method: 'get',
                url: '/api/v1/Access/Unity/Unitie/'+this.unitie.id+'/notificacoes/'+nota.id,
                headers: {
                        'CSRF_TOKEN': 'TESTE',
                //         'Authorization': this.$root.token(),
                    },
                };
                //executa requisição
                axios(config)
                .then(response => {

                    if(response.data){
                        if(response.data.sucesso && response.data.sucesso==true){
                            // nota.lida = 1;

                            if(this.notas_webhook && this.notas_webhook.length>0){
                                for(let i = 0; i<this.notas_webhook.length;i++){
                                    if(this.notas_webhook[i].id==response.data.nota.id){
                                        console.log(response.data.nota)
                                        this.notas_webhook[i].lida = response.data.nota.lida;
                                        this.notas_webhook[i].dataleitura= response.data.nota.dataleitura
                                        this.notas_webhook[i].codigousuarioleitura= response.data.nota.codigousuarioleitura
                                        this.notas_webhook[i].user.nome = response.data.nota.user.nome
                                    }
                                }
                            }
                            nota = response.data.nota
                        }else{}
                        
                        console.log('sus',response.data.nota)
                        
                        
                    }else{
                        window.alert("Houve um problema")
                    }

                    // console.log('web_hook_notas',this.web_hook_notas)
                    // if(exibe_loading==1){
                    //     if(this.unidade!=null){
                    //         $('#WebHooks'+this.unidade.id).modal('hide');
                    //     }
                    // }
                })
                .catch(function (error) {
                    console.log('error',error);
                    window.alert(error)
                    // if(exibe_loading==1){
                    //     if(this.unidade!=null){

                    //         $('#WebHooks'+this.unidade.id).modal('hide');

                    //     }else{
                    //         document.getElementById('logout-form').submit();
                    //     }
                    // }
                    //this.erro = error
                    // console.log(error);
                });
        }

    },
    created(){ 
        this.web_hook_nota = this.webhook;
        this.notas_webhook = this.notas
    },
    mounted(){
        
    }
}
</script>

<style scoped>
    .div-crud-sistema{
            background: white;
            padding: 0px;
            padding-top: 10px;
            border-radius: 0px 0px 10px 10px;
            
    }

    .nav-item{
        
        text-align: center;
        border-radius: 10px 10px 10px 10px!important;
    }

    .tab-pane{
        overflow: auto;
        width: 100%;
    }
    .div-crud-sistema > .col-2 > .nav-pills > .nav-link,
    .div-crud-sistema > .nav > .nav-item > .nav-link{
        color:black!important;
        border-radius: 10px 10px 0px 0px!important;
        border: 1px solid #1c4d5a;
        
        height: 100%;
        padding-left: 10px;
    }

    .div-crud-sistema > .row > .col-2 > .nav-pills > .active,
    .div-crud-sistema > .nav > .nav-item > .active{
        color:white!important;
        background-color:#1c4d5a!important;
        border-radius: 10px 10px 0px 0px!important;
        border: 1px solid #1c4d5a;
        
        height: 100%;
        padding-left: 10px;
    }

    .tab-content > .active {
        background-color:#fff!important;
        border: 1px solid #1c4d5a;
    }

    #td-html > p {
        margin:0px!important;
    }

    .nav-tabs {
        border-bottom: 5px solid #1c4d5a;
    }

    .table thead th {
        vertical-align: bottom;
        border: 0px solid #1c4d5a;
    }

    p{
        margin-bottom:0px;
        max-width:50px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .btn-primary.disabled{
        background-color: #5d7773!important;

        border-color: #5d7773!important;
    }
    
    
</style>
