<template>
  <div role="group" class="form-group" :id="id+'div'">
      <label :for="para" class="d-block" :id="id+'Label'">
          {{ label }}
        </label>
        <div>
            <slot name="inputs">
            </slot>
        </div>
  </div>
</template>

<script>
export default {
    props:['label','para','id']
}
</script>

<style scoped>
    label{
        text-align: left;
    }
</style>
