<template>
    <div class="container-fluid" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
        <div class="row justify-content-center">
            <div class="col-md-12 conteudo">
                <div class="card">
                    <div class="card-header" @click="exibirOcultar()" style="cursor:pointer;">
                        <table style="width:100%;">
                            <tr>
                                <td style="width:2%;text-align:center;padding:5px;">
                                    <b-icon v-if="padrao" icon="zoom-out" font-scale="1.3"></b-icon>
                                    <b-icon v-else icon="zoom-in" font-scale="1.3"></b-icon>
                                </td>
                                <td :style="estiloCabecalho" id="cabecalho">
                                    <b>
                                        <slot name="cabecalho">
                                        </slot>
                                    </b>
                                </td>
                            </tr>
                        </table>
                        <h4>
                        </h4>
                    </div>
                    <div class="card-body" v-show="padrao" :style="estiloConteudo">
                        <slot name="conteudo">
                        </slot>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:{
            padraoExibicao:{type:Boolean,required:true},
            id:{type:String,required:true},
            estilo:{type:String,required:false},
            estiloBody:{type:String,required:false},
            
        },
        data(){
            return {
                padrao:true,
            }
        },
        computed:{
            estiloCabecalho(){

                return (this.estilo && this.estilo!='' ? "width:98%;"+this.estilo : "width:98%;text-align:center;padding:5px;font-size:14pt!important;")
            },
            estiloConteudo(){

                return (this.estiloBody && this.estiloBody!='' ? this.estiloBody : "padding:0px;overflow-x: auto;")
            },
        },
        methods:{
            exibirOcultar(){
                this.padrao=!this.padrao
            }
        },
        created(){
            this.padrao = this.padraoExibicao
        }
        /*mounted() {
            console.log(user);
        },*/
        // props:{
        //     user:{type: Object, required:true},
        // },
        // created() {
        //     //console.log(this.user);
        // },
    }
</script>
<style scoped>
    .container-fluid{
            padding-right: 0px;
            padding-left: 0px;
    }

    .card{
            text-align: center;
            border: 1px solid #1c4d5a33;
            -webkit-box-shadow: 3px 5px 5px #1c4d5a75;
            -moz-box-shadow:    3px 5px 5px #1c4d5a75;
            box-shadow:         3px 5px 5px #1c4d5a75;


        }
    .card-header{
        background-image: linear-gradient( to right, #1c4d5a, #1a7580,#1c4d5a );
        color: white;
        padding: 2px 12px 2px!important;
    }
    h4{
        margin-bottom: 0rem;
        font-weight: 600;
    }

    @media(max-width:600px){
        .conteudo{
            padding-left: 2px!important;
            padding-right: 2px!important;
        }

        .card-body {
            padding: 0px!important;
        }
        
        .container-fluid{
            padding-right: 15px;
            padding-left: 15px;
    }
    }
</style>
