<template>
    <div v-if="filtro" class="modal show" id="ExclusaoPessoas" tabindex="-1" role="dialog" :aria-labelledby="'ExclusaoPessoasLabel'" aria-hidden="true" @click="fechar()">
        <div class="modal-dialog  modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body" v-if="semPadding" style="padding:0!important">
                    <div v-if="filtro.anoliberacaoinscricaocursoweb">
                        <table class="table table-sm table-hover" style="margin: 0px;">
                            <thead style="background:#1c4d5a!important;color:white;white-space: nowrap;">
                                <tr>
                                    <th colspan="2" class="align-middle" style="text-align:center;border: 0px;">
                                        MENSAGEM
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colspan="2" style="padding:0px;">
                                        <div class="alert alert-success" role="alert" style="margin:0px;text-align: center;" v-if="filtroExcluido.status=='OK'">
                                            {{ filtroExcluido.mensagem }}
                                        </div>
                                        <div class="alert alert-danger" role="alert" style="margin:0px;text-align: center;" v-if="filtroExcluido.status=='NOK'">
                                            {{ filtroExcluido.mensagem }}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" style="text-align:left;">
                                        <b>Ano Inscrição:</b> {{ filtro.anoliberacaoinscricaocursoweb }}
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" style="text-align:left;">
                                        <b>Curso:</b> {{ filtro.nomecurso }}                                        
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" style="text-align:left;" v-if="filtro.semestre==0">
                                        <b>Periodo:</b> Anual                                       
                                    </td>
                                    <td colspan="2" style="text-align:left;" v-else-if="filtro.semestre==1">
                                        <b>Periodo:</b> 1º Semestre                                       
                                    </td>
                                    <td colspan="2" style="text-align:left;" v-else-if="filtro.semestre==2">
                                        <b>Periodo:</b> 2º Semestre                                            
                                    </td>
                                    <td colspan="2" style="text-align:left;" v-else>
                                        <b>Periodo:</b> Todos
                                    </td>
                                </tr>
                                <tr v-if="filtro.datainicio || filtro.datatermino">
                                    <td style="text-align:left;" v-if="filtro.datainicio" :colspan="filtro.datainicio ? '1' :'2'">
                                        <b>Data Inicio:</b> {{ formatDate(filtro.datainicio,2) }}
                                    </td>
                                    <td style="text-align:left;" v-if="filtro.datatermino" :colspan="filtro.datatermino ? '1' :'2'">
                                        <b>Data Término:</b> {{ formatDate(filtro.datatermino,2) }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-body" v-else style="padding:0!important">
                    <div v-if="filtro.anoliberacaoinscricaocursoweb">
                        <table class="table table-sm table-hover" style="margin: 0px;">
                            <thead style="background:#1c4d5a!important;color:white;white-space: nowrap;">
                                <tr>
                                    <th colspan="2" class="align-middle" style="text-align:center;border: 0px;">
                                        MENSAGEM
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colspan="2" style="padding:0px;">
                                        <div class="alert alert-success" role="alert" style="margin:0px;text-align: center;">
                                            Filtro cadastrado com sucesso.
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" style="text-align:left;">
                                        <b>Ano Inscrição:</b> {{ filtro.anoliberacaoinscricaocursoweb }}
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" style="text-align:left;">
                                        <b>Curso:</b> {{ filtro.nomecurso }}                                        
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" style="text-align:left;" v-if="filtro.semestre==0">
                                        <b>Periodo:</b> Anual                                       
                                    </td>
                                    <td colspan="2" style="text-align:left;" v-if="filtro.semestre==1">
                                        <b>Periodo:</b> 1º Semestre                                       
                                    </td>
                                    <td colspan="2" style="text-align:left;" v-if="filtro.semestre==2">
                                        <b>Periodo:</b> 2º Semestre                                            
                                    </td>
                                </tr>
                                <tr v-if="filtro.datainicio || filtro.datatermino">
                                    <td style="text-align:left;" v-if="filtro.datainicio" :colspan="filtro.datainicio ? '1' :'2'">
                                        <b>Data Inicio:</b> {{ formatDate(filtro.datainicio,2) }}
                                    </td>
                                    <td style="text-align:left;" v-if="filtro.datatermino" :colspan="filtro.datatermino ? '1' :'2'">
                                        <b>Data Término:</b> {{ formatDate(filtro.datatermino,2) }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button v-if="!filtroExcluido.status" type="button" class="btn btn-danger" :id="'btnOkExclusaoFiltros'" @click="excluirFiltro(filtro)">Excluir</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" :id="'Fechar'" @click="fechar()">Fechar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        urlRedirect:{type:String, requied:true},
        filtro:{type:Object, requied:false},
        urlSistema:{type:String, requied:true},
        semPadding:{type:Boolean,required:false}
        
    },
    
    data(){
        return {
            filtroSelecionado:{},
            filtroExcluido:{},
        }
    },
    created(){
         this.filtroSelecionado = this.filtro
    },
    methods:{
        redirect(){
            window.location.href = this.urlRedirect;
        },
        fechar(){
            this.filtroExcluido = {}
        },
        excluirFiltro(filtro){
            console.log('/api/v1'+this.urlSistema+'/'+filtro.id);
            var config = {
            method: 'delete',
            url: '/api/v1'+this.urlSistema+'/'+filtro.id,
            // headers: {
            //         'Accept': 'application/json',
            //         'Authorization': this.$root.token(),
            //     },
            };
            //executa requisição
            axios(config)
            .then(response => {

                this.filtroExcluido = response.data;
                console.log('Exclusai',this.filtroExcluido);
                this.$emit('exclusaoconcluida' /*, this.nclick*/);
                
            })
            .catch(function (error) {
                console.log(error);
            });

            $("#ExclusaoFiltro").modal('');
        },
        formatDate(date,idioma) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            if(idioma==1){
                return [year, month, day].join('-');
            }else if(idioma==2){
                return [day, month, year].join('/');
            }else{
                return [year, month, day].join('-');
            }
        },
        
    }
}
</script>

<style>
    
</style>
