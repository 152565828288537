<template>
    <div>
        <div class="form-row">
            <div class="form-group col-md-12 text-center">
                <br/><h5>{{ titulo }}</h5>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-md-12">
                <div :class="'alert alert-'+variant" role="alert" style="font-size: 11px;">
                    <b>OBSERVAÇÕES:</b><br/>
                    <b>1 - </b>A separação entre as macros deve ser unica e exclusivamente com o caracter <b>"-"</b><br/>
                    <b>2 - </b>Devem estar em <b>caixa-alta/maiuscula</b><br/>
                    <b>3 - As alterações de Máscaras de Ofertas de Inscrição e PSE realizadas terão impacto imediato para as próximas Integrações</b><br/>
                    <hr>
                    <b>EXEMPLOS:</b><br/>
                    <b>1 - </b>ANOINICIO<b>-</b>CURSODOCUMENTO<b>-</b>PRODUTOOUCICLO<b>-</b>TURNO<br><b>Resultado: 2023 - Ensino Fundamental - 5º Ano - Manhã</b><br/><br/>
                    <b>2 - </b>ANOINICIO<b>-</b>CURSODOCUMENTO<b>-</b>PRODUTOOUCICLO<b>-</b>DESCRICAO<br><b>Resultado: 2023 - Ensino Fundamental - 5º Ano - 92M</b><br/><br/>
                    <b>3 - </b>ANOINICIO<b>-</b>SEMESTREINICIO<b>-</b>CURSODOCUMENTO<b>-</b>PRODUTOOUCICLO<b>-</b>DESCRICAO<br><b>Resultado: 2023/1 - Curso de Administração - 1º Semestre - ADM1</b><br/><br/>
                    <b>4 - </b>CURSODOCUMENTO<b>-</b>PRODUTOOUCICLO<b>-</b>DESCRICAO<br><b>Resultado: Curso de Administração - 1º Semestre - ADM1</b><br/>
                    <hr>
                    
                    <b>MACROS DISPONÍVEIS:</b>
                    <div class="row">
                        <div class="col-md-3">
                            <b>ANOINICIO</b> - Ano inicio da Oferta<br/>
                            <b>SEMESTREINICIO</b> - Semestre de inicio da Oferta<br/>
                            <b>CURSODOCUMENTO</b> - Nome Documento do Curso para Oferta
                        </div>
                        <div class="col-md-3">
                            <b>CURSO</b> - Nome do Curso para Oferta<br/>
                            <b>DESCRICAO</b> - Descrição da Turma para Oferta   <br/>                                 
                            <b>PRODUTOOUCICLO</b> - Etapa da Oferta
                        </div>
                        <div class="col-md-3">
                            <b>TURNO</b> - Turno da Oferta<br/>
                            <b>SALA</b> - Sala da Oferta<br/>
                            <b>CARGAHORARIAPRODUTO</b> - Carga Horária da Oferta
                        </div>
                        <div class="col-md-3">
                            <b>SITUACAOTURMA</b> - Situação Turma da Oferta<br/>
                            <b>MODALIDADE</b> - Modalidade da Oferta
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    props:['titulo','cor'],
    computed:{
        variant(){
            return this.cor
        }
    }
}
</script>

<style scoped>
   
</style>
