<template>
    <div class="modal show" data-backdrop="static" :id="id" tabindex="-1" role="dialog" :aria-labelledby="id+'Label'" aria-hidden="true" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;background-color: #1c4d5aa8!important;">
        <div class="modal-dialog  modal-dialog-centered" role="document">
           <!--  <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="TituloModalLabel" v-html="titulo"></h5>
                </div>
                <div class="modal-body text-center" v-if="semPadding" style="padding:0!important;background-color: #1c4d5a;">
                    <img :src="gifCarregando"  width="100%" />
                </div>
                <div class="modal-body text-center" v-else style="background-color: #1c4d5a">
                    <img :src="gifCarregando" width="100%" />
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" :id="'btnOk'+id" @click="cancelaRequisicao()">Cancelar</button>
                </div> 
            </div>-->
            <table style="width:100%!important;" class="table">
                <tr>
                    <td style="width:10%!important;border:0px;">&nbsp;</td>
                    <td style="text-align:center;padding:5px;border:0px;" class="align-middle">
                        <socket size="100%" color="#55fafa" secondary-color="#55fafa"></socket>                        
                    </td><td style="width:10%!important;border:0px;">&nbsp;</td>
                </tr>
                <tr>
                    <td style="width:10%!important;border:0px;">&nbsp;</td>
                    <td style="text-align:center;border:0px;" class="align-middle">
                        <img src="/imagens/Logo_educonnect_crm.png" width="250px" height="auto" alt="" class="float-center">
                    </td>
                    <td style="width:10%!important;border:0px;">&nbsp;</td>
                </tr>
                <tr>
                    <td style="width:10%!important;border:0px;">&nbsp;</td>
                    <td style="color:#1c4d5a!important;text-align:center;font-weight:bold;border:0px;background-color:#55fafa;border-radius:20px;" class="align-middle" v-html="titulo">                                                
                    </td>
                    <td style="width:10%!important;border:0px;">&nbsp;</td>
                </tr>
                </table>
        </div>
    </div>
</template>

<script>
export default {
    props:['id','titulo','semPadding','gifCarregando'],
    methods:{
        cancelaRequisicao(){
            this.$emit('cancelaRequisicao');
        },
        // redirect(){
        //     if(this.urlRedirect && this.urlRedirect!=''){
        //         window.location.href = this.urlRedirect;
        //     }
        // }
    }
}
</script>

<style>
    
</style>
