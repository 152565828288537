<template>
    <div >
        <div :id="'Div' + nomeFormulario" :name="'Div' + nomeFormulario" class="div-crud-sistema">
            <form :method="metodoFormulario" :action="linkAction" :id="nomeFormulario" :name="nomeFormulario">
                <div v-if="!edit">
                    <slot name="csrf">

                    </slot>
                </div>
                <input type="hidden" name="id_empresa" :value="curso.id">
                {{ curso }}
                <div>
                    <div class="form-row">
                        <div class="form-group col-md-2">
                            <label for="inputCodigoEmpresa">Codigo Curso</label>
                            <input :readonly="visualizar" v-if="!errors.codigoempresa" type="text" class="form-control" id="inputCodigoEmpresa" name="inputCodigoEmpresa" placeholder="Código Empresa" v-model="curso.codigocurso">
                            <input :readonly="visualizar" v-else type="text" class="form-control is-invalid" id="inputCodigoEmpresa" name="inputCodigoEmpresa" placeholder="Código Empresa" v-model="curso.codigoempresa">
                            <div class="invalid-feedback" v-if="errors.codigocurso">
                                {{ errors.codigocurso[0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-2">
                            <label for="inputCodigoEmpresa">Codigo Identificação</label>
                            <input :readonly="visualizar" v-if="!errors.codigoempresa" type="text" class="form-control" id="inputCodigoEmpresa" name="inputCodigoEmpresa" placeholder="Código Empresa" v-model="curso.codigoidentificacao">
                            <input :readonly="visualizar" v-else type="text" class="form-control is-invalid" id="inputCodigoEmpresa" name="inputCodigoEmpresa" placeholder="Código Empresa" v-model="curso.codigoempresa">
                            <div class="invalid-feedback" v-if="errors.codigocurso">
                                {{ errors.codigocurso[0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="inputRazaoSocial">Nome Completo</label>
                            <input :readonly="visualizar" v-if="!errors.nomecompleto" type="text" class="form-control" id="inputRazaoSocial" name="inputRazaoSocial" placeholder="Nome Completo" v-model="curso.descricaocurso">
                            <input :readonly="visualizar" v-else type="text" class="form-control is-invalid" id="inputRazaoSocial" name="inputRazaoSocial" placeholder="Nome Completo" v-model="curso.descricaocurso">
                            <div class="invalid-feedback" v-if="errors.descricaocurso">
                                {{ errors.descricaocurso[0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="inputNomeFantasia">Nome Reduzido</label>
                            <input :readonly="visualizar" v-if="!errors.nomereduzido" type="text" class="form-control" id="inputNomeFantasia" name="inputNomeFantasia" placeholder="Nome Reduzido" v-model="curso.descricaodocumento">
                            <input :readonly="visualizar" v-else type="text" class="form-control is-invalid" id="inputNomeFantasia" name="inputNomeFantasia" placeholder="Nome Reduzido" v-model="curso.descricaodocumento">
                            <div class="invalid-feedback" v-if="errors.descricaodocumento">
                                {{ errors.descricaodocumento[0] }}
                            </div>
                        </div>
                        
                        
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="inputCelular">Período</label>
                            <input :readonly="visualizar" type="text" v-if="!errors.periodo"  class="form-control" id="inputEmail" name="inputEmail" placeholder="E-mail " v-model="curso.periodo">
                            <input :readonly="visualizar" type="text" v-else class="form-control is-invalid" id="inputEmail" name="inputEmail" placeholder="E-mail " v-model="curso.periodo">
                            <div class="invalid-feedback" v-if="errors.periodo">
                                {{ errors.periodo[0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="tipocurso">Tipo Curso</label>
                            <input :readonly="visualizar" type="text" v-if="!errors.tipocurso"  class="form-control" id="tipocurso" name="tipocurso" placeholder="Tipo Curso" v-model="curso.tipocurso">
                            <input :readonly="visualizar" type="text" v-else class="form-control is-invalid" id="tipocurso" name="tipocurso" placeholder="Tipo Curso" v-model="curso.tipocurso">
                            <div class="invalid-feedback" v-if="errors.tipocurso">
                                {{ errors.tipocurso[0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-2" >
                            <label for="inputCodigoEmpresa">Ativo</label><br/>
                                <b-form-checkbox
                                    id="cadastroAtivo"
                                    v-model="curso.ativo"
                                    name="cadastroAtivo"
                                    :value="'Ativo'"
                                    :unchecked-value="'Inativo'"
                                    :disabled="visualizar"
                                    :switch="true"
                                    style="vertical-align: bottom !important;"
                                ></b-form-checkbox>
                        </div>
                    </div>
                    <div class="form-row" v-if="users.length>0">
                    </div>
                </div>
                <br/>
                <a :href="'/'+urlSistema" class="btn btn-primary">Cancelar</a>
                <button :disabled="visualizar" type="button" class="btn btn-primary" @click="insereOuAtualiza()">Salvar</button>
                <button :disabled="visualizar || curso.idexportacaocrm > 0 || curso.id==''" type="button" class="btn btn-primary" @click="exportarEmpresaCrm(curso)" v-if="curso.tokenapirubeus && curso.tokenapirubeus">Exportar para Rubeus</button>
            </form>
        </div>
        <modal-component titulo="Mensagem do Sistema" label-btn-fechar="OK" id="MensagemSistema" :url-redirect="'/'+urlSistema+'/'" :sem-padding="true">
            <template v-slot:titulo>
            </template>
            <template v-slot:conteudo>
                <table class="table table-sm table-hover" style="margin: 0px;">
                    <thead style="background:#1c4d5a!important;color:white;white-space: nowrap;">
                        <tr>
                            <th colspan="2" style="text-align:center;border: 0px;">
                                EMPRESA
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="2" style="padding:0px;">
                                <div class="alert alert-success" role="alert" style="margin:0px;text-align: center;">
                                    <b v-if="edit">Atualizado com sucesso!</b>
                                    <b v-else>Cadastrado com sucesso!</b>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="text-align:left;">
                                <b>ID:</b> {{ curso.id }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="text-align:left;">
                                <b>NOME:</b> {{ curso.nomecompleto }}                                    
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="text-align:left;">
                                <b>CNPJ:</b> {{ curso.cnpj }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </modal-component>
        <modal-component titulo="Exportação CRM" label-btn-fechar="OK" id="ExportacaoCrmMsg" :url-redirect="'/'+urlSistema+'/'" :sem-padding="true">
            <template v-slot:titulo>
                Exportação CRM
            </template>
            <template v-slot:conteudo>
                <table class="table table-sm table-hover ">
                    <thead style="background:#1c4d5a!important;color:white;white-space: nowrap;">
                        <tr>
                            <th colspan="2" style="text-align:center;">
                                EMPRESA
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="2" style="padding:0px;">
                                <div class="alert alert-success" role="alert" style="margin:0px;text-align: center;">
                                    <b>Empresa Exportada para o CRM</b>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="text-align:left;">
                                <b>Código:</b> {{ empresa_exportada_crm.codigoempresa }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="text-align:left;">
                                <b>Id Exportação:</b> {{ empresa_exportada_crm.idexportacaocrm }}                                   
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="text-align:left;">
                                <b>Descrição Unidade:</b> {{ empresa_exportada_crm.nomecompleto }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </modal-component>
        <div :id="'DivTable' + nomeFormulario" :name="'DivTable' + nomeFormulario" class="div-table-sistema">
            <div class="table-crud-sistema">
                <table class="table table-sm table-hover ">
                    <thead style="background:#1c4d5a!important;color:white;">
                        <tr>
                            <th colspan="10" style="text-align:center;">
                                Turmas vinculadas ao Curso
                            </th>
                        </tr>
                        <tr>
                            <th style="text-align:center;">
                                Oferta
                            </th>
                            <th style="text-align:center;">
                                Ano
                            </th>
                            <th style="text-align:center;">
                                Descrição Turma
                            </th>
                            <th style="text-align:center;">
                                Descrição Ciclo
                            </th>
                            <th style="text-align:center;">
                                Inicio
                            </th>
                            <th style="text-align:center;">
                                Término
                            </th>
                            <th style="text-align:center;">
                                Inicio Inscrição
                            </th>
                            <th style="text-align:center;">
                                Término Inscrição
                            </th>
                            <th style="text-align:center;">
                                Tamanho
                            </th>
                            <th style="text-align:center;">
                                Situação
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="turma,i in curso.school_classes" :key="i">
                            <td style="text-align:center;">
                                {{ turma.codigooferta }}
                            </td>
                            <td style="text-align:center;">
                                {{ turma.anoinicio }}
                            </td>
                            <td style="text-align:center;">
                                {{ turma.descricaoturma }}
                            </td>
                            <td style="text-align:center;">
                                {{ turma.descricaociclo }}
                            </td>
                            <td style="text-align:center;">
                                {{ formatDate(turma.datainicio,2) }}
                            </td>
                            <td style="text-align:center;">
                                {{ formatDate(turma.datatermino,2) }}
                            </td>
                            <td style="text-align:center;">
                                {{ formatDate(turma.datainicioinscricao,2) }}
                            </td>
                            <td style="text-align:center;">
                                {{ formatDate(turma.dataterminoinscricao,2) }}
                            </td>
                            <td style="text-align:center;">
                                {{ (turma.tamanhoturmaminimo>=0 ? 'MIN: ' + turma.tamanhoturmaminimo :'') }} {{ (turma.tamanhoturmaximo>=0 ? 'MÁX: ' + turma.tamanhoturmaximo :'') }}
                            </td>
                            <td style="text-align:center;">
                                {{ turma.situacaoturma }}
                            </td>
                        </tr>
                    </tbody>
    
                </table>
            </div>
        </div>
    </div>
</template>

<script>


export default{

    methods:{
        dissmiss(id){
            this.$bvModal.hide(id);
        },
        novo(id){
            this.user = {}
            this.modoeditar = false
            this.modoexcluir = false
            this.modonovo = true
            this.texto_modal = "Novo"
            this.user.id=id
            // console.log(this.user)
        },
        editar(usuarioSelecionado){
            this.user = usuarioSelecionado;
            this.modoeditar = true
            this.modoexcluir = false
            this.modonovo = false
            this.texto_modal = "Editar"
            // console.log(this.user)
            // console.log(this.user)
        },
        excluir(usuarioSelecionado){
            this.user = usuarioSelecionado;
            this.modoeditar = false
            this.modoexcluir = true
            this.modonovo = false
            this.texto_modal = "Excluir"
            // console.log(this.user)
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.nameState = valid
            return valid
        },
        resetModal() {
            this.name = ''
            this.nameState = null
        },
        removerPorId: function (id) {
            var _vm = this; //Adicionando a instância à uma variável, para que possa ser acessada dentro do forEach`
            this.users.forEach(function(el, index) {
                if(el.id === id)
                    _vm.users.splice(index, 1);
            })
        },
        insereOuAtualiza(){
            if(!this.edit){
                this.inserirCompany();
                console.log('insere')
            }else{
                this.atualizaCompany();
                console.log('atualiza')
            }
        },
        inserirCompany(){
            // if(this.modonovo){
                let url='/api/v1/'+this.urlSistema;
                console.log(url)
                let formData = new FormData();
                formData.append('tipocadastro', this.tipoCadastro)
                formData.append('codigoempresa', (this.curso.codigoempresa?this.curso.codigoempresa:''))
                formData.append('nomecompleto', (this.curso.nomecompleto?this.curso.nomecompleto:''))
                formData.append('cnpj', (this.curso.cnpj?this.curso.cnpj:''))
                formData.append('periodo', (this.curso.periodo?this.curso.periodo:''))
                formData.append('tipocurso', (this.curso.tipocurso?this.curso.tipocurso:''))
                formData.append('celular', (this.curso.celular?this.curso.celular:''))
                formData.append('logradouro', (this.curso.logradouro?this.curso.logradouro:''))
                formData.append('numero', (this.curso.numero?this.curso.numero:''))
                formData.append('complemento', (this.curso.complemento?this.curso.complemento:''))
                formData.append('bairro', (this.curso.bairro?this.curso.bairro:''))
                formData.append('cidade', (this.curso.cidade?this.curso.cidade:''))
                formData.append('estado', (this.curso.estado && this.curso.estado != '0' ?this.curso.estado:''))
                formData.append('cep', (this.curso.cep?this.curso.cep:''))
                formData.append('urlapirubeus', (this.curso.urlapirubeus?this.curso.urlapirubeus:''))
                formData.append('tokenapirubeus', (this.curso.tokenapirubeus?this.curso.tokenapirubeus:''))
                formData.append('urlapigvdasa', (this.curso.urlapigvdasa?this.curso.urlapigvdasa:''))
                formData.append('tokenapigvdasa', (this.curso.tokenapigvdasa?this.curso.tokenapigvdasa:''))
                formData.append('ativo',(this.curso.ativo=='Ativo'?'1':'0'))
                if(this.tipoCadastro==2){
                    formData.append('nomereduzido', (this.curso.nomereduzido?this.curso.nomereduzido:''))
                }else{
                    formData.append('nomereduzido', '')
                }
                formData.append('email',(this.curso.email?this.curso.email:''))

                let configuracao = {
                    // method:'post',
                    // headers:{
                    //     // // 'Accept': 'application/json',
                    //     // // 'Authorization': this.$root.token(),
                    // }
                }
                
                axios.post(url,formData,configuracao)
                    .then(response => {
                        this.transacaoStatus = response.status
                        this.curso = response.data.usuarioNovo;
                        this.errors={};
                        this.curso.CadastroTipo=2
                        $('#MensagemSistema').modal('show');
                    })
                    .catch(errors => {
                        this.errors = errors.response.data.errors
                        console.log(errors.response)
                    })
            // }
        },
        atualizaCompany(){
            // if(this.modonovo){
                let url='/api/v1/'+this.urlSistema+'/'+this.curso.id;
                console.log(url)
                //console.log(this.curso)                                  
                let formData = new FormData();
                formData.append('_method','patch');
                formData.append('tipocadastro', this.tipoCadastro)
                formData.append('codigoempresa', (this.curso.codigoempresa?this.curso.codigoempresa:''))
                formData.append('nomecompleto', (this.curso.nomecompleto?this.curso.nomecompleto:''))
                formData.append('cnpj', (this.curso.cnpj?this.curso.cnpj:''))
                formData.append('email', (this.curso.email?this.curso.email:''))
                formData.append('tipocurso', (this.curso.tipocurso?this.curso.tipocurso:''))
                formData.append('celular', (this.curso.celular?this.curso.celular:''))
                formData.append('logradouro', (this.curso.logradouro?this.curso.logradouro:''))
                formData.append('numero', (this.curso.numero?this.curso.numero:''))
                formData.append('complemento', (this.curso.complemento?this.curso.complemento:''))
                formData.append('bairro', (this.curso.bairro?this.curso.bairro:''))
                formData.append('cidade', (this.curso.cidade?this.curso.cidade:''))
                formData.append('estado', (this.curso.estado && this.curso.estado != '0' ?this.curso.estado:''))
                formData.append('cep', (this.curso.cep?this.curso.cep:''))
                formData.append('urlapirubeus', (this.curso.urlapirubeus?this.curso.urlapirubeus:''))
                formData.append('tokenapirubeus', (this.curso.tokenapirubeus?this.curso.tokenapirubeus:''))
                formData.append('urlapigvdasa', (this.curso.urlapigvdasa?this.curso.urlapigvdasa:''))
                formData.append('tokenapigvdasa', (this.curso.tokenapigvdasa?this.curso.tokenapigvdasa:''))
                formData.append('ativo',(this.curso.ativo=='Ativo'?'1':'0'))
                if(this.responsavel_id>0){
                    formData.append('responsavel_id', (this.responsavel_id))
                }
                if(this.tipoCadastro==2){
                    formData.append('nomereduzido', (this.curso.nomereduzido?this.curso.nomereduzido:''))
                }else{
                    formData.append('nomereduzido', '')
                }
                formData.append('email',(this.curso.email?this.curso.email:''))
                
                let configuracao = {
                    // method:'put',
                    // headers:{
                    // //     'Accept': 'application/json',
                    // //     'Authorization': this.$root.token()
                    // },
                }
                
                axios.post(url, formData,configuracao)
                    .then(response => {
                        // console.log(response)
                        this.transacaoStatus = response.status
                        this.curso = response.data.usuarioNovo;
                        this.errors={};
                        this.curso.CadastroTipo=2
                        $('#MensagemSistema').modal('show');
                    })
                    .catch(errors => {
                        this.errors = errors.response.data.errors
                        console.log(errors.response.data)
                    })
            // }
        },
        exportarEmpresaCrm(unidade){
            let url='/api/v1/Companies/Company/CadastroLocaisOferta/'+this.curso.id;
                
                //console.log(this.curso)                                  
                
                
            let configuracao = {
                // method:'put',
                // headers:{
                // //     'Accept': 'application/json',
                // //     'Authorization': this.$root.token()
                // },
            }
            
            axios.post(url,configuracao)
                .then(response => {
                    this.empresa_exportada_crm = response.data
                    console.log(this.empresa_exportada_crm)
                    // this.empresa_exportada_crm.id = response.data.resposta_crm.id
                    $('#ExportacaoCrmMsg').modal('show');
                })
                .catch(errors => {
                    this.errors = errors.response.data.errors
                    console.log(errors.response.data)
                })
        },
        excluirUsuarioCliente(id){
            
            if(this.modoexcluir){
                var config = {
                method: 'delete',
                url: '/api/v1/'+this.urlSistema+'/'+id,
                // headers: {
                //         // 'Accept': 'application/json',
                //         // 'Authorization': this.$root.token(),
                //     },
                };
                //executa requisição
                axios(config)
                .then(response => {
                    debugger;
                    // console.log(response.data.usuario)
                    this.$bvModal.hide('modal-prevent-closing')
                    this.$nextTick(() => {
                        window.alert("Usuário de Cliente excluído com sucesso");
                    })
                    this.removerPorId(id)
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
        },
        formatDate(date,idioma) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            if(idioma==1){
                let dt = [year, month, day].join('-');
                if(dt=='1969-12-31'){
                    return null
                }else{
                    return dt;
                }
            }else if(idioma==2){
                let dt = [day, month, year].join('/');
                if(dt=='31/12/1969'){
                    return null
                }else{
                    return dt;
                }
            }else{
                let dt = [year, month, day].join('-');
                if(dt=='1969-12-31'){
                    return null
                }else{
                    return dt;
                }
            }
        },
    },
    mounted(){
        // this.$root.atualizaCookie('token');
    },
    created(){

        if(this.pessoa){
            this.curso = this.pessoa;
            console.log(this.curso)
            this.users = this.usuarios;
            this.doc=this.pessoa.cnpj;
            
            if(this.pessoa.responsavel_id>0){
                this.responsavel_id=this.pessoa.responsavel_id
            }

            if(this.pessoa.cnpj.length==14){
                this.curso.CadastroTipo=1
                this.curso.cnpj = this.pessoa.cnpj;
                this.tipoCadastro=1
            }else if(this.pessoa.cnpj.length==18){
                this.curso.CadastroTipo=2
                this.curso.cnpj = this.pessoa.cnpj;
                this.tipoCadastro=2
            }else{
                this.curso.CadastroTipo=2
                this.tipoCadastro=2
            }
        }else{
            this.curso.CadastroTipo=2;
            this.tipoCadastro=2;
            this.curso.estado="0";
        }
    },
    props:{
        urlSistema:{
            type:String,
            required:true
        },
        visualizar:{
            type:Boolean,
            required:true
        },
        nomeFormulario:{
            type:String,
            required:true
        },
        metodoFormulario:{
            type:String,
            required:true
        },
        linkAction:{
            type:String,
            required:true
        },
        pessoa:{
            type:Object,
            required:false
        },
        usuarioAutenticado:{
            type:Object,
            required:false
        },
        usuarios:[],
        edit:{
            type:Boolean,
            required:true
        }
    },
    watch: {
    // whenever question changes, this function will run
        texto_modal(newTexto, oldTtexto) {
            if (newTexto!=oldTtexto) {
                this.texto_modal=newTexto;
            }
        }
        ,tipoCadastro(newTipoCadastro, oldTipoCadastro) {
            if (newTipoCadastro!=oldTipoCadastro) {
                this.curso.CadastroTipo=newTipoCadastro;
                this.tipoCadastro=newTipoCadastro;
                this.errors={};
            }
        },
        doc(newdoc, olddoc) {
            if (newdoc!=olddoc) {
                this.curso.cnpj=newdoc;
                this.doc=newdoc;
            }
        },
    },
    data(){
        return{
            transacaoStatus:null,
            modonovo:false,
            modoeditar:false,
            modoexcluir:false,
            Novo:false,
            texto_modal:'',
            nameState: null,
            senhaState:null,
            emailState:null,
            submittedNames: [],
            tipoCadastro:null,
            doc:null,
            curso:{
                id:null,
                nomecompleto:null,
                nomereduzido:null,
                cnpj:null,
                logradouro:null,
                numero:null,
                complemento:null,
                cep:null,
                pais:null,
                estado:null,
                cidade:null,
                bairro:null,
                site:null,
                email:null,
                tipocurso:null,
                celular:null,
                codigoempresa:null,
                /**
                 * cpf = 1
                 * cnpj = 2
                 */
                CadastroTipo:0,
                // link_integracao_gvdasa:null,
                // token_api_gvdasa:null,
                // link_integracao_rubeus:null,
                // token_api_rubeus:null,
                // origem:null,

            },
            user:{
                id:null,
                name:null,
                email:null,
                senha:null,
            },
            responsavel_id:0,
            users:[],
            errors:{},
            empresa_exportada_crm:[{
                id:null,
                codigo:null,
                descricao:null,
                nome:null,
                oridem:null
            }],
        }
    }
}
</script>

<style scoped>
    form{
        text-align: left!important;
    }

    .div-crud-sistema{
        background: white;
        padding: 15px;
        border-radius: 0px 0px 10px 10px;

    }

    .div-table-sistema{
        background: white;
        padding: 15px;
        border-radius: 10px;
        margin-top:15px;

    }

    .table-crud-sistema{
        background: white;
        border-radius: 0px 0px 10px 10px;
        overflow-x: auto;
    }

</style>
